import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import moment from 'moment';

const CommissionReportPDF = ({ ordersList, grossAmountTotal, differenceTotal, creditNoteTotal, netAmountTotal, grossCommissionAmountTotal, 
    netCommissionAmountTotal, buyersList, sellersList, selectedBuyer, selectedSeller, fromDate, toDate}) => {
    
    // This function adds commas to a number for every thousand separator.
    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const styles = StyleSheet.create({
        page: {
            backgroundColor: '#ffffff',
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 10,
            paddingBottom: 30
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1,
        },
        header: {
            fontSize: 24,
            marginBottom: 20,
            textAlign: 'center',
            color: 'black',
        },
        subheader: {
            fontSize: 15,
            marginBottom: 10,
            color: 'blue',
        },
        text: {
            fontSize: 10,
            marginBottom: 5,
            color: '#39566b',
        },
        table: {
            display: 'table',
            width: '100%',
            borderStyle: 'solid',
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0
        },
        tableRow: {
            margin: 'auto',
            flexDirection: 'row',
            width: '200%'
        },
        text1: {
            fontSize: 10,
            marginBottom: 5,
            color: '#39566b',
        },
        tableCol: {
            padding: '5px',
            width: '5%',
            borderStyle: 'solid',
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            backgroundColor: '#E6E2E2',
            fontSize : 6
        },
        tableFoot: {
            padding: '5px',
            width: '5%',
            borderStyle: 'solid',
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            backgroundColor: '#E6E2E2',
            fontSize : 8,
            textAlign:'center'
        },
        tableDataCol: {
            padding: '5px',
            width: '5%',
            borderStyle: 'solid',
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            backgroundColor: '#fff',
            textAlign:'center',
        },
        image: {
            width: 60,
            left: 10,
        },
        footer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            // position: 'absolute',
            // bottom: -5,
            left: 0,
            right: 0,
            // marginBottom: '10',
            padding: 10,
            borderTop: '1px',
        },

        text2: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginVertical: 20,
            display: "flex"
        },
        column: {
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            marginHorizontal: 5,
        },
        row: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
        },

        container: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center', // Align items vertically in the center
        },
        labelText: {
            marginRight: 8, // Adjust spacing between the label and value as needed
            fontSize: 14,
            fontWeight: '700'
        },
        valueText: {
            fontSize: 7,
            // marginBottom: 2,
            color: '#39566b',
            // padding: '5px 0px 5px 0px'
        },
        heading: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: "10px"
        },
        columnHeader: {
            fontSize: 9,
            marginBottom: 5,
            textAlign : 'center'
        },
        logoContainer: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        logo: {
            width: '35%',
            resizeMode: 'contain',
        },
        companyInfo: {
            width: '65%',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 10,
        },

        statusContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center', // Align items horizontally in the center
            marginTop: 20,
        },
        setStatusContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            // justifyContent: 'space-between', // Align items horizontally in the center

            border: 1,
            padding: 2,
            borderBottom: 3,
            borderRight: 3,
            width: "100%",
        },
        inlineText: {
            marginHorizontal: 3, // Adjust as needed for spacing between the two Text components
        },
        companyName: {
            fontSize: 20,
            fontWeight: '700',
            textTransform: "uppercase",
        },
        companyDescription: {
            fontSize: 12,
            fontWeight: '500',
        },
        statusLabel: {
            fontSize: 12,
            fontWeight: '600',
        },
        orderNumber: {
            fontSize: 18,
        },
        imageContainer: {
            alignItems: 'center',
            justifyContent: 'center',
        },
        titleCenter: {
            alignItems: 'center',
            width: "80%"
        },
        reportDescription:{ fontSize: 12, fontWeight: '600'},
    });

    var srcLogo = process.env.REACT_APP_PDF_LOGO+'img/logo.png';

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <View style={styles.logoContainer}>
                        <View style={styles.setStatusContainer}>
                            <View style={styles.imageContainer}>
                                <Image src={srcLogo} style={styles.image} />
                            </View>
                            <View style={styles.titleCenter}>
                                <Text style={[styles.companyName, styles.inlineText]}>Maruti Overseas Corp.</Text>
                                <Text style={[styles.companyDescription]}>Importers & Indentors</Text>
                            </View>
                        </View>
                    </View>
                    <View style={styles.statusContainer}>
                        <Text style={styles.statusLabel}>Account Statment</Text>
                    </View>
                    <View  style={styles.text2}>
                        <Text style={styles.reportDescription}>
                            Buyer: <Text style={styles.text1}>{(selectedBuyer) ? (buyersList.find(buyer => buyer.id === selectedBuyer)?.name || selectedBuyer) : '-'}</Text>
                            &nbsp;&nbsp;Seller: <Text style={styles.text1}>{(selectedSeller) ? (sellersList.find(seller => seller.id === selectedSeller)?.name || selectedSeller) : '-'}</Text>
                        </Text>
                        {/* <Text style={styles.reportDescription}>Account Statment</Text> */}
                        <Text style={styles.reportDescription} > 
                            From Date: <Text style={styles.text1}>{(fromDate) ? moment(fromDate).format('DD-MMM-YYYY') : '-'}</Text>
                            &nbsp;&nbsp;To Date: <Text style={styles.text1}>{(toDate) ? moment(toDate).format('DD-MMM-YYYY') : '-'}</Text>
                        </Text>
                    </View>
                    {/* <View style={{ textAlign: 'center', fontSize: 18, marginBottom: 10 }}>
                    </View> */}
                    <View style={styles.table}>
                        <View style={styles.tableRow} fixed>
                            <View style={styles.tableCol}>
                                <Text style={styles.columnHeader}>Invoice Date</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.columnHeader}>Invoice No.</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.columnHeader}>Buyer</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.columnHeader}>gross Amount</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.columnHeader}>Difference US($)</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.columnHeader}>Credit Note</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.columnHeader}>Net Amount US($)</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.columnHeader}>Commission (%)</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.columnHeader}>Gross Commission Amount US($)</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.columnHeader}>Net Commission Amount US($)</Text>
                            </View>
                        </View>
                        {/* Loop through order items */}
                        {ordersList?.map((invoice, i) => {
                            // calculate commission ammount behalf of commission or seller 
                            const commissionCalc = ((invoice?.net_amount) *
                                ((invoice?.commission) ? (invoice?.commission) : ((invoice?.sellers?.commission) ? (invoice?.sellers?.commission) : 0)) / 100)
                            // calculate difference ammount 
                            const differenceCalc = ((invoice?.invoice_total) - (invoice?.net_amount));
                            const netCommAmount = commissionCalc + differenceCalc;
                            return (
                                <>
                                    <View style={styles.tableRow} key={invoice.id}>
                                        <View style={styles.tableDataCol}>
                                            <Text style={styles.valueText}>{(invoice?.invoice_date) ? moment(invoice?.invoice_date).format('DD-MM-YYYY') : '-'}</Text>
                                        </View>
                                        <View style={styles.tableDataCol}>
                                            <Text style={styles.valueText}>{(invoice?.invoice_no) ? (invoice?.invoice_no) : '-'}</Text>
                                        </View>
                                        <View style={styles.tableDataCol}>
                                            <Text style={styles.valueText}>{(invoice?.buyers?.name) ? (invoice?.buyers?.name) : '-'}</Text>
                                        </View>
                                        <View style={styles.tableDataCol}>
                                            <Text style={styles.valueText}>{numberWithCommas((invoice?.invoice_total) ? parseFloat(invoice?.invoice_total).toFixed(2) : '-')}</Text>
                                        </View>
                                        <View style={styles.tableDataCol}>
                                            <Text style={styles.valueText}>{numberWithCommas(parseFloat(differenceCalc).toFixed(2))}</Text>
                                        </View>
                                        <View style={styles.tableDataCol}>
                                            <Text style={styles.valueText}>{numberWithCommas((invoice?.credit_note) ? parseFloat(invoice?.credit_note).toFixed(2) : '-')}</Text>
                                        </View>
                                        <View style={styles.tableDataCol}>
                                            <Text style={styles.valueText}> {numberWithCommas((invoice?.net_amount) ? parseFloat(invoice?.net_amount).toFixed(2) : '-')}</Text>
                                        </View>
                                        <View style={styles.tableDataCol}>
                                            <Text style={styles.valueText}>{numberWithCommas((invoice?.commission) ? (invoice?.commission) : ((invoice?.sellers?.commission)?(invoice?.sellers?.commission) : 0))}</Text>
                                        </View>
                                        <View style={styles.tableDataCol}>
                                            <Text style={styles.valueText}>{numberWithCommas(parseFloat(commissionCalc).toFixed(2))}</Text>
                                        </View>
                                        <View style={styles.tableDataCol}>
                                            <Text style={styles.valueText}>{numberWithCommas(parseFloat(netCommAmount).toFixed(2))}</Text>
                                        </View>
                                    </View>
                                </>
                            );
                        })}
                        {/* Additional row for calculation data */}
                        <View style={styles.tableRow}>
                            <View style={styles.tableFoot}><Text>Total</Text></View>
                            <View style={styles.tableFoot}><Text></Text></View>
                            <View style={styles.tableFoot}><Text></Text></View>
                            <View style={styles.tableFoot}>
                                <Text>{(grossAmountTotal) ? '$'+numberWithCommas(parseFloat(grossAmountTotal).toFixed(2)) : 0}</Text>
                            </View>
                            <View style={styles.tableFoot}>
                                <Text>{(differenceTotal) ? parseFloat(differenceTotal).toFixed(2) : 0}</Text>
                            </View>
                            <View style={styles.tableFoot}>
                                <Text>{(creditNoteTotal) ? parseFloat(creditNoteTotal).toFixed(2) : 0}</Text>
                            </View>
                            <View style={styles.tableFoot}>
                                <Text>{(netAmountTotal) ? '$'+numberWithCommas(parseFloat(netAmountTotal).toFixed(2)) : 0}</Text>
                            </View>
                            <View style={styles.tableFoot}><Text></Text></View>
                            <View style={styles.tableFoot}>
                                <Text>{(grossCommissionAmountTotal) ? '$'+numberWithCommas(parseFloat(grossCommissionAmountTotal).toFixed(2)) : '0'}</Text>
                            </View>
                            <View style={styles.tableFoot}>
                                <Text>{(netCommissionAmountTotal) ? '$'+numberWithCommas(parseFloat(netCommissionAmountTotal).toFixed(2)) : '0'}</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={styles.footer} fixed>
                    <Text style={styles.reportDescription}>
                        File Name : <Text style={styles.text1}>Commission Report</Text>
                    </Text>
                    <Text style={styles.reportDescription}>
                        User :  <Text style={styles.text1}>Ambuj</Text>
                    </Text>
                    <Text style={styles.reportDescription} > 
                        Print Date : <Text style={styles.text1}>{moment().format('DD-MMM-YYYY')}</Text>
                    </Text>
                    <Text style={styles.text1} render={({ pageNumber, totalPages }) => (
                        `Page ${pageNumber} of ${totalPages}`
                    )} fixed />
                </View>
            </Page>
        </Document>
    );
};

export default CommissionReportPDF;