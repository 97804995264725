import Axios from "axios";
require("dotenv").config();

const axios = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL + 'api',
});

// Request interceptor
axios.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("token");
    if (accessToken) {
      if (config.headers) config.headers.Authorization = 'Bearer '+accessToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      const status = error.response.status;
      if (status === 401) {
        // Clear local storage
        localStorage.removeItem("token");
        localStorage.removeItem("userData");
        // Redirect to login page
        window.location.href = "/login";
      }
    }
    return Promise.reject(error);
  }
);

export default axios;