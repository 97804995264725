import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import configureStore from './store/store';

// import { startGetUser } from './actions/authAction';

const Store = configureStore();

//to handle page reloads
if (localStorage.getItem("token")) {
  // Store.dispatch(startGetUser());  
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={Store}>
    {/* <React.StrictMode> */}
      <App />
    {/* </React.StrictMode> */}
  </Provider>
);

reportWebVitals();
