import axios from "../services/axios";
import { buyerListConstants, createBuyerConstants, viewBuyerConstants, updateBuyerConstants, clearBuyerMessages, deleteBuyerConstants } from "../constants/buyer.constant";

export const getBuyers = () => {
  return (dispatch) => {
    axios.get("/buyer/list").then((response) => {
        if (response.data.hasOwnProperty("error")) {
          alert(response.data.error);
        } else {
            dispatch({ type: buyerListConstants.GET_BUYERLIST_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({type: buyerListConstants.GET_BUYERLIST_FAILURE,payload: err.message,});
        console.log(err);
      });
  };
};

export const createBuyer = (formData) => {
  return (dispatch) => {
    axios.post("/buyer/create", formData).then((response) => {
        if (response.data.hasOwnProperty("error")) {
          alert(response.data.error);
        } else {
            dispatch({ type: createBuyerConstants.GET_CREATEBUYER_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({type: createBuyerConstants.GET_CREATEBUYER_FAILURE,payload: err.message,});
        console.log(err);
      });
  };
};

export const viewBuyer = (id) => {
  return (dispatch) => {
    axios.get(`/buyer/view/${id}`).then((response) => {
        if (response.data.hasOwnProperty("error")) {
          alert(response.data.error);
        } else {
            dispatch({ type: viewBuyerConstants.GET_VIEWBUYER_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({type: viewBuyerConstants.GET_VIEWBUYER_FAILURE,payload: err.message,});
        console.log(err);
      });
  };
};

export const updateBuyer = (formData) => {
  return (dispatch) => {
    axios.post("/buyer/update", formData).then((response) => {
        if (response.data.hasOwnProperty("error")) {
          alert(response.data.error);
        } else {
            dispatch({ type: updateBuyerConstants.GET_UPDATEBUYER_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({type: updateBuyerConstants.GET_UPDATEBUYER_FAILURE,payload: err.message,});
        console.log(err);
      });
  };
};
export const clearBuyerMsg = () => async (dispatch) => {
  try {
      dispatch({ type: clearBuyerMessages.CLEAR_BUYER_MESSAGE, payload: [] })
  } catch (err) {
      console.log(`Error while calling login api ${err}`);
  }
}

export const deleteBuyer = (id) => {
  return (dispatch) => {
    axios.get("/buyer/delete/"+id).then((response) => {
        if (response.data.hasOwnProperty("error")) {
          alert(response.data.error);
        } else {
            dispatch({ type: deleteBuyerConstants.GET_DELETEBUYER_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({type: deleteBuyerConstants.GET_DELETEBUYER_FAILURE,payload: err.message,});
        console.log(err);
      });
  };
};