
/******************* Constants For Authenctication ******************/
export const loginConstants = {
    GET_LOGIN_REQUEST: 'GET_LOGIN_REQUEST',
    GET_LOGIN_SUCCESS: 'GET_LOGIN_SUCCESS',
    GET_LOGIN_FAILURE: 'GET_LOGIN_FAILURE'
}
  
export const logoutConstants = {
    GET_LOGOUT_SUCCESS: 'GET_LOGOUT_SUCCESS',
    GET_LOGOUT_FAILURE: 'GET_LOGOUT_FAILURE'
}
  
export const signupConstants = {
    GET_SIGNUP_SUCCESS: 'GET_SIGNUP_SUCCESS',
    GET_SIGNUP_FAILURE: 'GET_SIGNUP_FAILURE'
}
  
export const forgetPassword = {
    GET_FORGET_SUCCESS: 'GET_FORGET_SUCCESS',
    GET_FORGET_FAILURE: 'GET_FORGET_FAILURE',
}
  
export const resetPassword = {
    GET_RESET_REQUEST: 'GET_RESET_REQUEST',
    GET_RESET_SUCCESS: 'GET_RESET_SUCCESS',
    GET_RESET_FAILURE: 'GET_RESET_FAILURE',
}