import axios from "../services/axios";
import {  itemListConstants, itemPropConstants, createItemConstants, viewItemConstants, itemByIdsConstants, itemByOrderIdConstants, itemByOrderItemIdConstants, 
  updateItemConstants, createUpdateItemConstants, deleteItemConstants, clearItemMessages } from "../constants/item.constant";

export const getItems = () => {
  return (dispatch) => {
    axios.get("/item/list").then((response) => {
        if (response.data.hasOwnProperty("error")) {
          alert(response.data.error);
        } else {
            dispatch({ type: itemListConstants.GET_ITEMLIST_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({type: itemListConstants.GET_ITEMLIST_FAILURE,payload: err.message,});
        console.log(err);
      });
  };
};

export const getItemByIds = (formData) => {
  return (dispatch) => {
    axios.post("/item/item-by-ids", formData).then((response) => {
        if (response.data.hasOwnProperty("error")) {
          alert(response.data.error);
        } else {
            dispatch({ type: itemByIdsConstants.GET_ITEMBYIDS_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({type: itemByIdsConstants.GET_ITEMBYIDS_FAILURE,payload: err.message,});
        console.log(err);
      });
  };
};

export const getItemByOrderId = (formData) => {
  return (dispatch) => {
    axios.post("/item/item-by-order-id", formData).then((response) => {
        if (response.data.hasOwnProperty("error")) {
          alert(response.data.error);
        } else {
            dispatch({ type: itemByOrderIdConstants.GET_ITEMBYORDERID_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({type: itemByOrderIdConstants.GET_ITEMBYORDERID_FAILURE,payload: err.message,});
        console.log(err);
      });
  };
};

export const getItemByOrderItemId = (formData) => {
  return (dispatch) => {
    axios.post("/item/item-by-order-item-id", formData).then((response) => {
        if (response.data.hasOwnProperty("error")) {
          alert(response.data.error);
        } else {
            dispatch({ type: itemByOrderItemIdConstants.GET_ITEMBYORDERITEMID_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({type: itemByOrderItemIdConstants.GET_ITEMBYORDERITEMID_FAILURE,payload: err.message,});
        console.log(err);
      });
  };
};

export const getItemProp = () => {
  return (dispatch) => {
    axios.get("/item/get-item-prop").then((response) => {
        if (response.data.hasOwnProperty("error")) {
          alert(response.data.error);
        } else {
            dispatch({ type: itemPropConstants.GET_ITEMPROP_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({type: itemPropConstants.GET_ITEMPROP_FAILURE,payload: err.message,});
        console.log(err);
      });
  };
};

export const viewItem = (id) => {
  return (dispatch) => {
    axios.get(`/item/view/${id}`).then((response) => {
        if (response.data.hasOwnProperty("error")) {
          alert(response.data.error);
        } else {
            dispatch({ type: viewItemConstants.GET_VIEWITEM_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({type: viewItemConstants.GET_VIEWITEM_FAILURE,payload: err.message,});
        console.log(err);
      });
  };
};

export const createItem = (formData) => {
  return (dispatch) => {
    axios.post("/item/create", formData).then((response) => {
        if (response.data.hasOwnProperty("error")) {
          alert(response.data.error);
        } else {
            dispatch({ type: createItemConstants.GET_CREATEITEM_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({type: createItemConstants.GET_CREATEITEM_FAILURE,payload: err.message,});
      });
  };
};

export const updateItem = (formData) => {
  return (dispatch) => {
    axios.post("/item/update", formData).then((response) => {
      if (response.data.hasOwnProperty("error")) {
        alert(response.data.error);
      } else {
          dispatch({ type: updateItemConstants.GET_UPDATEITEM_SUCCESS, payload: { data: response.data },});
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch({type: updateItemConstants.GET_UPDATEITEM_FAILURE,payload: err.message,});
    });
  };
}

export const createUpdateItem = (formData) => {
  return (dispatch) => {
    axios.post("/item/create-update", formData).then((response) => {
      console.log('response',response);
      if (response.data.hasOwnProperty("error")) {
        alert(response.data.error);
      } else {
          dispatch({ type: createUpdateItemConstants.GET_CREATEUPDATEITEM_SUCCESS, payload: { data: response.data },});
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch({type: createUpdateItemConstants.GET_CREATEUPDATEITEM_FAILURE,payload: err.message,});
    });
  };
}

// action for clear the state of set messages
export const clearItemMsg = () => async (dispatch) => {
  try {
      dispatch({ type: clearItemMessages.CLEAR_ITEM_MESSAGE, payload: [] })
  } catch (err) {
      console.log(`Error while calling login api ${err}`);
  }
}

// action for call the item delete API
export const deleteItem = (id) => {
  return (dispatch) => {
    axios.post("/item/delete/"+id).then((response) => {
        if (response.data.hasOwnProperty("error")) {
          alert(response.data.error);
        } else {
            dispatch({ type: deleteItemConstants.GET_DELETEITEM_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({type: deleteItemConstants.GET_DELETEITEM_FAILURE,payload: err.message,});
        console.log(err);
      });
  };
};
