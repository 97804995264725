import axios from "../services/axios";
import { loginConstants } from "../constants/auth.constants";


export const loginUser = (formData, redirect) => {
  return (dispatch) => {
    axios.post("/login", formData).then((response) => {
        if (response.data.hasOwnProperty("error")) {
          alert(response.data.error);
        } else {
          if(response?.data?.status === true){
            localStorage.setItem("token", response.data.data.authToken);
            localStorage.setItem("userData", JSON.stringify(response.data.data.user));
            dispatch({ type: loginConstants.GET_LOGIN_SUCCESS, payload: { data: response.data },});
          }else{
            dispatch({ type: loginConstants.GET_LOGIN_SUCCESS, payload: { data: response.data },});
          }
        }
      })
      .catch((err) => {
        dispatch({type: loginConstants.GET_LOGIN_FAILURE,payload: err.message,});
        console.log(err);
      });
  };
};

export const registerUser = (formData, redirect) => {
  return (dispatch) => {
    axios
      .post("/users/register", formData)
      .then((response) => {
        if (response.data.hasOwnProperty("errors")) {
          alert(response.data.message);
        } else {
          alert("you have registered successfully");
          redirect();
          //props.history.push('/users/login')
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const userLogout = () => {
  return (dispatch) => {
    axios.post("logout", {
        headers: {
          "x-auth": localStorage.getItem("token"),
        },
      })
      .then((response) => {
        if (response.data.notice) {
          alert(response.data.notice);
          localStorage.removeItem("token");
          localStorage.removeItem("userData");
          window.location.href = "/login";
        }else{
          localStorage.removeItem("token");
          localStorage.removeItem("userData");
          window.location.href = "/login";
        }
      });
  };
};
