import axios from "../services/axios";
import { userConstants } from "../constants/user.constant";
import { dashboardConstants,userListConstants, createUserConstants, viewUserConstants, updateUserConstants, clearUserMessages, deleteUserConstants, 
        financialYearConstants, changePasswordConstants, setFinancialYearConstants, salesOrderPrefixConstants, setSalesOrderPrefixConstants, 
        recycleBinRecordsConstants, restoreRecordConstants, deleteRecordPermanentConstants  } from "../constants/user.constant";


// action for call the dashboard list
export const dashboard = () => {
  return (dispatch) => {
    axios.get("/dashboard").then((response) => {
        if (response.data.hasOwnProperty("error")) {
          alert(response.data.error);
        } else {
            dispatch({ type: dashboardConstants.GET_DASHBOARDLIST_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({type: dashboardConstants.GET_DASHBOARDLIST_FAILURE,payload: err.message,});
        console.log(err);
      });
  };
};

  // action to get loggedIn user data
export const startGetUser = () => {
    return (dispatch) => {
      axios.get("user/get-user").then((response) => {
            if (response.data.hasOwnProperty("error")) {
                alert(response.data.error);
            } else {
                dispatch({ type: userConstants.GET_USER_SUCCESS, payload: { data: response.data },});
            }
        })
        .catch((err) => {
            dispatch({type: userConstants.GET_USER_SUCCESS,payload: err.message,});
            console.log(err);
        });
    };
};

// action for call the get all the users list
export const getUsers = () => {
    return (dispatch) => {
      axios.get("/user/list").then((response) => {
          if (response.data.hasOwnProperty("error")) {
            alert(response.data.error);
          } else {
              dispatch({ type: userListConstants.GET_USERLIST_SUCCESS, payload: { data: response.data },});
          }
        })
        .catch((err) => {
          dispatch({type: userListConstants.GET_USERLIST_FAILURE,payload: err.message,});
          console.log(err);
        });
    };
  };
  
  // action for call the create user API
  export const createUser = (formData) => {
    return (dispatch) => {
      axios.post("/user/create", formData).then((response) => {
          if (response.data.hasOwnProperty("error")) {
            alert(response.data.error);
          } else {
              dispatch({ type: createUserConstants.GET_CREATEUSER_SUCCESS, payload: { data: response.data },});
          }
        })
        .catch((err) => {
          dispatch({type: createUserConstants.GET_CREATEUSER_FAILURE,payload: err.message,});
          console.log(err);
        });
    };
  };
  
  // action for call the user view information
  export const viewUser = (id) => {
    return (dispatch) => {
      axios.get(`/user/view/${id}`).then((response) => {
          if (response.data.hasOwnProperty("error")) {
            alert(response.data.error);
          } else {
              dispatch({ type: viewUserConstants.GET_VIEWUSER_SUCCESS, payload: { data: response.data },});
          }
        })
        .catch((err) => {
          dispatch({type: viewUserConstants.GET_VIEWUSER_FAILURE,payload: err.message,});
          console.log(err);
        });
    };
  };
  
  // action for call the user details update API
  export const updateUser = (formData) => {
    return (dispatch) => {
      axios.post("/user/update", formData).then((response) => {
          if (response.data.hasOwnProperty("error")) {
            alert(response.data.error);
          } else {
              dispatch({ type: updateUserConstants.GET_UPDATEUSER_SUCCESS, payload: { data: response.data },});
          }
        })
        .catch((err) => {
          dispatch({type: updateUserConstants.GET_UPDATEUSER_FAILURE,payload: err.message,});
          console.log(err);
        });
    };
  };
  
  // action for clear the state of set messages
  export const clearUserMsg = () => async (dispatch) => {
    try {
        dispatch({ type: clearUserMessages.CLEAR_USER_MESSAGE, payload: [] })
    } catch (err) {
        console.log(`Error while calling login api ${err}`);
    }
  }
  
  // action for call the user delete API
  export const deleteUser = (id) => {
    return (dispatch) => {
      axios.get("/user/delete/"+id).then((response) => {
          if (response.data.hasOwnProperty("error")) {
            alert(response.data.error);
          } else {
              dispatch({ type: deleteUserConstants.GET_DELETEUSER_SUCCESS, payload: { data: response.data },});
          }
        })
        .catch((err) => {
          dispatch({type: deleteUserConstants.GET_DELETEUSER_FAILURE,payload: err.message,});
          console.log(err);
        });
    };
  };

  // action for call the chnage password API
  export const changePassword = (formData) => {
    return (dispatch) => {
      axios.post("/user/change-password",formData).then((response) => {
          if (response.data.hasOwnProperty("error")) {
            alert(response.data.error);
          } else {
            dispatch({ type: changePasswordConstants.GET_CHANGEPASSWORD_SUCCESS, payload: { data: response.data },});
          }
        })
        .catch((err) => {
          dispatch({type: changePasswordConstants.GET_CHANGEPASSWORD_FAILURE,payload: err.message,});
          console.log(err);
        });
    };
  };

  // action to get financial year
  export const financialYear = (id) => {
    return (dispatch) => {
      axios.get("/user/get-financial-year").then((response) => {
          if (response.data.hasOwnProperty("error")) {
            alert(response.data.error);
          } else {
              dispatch({ type: financialYearConstants.GET_FINANCIALYEAR_SUCCESS, payload: { data: response.data },});
          }
        })
        .catch((err) => {
          dispatch({type: financialYearConstants.GET_FINANCIALYEAR_FAILURE,payload: err.message,});
          console.log(err);
        });
    };
  };

  // action to update financial year
  export const setFinancialYear = (formData) => {
    return (dispatch) => {
      axios.post("/user/set-financial-year",formData).then((response) => {
          if (response.data.hasOwnProperty("error")) {
            alert(response.data.error);
          } else {
              dispatch({ type: setFinancialYearConstants.GET_SETFINANCIALYEAR_SUCCESS, payload: { data: response.data },});
          }
        })
        .catch((err) => {
          dispatch({type: setFinancialYearConstants.GET_SETFINANCIALYEAR_FAILURE,payload: err.message,});
          console.log(err);
        });
    };
  };

  // action to get financial year
  export const salesOrderPrefix = (id) => {
    return (dispatch) => {
      axios.get("/user/sales-order-prefix").then((response) => {
          if (response.data.hasOwnProperty("error")) {
            alert(response.data.error);
          } else {
              dispatch({ type: salesOrderPrefixConstants.GET_SALESORDERPREFIX_SUCCESS, payload: { data: response.data },});
          }
        })
        .catch((err) => {
          dispatch({type: salesOrderPrefixConstants.GET_SALESORDERPREFIX_FAILURE,payload: err.message,});
          console.log(err);
        });
    };
  };

  // action to update sales order prefix
  export const setSalesOrderPrefix = (formData) => {
    return (dispatch) => {
      axios.post("/user/set-sales-order-prefix",formData).then((response) => {
          if (response.data.hasOwnProperty("error")) {
            alert(response.data.error);
          } else {
              dispatch({ type: setSalesOrderPrefixConstants.GET_SETSALESORDERPREFIX_SUCCESS, payload: { data: response.data },});
          }
        })
        .catch((err) => {
          dispatch({type: setSalesOrderPrefixConstants.GET_SETSALESORDERPREFIX_FAILURE,payload: err.message,});
          console.log(err);
        });
    };
  };

  // action to get all the recycle bin records
  export const recycleBinRecords = (formData) => {
    return (dispatch) => {
      axios.post("/recycle-bin-records",formData).then((response) => {
          if (response.data.hasOwnProperty("error")) {
            alert(response.data.error);
          } else {
              dispatch({ type: recycleBinRecordsConstants.GET_RECYCLEBINRECORDS_SUCCESS, payload: { data: response.data },});
          }
        })
        .catch((err) => {
          dispatch({type: recycleBinRecordsConstants.GET_RECYCLEBINRECORDS_FAILURE,payload: err.message,});
          console.log(err);
        });
    };
  };

  // action to restore records
  export const restoreRecord = (formData) => {
    return (dispatch) => {
      axios.post("/restore-record",formData).then((response) => {
          if (response.data.hasOwnProperty("error")) {
            alert(response.data.error);
          } else {
              dispatch({ type: restoreRecordConstants.GET_RESTORERECORD_SUCCESS, payload: { data: response.data },});
          }
        })
        .catch((err) => {
          dispatch({type: restoreRecordConstants.GET_RESTORERECORD_FAILURE,payload: err.message,});
          console.log(err);
        });
    };
  };
  

  // action to delete record permanent
  export const deleteRecordPermanent = (formData) => {
    return (dispatch) => {
      axios.post("/delete-record-permanent",formData).then((response) => {
          if (response.data.hasOwnProperty("error")) {
            alert(response.data.error);
          } else {
              dispatch({ type: deleteRecordPermanentConstants.GET_DELETERECORDPERMANENT_SUCCESS, payload: { data: response.data },});
          }
        })
        .catch((err) => {
          dispatch({type: deleteRecordPermanentConstants.GET_DELETERECORDPERMANENT_FAILURE,payload: err.message,});
          console.log(err);
        });
    };
  };