
/******************* Constants For Sellers ******************/
export const sellerListConstants = {
    GET_SELLERLIST_SUCCESS: 'GET_SELLERLIST_SUCCESS',
    GET_SELLERLIST_FAILURE: 'GET_SELLERLIST_FAILURE'
}

export const createSellerConstants = {
    GET_CREATESELLER_SUCCESS: 'GET_CREATESELLER_SUCCESS',
    GET_CREATESELLER_FAILURE: 'GET_CREATESELLER_FAILURE'
}

export const viewSellerConstants = {
    GET_VIEWSELLER_SUCCESS: 'GET_VIEWSELLER_SUCCESS',
    GET_VIEWSELLER_FAILURE: 'GET_VIEWSELLER_FAILURE'
}

export const updateSellerConstants = {
    GET_UPDATESELLER_SUCCESS: 'GET_UPDATESELLER_SUCCESS',
    GET_UPDATESELLER_FAILURE: 'GET_UPDATESELLER_FAILURE',
}

export const deleteSellerConstants = {
    GET_DELETESELLER_SUCCESS: 'GET_DELETESELLER_SUCCESS',
    GET_DELETESELLER_FAILURE: 'GET_DELETESELLER_FAILURE'
}

export const clearSellerMessages = {
    CLEAR_SELLER_MESSAGE: 'CLEAR_SELLER_MESSAGE'
}