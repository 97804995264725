import './App.css';
import React from "react";
import { BrowserRouter } from "react-router-dom";
import Routers from './routes/routes';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'datatables.net-dt';

function App(props) {
  return (
    <BrowserRouter >
    <ToastContainer />
        <Routers/> 
    </BrowserRouter>
  );
}

export default App;
