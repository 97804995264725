import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from 'react-select';
import Modal from "react-modal";
import { toast } from 'react-toastify';
import CreatableSelect from 'react-select/creatable';
import { createUpdateItem, getItemByIds, getItemProp, viewItem, clearItemMsg} from "../../actions/itemAction";
import { getBuyers } from "../../actions/buyerAction";
import { getSellers } from "../../actions/sellerAction";
// import { salesOrderPrefix } from "../../actions/userAction";
import { clearOrderMsg, viewOrder, updateOrder } from "../../actions/orderAction";
import BuyerCreate from "../buyers/createBuyer";
import SellerCreate from "../sellers/createSeller";


const OrderUpdate = () => {

    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const salesProps =  {"from" : "salesOrder"}
    
    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            width : "50%",
          right: "auto",
          bottom: "auto",
          padding: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
        },
    };

    const options = [
        { value: 'red', label: 'Red', color: '#FF0000' },
        { value: 'blue', label: 'Blue', color: '#0000FF' },
        { value: 'green', label: 'Green', color: '#00FF00' },
    ];

    const [userData, setUserData] = useState("");
    // const [prefix, setPrefix] = useState("");
    const [buyerModalIsOpen, setBuyerModalIsOpen] = useState(false);
    const [sellerModalIsOpen, setSellerModalIsOpen] = useState(false);
    
    const [orderError, setOrderError] = useState(false);
    const [itemError, setItemError] = useState(false);
    const [buyersList, setBuyersList] = useState([]);
    const [sellersList, setSellersList] = useState([]);

    const [sizesList, setSizeslist] = useState([]);
    const [ratiosList, setRatioslist] = useState([]);
    const [stylesList, setStyleslist] = useState([]);
    const [forList, setForlist] = useState([]);
    const [categoriesList, setCategorieslist] = useState([]);
    const [brandsList, setBrandsList] = useState([]);
    const [fabricsList, setFabricslist] = useState([]);
    const [assortmentsList, setAssortmentsList] = useState([]);
    const [termsList, setTermslist] = useState([]);
    

    const [selectedBuyer, setSelectedBuyer] = useState("");
    const [selectedSeller, setSelectedSeller] = useState("");
    const [isDirectChecked, setIsDerectChecked] = useState(false);
    const [selectedTerm, setSelectedTerm] = useState("");
    
    const [selectedItem, setSelectedItem] = useState("");
    const [selectedItemNo, setSelectedItemNo] = useState("");
    const [selectedSize, setSelectedSize] = useState("");
    const [selectedRatio, setSelectedRatio] = useState("");
    const [selectedStyle, setSelectedStyle] = useState("");
    const [selectedFor, setSelectedFor] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedFabrics, setSelectedFabrics] = useState("");
    const [selectedBrand, setSelectedBrand] = useState("");
    const [selectedColor, setSelectedColor] = useState("");
    const [selectedAssortment, setSelectedAssortment] = useState("");
    const [itemPrice, setItemPrice] = useState("");
    const [cnfValue, setCnfValue] = useState("");
    const [totalCnfValue, setTotalcnfValue] = useState("");
    const [totalQuantity, setTotalQuantity] = useState("");

    
    const [items, setItems] = useState([]);
    const [selectedItemArray, setSelectedItemArray] = useState([]);

    const [handleCreateBuyer , setCreateBuyer] = useState(false);
    const [handleCreateSeller , setCreateSeller] = useState(false);
    const [selllerOverPrice, setSellerOverPrice] = useState("");

    const [flag, setFlag] = useState(false);
    const [messageChecked, setMessageChecked] = useState(false)
    const [revisedChecked, setRevisedChecked] = useState(false)
    
    // manage state for order
    const [orderDetails, setOrderDetails] = useState({
        id:"", 
        order_no:"", 
        date:new Date().toISOString().substr(0, 10), 
        buyer_id:"", 
        seller_id:"",
        direct : "",
        revised_date:new Date().toISOString().substr(0, 10),
        payment_term:"", 
        validity:"", 
        shipment_date:"", 
        pre_remainder_date:"",
        shipment_term:"Partial / Trans Shipment Allowed",
        message_info:""
    });
    //manage state for item
    const [itemDetails, setItemDetails] = useState({
        id:"", 
        item_no:"", 
        date:"", 
        barcode:"", 
        basicPrice:"", 
        overPrice:"", 
        price:"",
        quantity:"",
        qntyValue: 1,
        wash:"", 
        color:"", 
        extra:"",
    });
    const [fontValue, setFontValue] = useState({
        bold: false,
        italic: false,
        underline: false,
        // message: false,
    });

    // useEffect for call Api's
    useEffect(() => {
        dispatch(viewOrder(id))
        dispatch(getItemProp());
        dispatch(getBuyers());
        dispatch(getSellers());
        // dispatch(salesOrderPrefix());
        Modal.setAppElement('#root');
        setUserData(JSON.parse(localStorage.getItem("userData")));
    }, [dispatch,id]);

    // //get the sales order prefix from reducer and set in state
    // const prefixDtl = useSelector((state) => state.salesOrderPrefix.salesOrderPrefix)
    // useEffect(() => {
    //     if (prefixDtl && prefixDtl?.data && prefixDtl?.data?.status === true) {
    //         setPrefix(prefixDtl?.data?.data?.sales_order_prefix)
    //     }
    // }, [prefixDtl,dispatch]);
 
    //get all the buyers and sellers list from reducer and set here
    const getBuyerList = useSelector((state) => state.getBuyerList.getBuyerList)
    const getSellerList = useSelector((state) => state.getSellerList.getSellerList)
    useEffect(() => {
        if (getBuyerList && getBuyerList?.data && getBuyerList?.data?.status === true) {
            setBuyersList(getBuyerList?.data?.data);
        }
        if (getSellerList && getSellerList?.data && getSellerList?.data?.status === true) {
            setSellersList(getSellerList?.data?.data);
        }

        if(handleCreateBuyer){
            let ind = getBuyerList?.data?.data[getBuyerList?.data?.data?.length - 1];
            setSelectedBuyer(ind.id);
        }
        if(handleCreateSeller){
            let ind = getSellerList?.data?.data[getSellerList?.data?.data?.length - 1];
            setSelectedSeller(ind.id);
        }
    }, [getBuyerList,getSellerList]);

    // get the all item properties list from reducer and set in state
    const itemProperties = useSelector((state) => state.getItemProp.getItemProp)
    useEffect(() => {
        if (itemProperties && itemProperties?.data && itemProperties?.data?.status === true) {
            setSizeslist(itemProperties?.data?.data.sizes);
            setRatioslist(itemProperties?.data?.data.ratios);
            setStyleslist(itemProperties?.data?.data.styles);
            setForlist(itemProperties?.data?.data.for);
            setCategorieslist(itemProperties?.data?.data.categories);
            setFabricslist(itemProperties?.data?.data.fabrics);
            setBrandsList(itemProperties?.data?.data.brands);
            setAssortmentsList(itemProperties?.data?.data?.assortments);
            setTermslist(itemProperties?.data?.data.terms);
        }
    }, [itemProperties]);

    // get order data via id and set it in state
    const orderDtl = useSelector((state) => state.viewOrder.viewOrder);
    useEffect(() => {
        if(orderDtl && orderDtl?.data && orderDtl?.data?.status === true ){
            setOrderDetails({
                id          : orderDtl?.data?.data?.id, 
                order_no    : orderDtl?.data?.data?.order_no, 
                date        : orderDtl?.data?.data?.order_date,
                revised_date        : (orderDtl?.data?.data?.revised_date) ? orderDtl?.data?.data?.revised_date : new Date().toISOString().substr(0, 10),
                validity            : orderDtl?.data?.data?.validity,
                shipment_date       : orderDtl?.data?.data?.shipment_date,
                pre_remainder_date  : orderDtl?.data?.data?.pre_remainder_date,
                shipment_term       : orderDtl?.data?.data?.shipment_term,
                message_info        : orderDtl?.data?.data?.message_info,
            });
            setItemDetails({
                quantity : orderDtl?.data?.data?.quantity,
                qntyValue           : 1
            });
            setSelectedBuyer(orderDtl?.data?.data?.buyer_id);
            setSelectedSeller(orderDtl?.data?.data?.seller_id);
            setSellerOverPrice(orderDtl?.data?.data?.sellers?.over_price);
            setSelectedTerm(orderDtl?.data?.data?.payment_term_id);
            setIsDerectChecked(orderDtl?.data?.data?.direct);
            setMessageChecked(orderDtl?.data?.data?.is_message)
            setRevisedChecked(orderDtl?.data?.data?.is_revised)

            setSelectedItemArray(orderDtl?.data?.data?.order_item);
            setTotalcnfValue(orderDtl?.data?.data?.total_cnf);
            setTotalQuantity(orderDtl?.data?.data?.total_quantity);
        }
    }, [orderDtl]);

    // call the API for get items by selected buyer or seller
    useEffect(() => {
          const requestData = {
            buyer_id  : selectedBuyer  || undefined,
            seller_id : selectedSeller || undefined,
          };
          if(selectedBuyer && selectedSeller){
              dispatch(getItemByIds(requestData));
          }
    }, [selectedBuyer, selectedSeller, dispatch]);

    // get the response of item by selected ids
    const itemListById = useSelector((state) => state.getItemById.getItemById)
    useEffect(() => {
        if (itemListById && itemListById?.data && itemListById?.data?.status === true) {
            setItems(itemListById?.data?.data);
        }
    }, [itemListById,navigate,dispatch]);

    // call the get item API by selected item ID
    useEffect(() => {
        if(typeof selectedItem === 'number'){
            if(flag === false){
                dispatch(viewItem(selectedItem));
            }
            setFlag(false);
        }
    }, [selectedItem,dispatch]);
    
    // get item data via id and set it in state
    const itemDtl = useSelector((state) => state.viewItem.viewItem);
    useEffect(() => {
        if(itemDtl && itemDtl.data && itemDtl.data.status === true ){
            setItemDetails({
                id          : itemDtl?.data?.data?.id, 
                item_no     : itemDtl?.data?.data?.item_no, 
                date        : itemDtl?.data?.data?.date,
                wash        : itemDtl?.data?.data?.wash,
                color       : itemDtl?.data?.data?.color,
                extra       : itemDtl?.data?.data?.extra,
                barcode     : itemDtl?.data?.data?.barcode,
                basicPrice  : itemDtl?.data?.data?.basic_price, 
                overPrice   : itemDtl?.data?.data?.over_price, 
                price       : itemDtl?.data?.data?.price,
                itemPrice   : itemDtl?.data?.data?.item_price,
                // quantity    : itemDtl?.data?.data?.quantity
                // item_color  : itemDtl?.data?.data?.item_color,
                qntyValue   : itemDetails.qntyValue
            });
            setSelectedSize(itemDtl?.data?.data?.size_id);
            setSelectedRatio(itemDtl?.data?.data?.ratio_id);
            setSelectedStyle(itemDtl?.data?.data?.style_id);
            setSelectedFor(itemDtl?.data?.data?.for_id);
            setSelectedCategory(itemDtl?.data?.data?.category_id);
            setSelectedFabrics(itemDtl?.data?.data?.fabric_id);
            setSelectedBrand(itemDtl?.data?.data?.brand_id);
            // setSelectedColor(itemDtl?.data?.data?.item_color);
            // setCnfValue("");
            setSelectedAssortment(itemDtl?.data?.data?.assortment_id);
        }
    }, [itemDtl]);
    
    // Update the state when we change the order value
    const handleOrderForm = (e) => {
        setOrderDetails({
            ...orderDetails, 
            [e.target.name]: e.target.value
        })
    }
    // set order properties in state
    const handleOrderDetails = (e, type) => {
        if(type === 'buyer'){
            setSelectedBuyer(e ? e.value : "");
        }else if(type === 'seller'){
            setSelectedSeller(e ? e.value : "");
            setItemDetails({...itemDetails, overPrice:""});
            setSellerOverPrice(e ? e.op : "");
        }else if(type === 'direct'){
            setIsDerectChecked(!isDirectChecked);
        }else if(type === 'terms'){
            setSelectedTerm(e ? e.value : "");
        }    
    }

    // function to create order
    const handleOrderSubmit = async (e) => {
        e.preventDefault();
        if (!orderDetails.order_no || !orderDetails.date ||  !selectedBuyer || !selectedSeller || !selectedTerm) {
            setOrderError(true);
            return false;
        } else {
            dispatch(
                updateOrder({
                    id           : id,
                    order_no     : orderDetails?.order_no,
                    order_date   : orderDetails?.date,
                    buyer_id     : selectedBuyer,
                    seller_id    : selectedSeller,

                    is_revised      : revisedChecked,
                    revised_date    : orderDetails?.revised_date,
                    direct          : isDirectChecked, 
                    payment_term_id : selectedTerm,
                    validity        : orderDetails?.validity, 
                    shipment_date   : orderDetails?.shipment_date,
                    pre_remainder_date   : orderDetails?.pre_remainder_date,
                    shipment_term   : orderDetails?.shipment_term,

                    itemArray    : selectedItemArray,
                    totalCnf     : totalCnfValue,
                    totalQty     : totalQuantity,

                    is_message   : messageChecked,
                    message_info : orderDetails?.message_info,
                })
            );
        }
    };

    // Update the state when we change the item value
    const handleItemFormDetails = (e) => {
        // Validate barcode input against regex pattern
        if (e.target.name === 'barcode' && !/^\d{0,13}$/.test(e.target.value)) {
            return;
        }
        // Check if the input value is a valid number with at most 9 digits
        if ((e.target.name === 'basicPrice' || e.target.name === 'overPrice') && !/^\d{0,7}(\.\d{0,2})?$/.test(e.target.value)) {
            return;
        }
        setItemDetails({
            ...itemDetails, 
            [e.target.name]: e.target.value
        })
    }

    // Handle fonts checkbox change
    const handleFontCheckboxChange = (fonts) => {
        setFontValue((prevValues) => ({
        ...prevValues,
        [fonts]: !prevValues[fonts],
        }));
    };
    
    //set Price value of perticular item
    useEffect(()=>{
        if(itemDetails.basicPrice && itemDetails.overPrice){
            setItemPrice(parseFloat(itemDetails.basicPrice) + parseFloat(itemDetails.overPrice));
        }else if(itemDetails.basicPrice === ''){
            itemDetails.basicPrice = 0;
            setItemPrice(parseFloat(itemDetails.basicPrice) + parseFloat(itemDetails.overPrice));
        }else if(itemDetails.overPrice === ''){
            itemDetails.overPrice = 0;
            setItemPrice(parseFloat(itemDetails.basicPrice) + parseFloat(itemDetails.overPrice));
        }else{
            setItemPrice(parseFloat(itemDetails.basicPrice));
        }
    },[itemDetails.basicPrice,itemDetails.overPrice])

    //set CNF value of perticular item
    useEffect(()=>{
        if(itemPrice && itemDetails.quantity){
            setCnfValue(parseFloat(itemPrice * itemDetails.quantity).toFixed(2));
        }
    },[itemPrice,itemDetails.quantity])

    // when creatable is selected and if the value is not empty and not present in setForlist then push new vlaue and remove all existing created values
    const filterCreatable = (value, list, listState) =>{
        if(value && list.findIndex(option => option.id === value) === -1){
            listState(prevList => prevList.filter(option => typeof option.id !== 'string'));
            listState(prevList => [...prevList, {'id':value,'name':value}]);
        }
    }

    //only for item no
    const filterItemCreatable = (value, list, listState) =>{
        if(value && list.findIndex(option => option.id === value) === -1){
            listState(prevList => prevList.filter(option => typeof option.id !== 'string'));
            listState(prevList => [...prevList, {'id':value,'item_no':value}]);
        }
    }

    // set item properties in state
    const handleItemDetails = (e, type) =>{
        let value = e ? e.value : "";
        let label = e ? e.label : "";
        if(type === 'item'){
            filterItemCreatable(value, items, setItems);
            setSelectedItem(value);
            setSelectedItemNo(label);
        }else if(type === 'size'){
            filterCreatable(value, sizesList, setSizeslist);
            setSelectedSize(value);
        }else if(type === 'ratio'){
            filterCreatable(value, ratiosList, setRatioslist);
            setSelectedRatio(value);
        }else if(type === 'style'){
            filterCreatable(value, stylesList, setStyleslist);
            setSelectedStyle(value);
        }else if(type === 'for'){
            filterCreatable(value, forList, setForlist);
            setSelectedFor(value);
        }else if(type === 'assortment'){
            filterCreatable(value, forList, setAssortmentsList);
            setSelectedAssortment(value);
        }else if(type === 'category'){
            filterCreatable(value, categoriesList, setCategorieslist);
            setSelectedCategory(value);
        }else if(type === 'fabric'){
            filterCreatable(value, fabricsList, setFabricslist);
            setSelectedFabrics(value);
        }else if(type === 'brand'){
            filterCreatable(value, brandsList, setBrandsList);
            setSelectedBrand(value);
        }else if(type === 'color'){
            setSelectedColor(value);
        }else if(type === 'message'){
            setMessageChecked(!messageChecked);
        }else if(type === 'revised'){
            setRevisedChecked(!revisedChecked);
        }
    }

    // function to create or update item
    const handleItemSubmit = async (e) => {
        e.preventDefault();
        if (!selectedItem || !itemDetails?.quantity) {
            setItemError(true);
            return false;
        } else {
            dispatch(
                createUpdateItem({
                    item_id     : selectedItem,
                    item_no     : selectedItemNo,
                    buyer_id    : selectedBuyer,
                    seller_id   : selectedSeller,
                    barcode     : itemDetails?.barcode,
                    date        : orderDetails?.date,
                    basic_price : itemDetails?.basicPrice, 
                    over_price  : itemDetails?.overPrice, 
                    price       : itemPrice,
                    quantity    : itemDetails?.quantity,
                    size        : selectedSize,
                    ratio       : selectedRatio,
                    style       : selectedStyle,
                    for         : selectedFor,
                    category    : selectedCategory,
                    fabrics     : selectedFabrics,
                    color       : itemDetails?.color,
                    wash        : itemDetails?.wash,
                    assortment  : selectedAssortment,
                    brand       : selectedBrand,
                    cnfValue    : cnfValue,
                    extra       : itemDetails?.extra,
                    fonts       : fontValue,
                    item_color  : selectedColor,
                })
            )
        }
    };

    const showItemList = (itemId) => {
        const indexById = selectedItemArray.findIndex(item => 
            item.item_id === itemId && item.quantity === parseInt(itemDetails?.quantity),
        );
        const newItem = {
            order_id    : id,
            item_id     : itemId,
            item_no     : selectedItemNo,
            barcode     : itemDetails?.barcode,
            date        : orderDetails?.date,
            basic_price : itemDetails?.basicPrice, 
            over_price  : itemDetails?.overPrice, 
            price       : itemPrice,
            quantity    : itemDetails?.quantity,
            qnty_value  : itemDetails?.qntyValue,
            size        : selectedSize,
            ratio       : selectedRatio,
            style       : selectedStyle,
            for         : selectedFor,
            category    : selectedCategory,
            fabrics     : selectedFabrics,
            color       : itemDetails?.color,
            wash        : itemDetails?.wash,
            assortment  : selectedAssortment,
            brand       : selectedBrand,
            cnfValue    : cnfValue,
            extra       : itemDetails?.extra,
            fonts       : fontValue,
            item_color  : selectedColor,
        };
        let temp = [...selectedItemArray];
        
        // if item already exist then edit or insert accordingly
        if (indexById === -1) {
            temp = [...temp, newItem];
        } else {
            temp[indexById] = newItem;
        }
        setSelectedItemArray(temp);
            // Calculate the sum of cnfValue and quantity
        setTotalcnfValue(temp.reduce((acc, item) => acc + (parseFloat(item.cnfValue) || 0), 0));
        setTotalQuantity(temp.reduce((acc, item) => acc + (parseInt(item.quantity) || 0), 0));
    }

    // Remove the selected item from item array list
    const removeItem = (index) => {
        let temp = [...selectedItemArray];
        temp.splice(index, 1);
        setSelectedItemArray(temp);

        // Recalculate the totals after removing the item
        setTotalcnfValue(temp.reduce((acc, item) => acc + (parseFloat(item.cnfValue) || 0), 0));
        setTotalQuantity(temp.reduce((acc, item) => acc + (parseInt(item.quantity) || 0), 0));
    };

    // Edit the selected item or set that value again in item state
    const editSelectedItem = (index) => {
        let temp = selectedItemArray[index];
        setFlag(true);
        setItemDetails({
            // id          : temp?.id, 
            // item_no     : temp?.item_no, 
            // date        : temp?.date,
            extra       : temp?.extra,
            barcode     : temp?.barcode,
            basicPrice  : temp?.basic_price, 
            overPrice   : temp?.over_price, 
            price       : temp?.price,
            quantity    : temp?.quantity,
            qntyValue   : temp?.qnty_value,
            // itemPrice   : temp?.item_price,
            wash        : temp?.wash,
            color       : temp?.color,
        });
        setSelectedItem(temp?.item_id);
        setSelectedItemNo(temp?.item_no);
        setSelectedSize(temp?.size);
        setSelectedRatio(temp?.ratio);
        setSelectedStyle(temp?.style);
        setSelectedFor(temp?.for);
        setSelectedAssortment(temp?.assortment);
        setSelectedCategory(temp?.category);
        setSelectedFabrics(temp?.fabrics);
        setSelectedBrand(temp?.brand);
        setSelectedColor(temp?.item_color);
        setFontValue(temp?.fonts);
        setCnfValue(temp?.cnfValue);
    };

    // return array in lable value pair for each select option 
    const createOptionsArray = (dataArray, labelColumnName, valueColumnName, overPriceColumnName) => {
        return dataArray.map((data, index) => {
            return {
                label: data[labelColumnName],
                value: data[valueColumnName],
                op   : data[overPriceColumnName],
            };
        });
    };
    
    // return lable value index object with selected value in DB
    const findById = (optionsArray, targetId) => {
        return optionsArray.find(option => option.value === targetId);
    };

    //select data from reducer and set toaster here
    const updateOrderDtl = useSelector((state) => state.updateOrder.updateOrder)
    useEffect(() => {
        if (updateOrderDtl && updateOrderDtl?.data && updateOrderDtl?.data?.status === true) {
            // navigate("/orders")
            toast.success(updateOrderDtl?.data?.message,{theme: "dark",});
            dispatch(clearOrderMsg());
        }
        if (updateOrderDtl && updateOrderDtl?.data && updateOrderDtl?.data?.status === false) {
            toast.error(updateOrderDtl?.data?.message,{theme: "dark",});
            dispatch(clearOrderMsg());
        }
    }, [updateOrderDtl,navigate,dispatch]);

    //handle create and update item response
    const createUpdateItemDtl = useSelector((state) => state.createUpdateItem.createUpdateItem)
    useEffect(() => {
        if (createUpdateItemDtl && createUpdateItemDtl?.data && createUpdateItemDtl?.data?.status === true) {
            toast.success(createUpdateItemDtl?.data?.message,{theme: "dark",});
            // setItemId(createUpdateItemDtl?.data?.data?.id )
            
            // call the API for update items list by selected buyer or seller
            const requestData = {
                buyer_id  : selectedBuyer  || undefined,
                seller_id : selectedSeller || undefined,
            };
            if(selectedBuyer && selectedSeller){
                dispatch(getItemByIds(requestData));
            }
            setSelectedItem(createUpdateItemDtl?.data?.data?.id)
            showItemList(createUpdateItemDtl?.data?.data?.id);
            dispatch(clearItemMsg());
        }
        if (createUpdateItemDtl && createUpdateItemDtl?.data && createUpdateItemDtl?.data?.status === false) {
            toast.error(createUpdateItemDtl?.data?.message,{theme: "dark",});
            dispatch(clearItemMsg());
        }
    }, [createUpdateItemDtl,navigate,dispatch]);

    // function for open or close modal
    const modalBuyerFunnction =(type)=>{
        if(type === 'open') setBuyerModalIsOpen(true); else setBuyerModalIsOpen(false);
    }
    const modalSellerFunnction =(type)=>{
        if(type === 'open') setSellerModalIsOpen(true); else setSellerModalIsOpen(false);
    }

    // create buyer or seller return from props
    const handleReturnedBuyerValue = (value) => {
        dispatch(getBuyers());
        setCreateBuyer(true);
        setBuyerModalIsOpen(false); // Close the popup
    };
    // create seller return from props
    const handleReturnedSellerValue = (value) => {
        dispatch(getSellers());
        setCreateSeller(true);
        setSellerModalIsOpen(false); // Close the popup
    }


    return (<>
        <div className="dash-body">
            <div className="head-row">
                <h3>Edit Sales Contract Form</h3>
            </div>
            <div className="row">
                <div className="col-lg-8">
                    <form onSubmit={handleOrderSubmit} onKeyDown={(e) => { if (e.key === 'Enter') e.preventDefault(); }}>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="box-shd height-full">
                                    <div className="box-head">
                                        <h4>Order Information</h4>
                                    </div>
                                    <div className="box-body">
                                        <div className="row">
                                            <div className="col-sm-6 dash-form-col">
                                                <label>Order No<span className="text-danger">*</span></label>
                                                {/* <div className="row">
                                                    <div className="col-sm-4" style={{paddingRight:"0px" }}>
                                                        <input className="form-control" type="text" id="" name=""
                                                            value={prefix} readOnly
                                                            placeholder="Prefix" />
                                                    </div>
                                                    <div className="col-sm-6" style={{paddingLeft:"0px" }}> */}
                                                        <input className="form-control" type="text" id="" name="order_no" readOnly
                                                            value={orderDetails.order_no || ''}
                                                            onChange={handleOrderForm}
                                                            placeholder="Order No." maxLength="255"/>
                                                    {/* </div>
                                                </div> */}
                                                {orderError && !orderDetails?.order_no && (
                                                    <span style={{ color: "red" }} className="invalidInput" >
                                                        Required*
                                                    </span>
                                                )}
                                            </div>
                                            <div className="col-sm-6 dash-form-col">
                                                <label>Order Date<span className="text-danger">*</span></label>
                                                <input className="form-control" type="date"
                                                    value={orderDetails.date || ''}
                                                    onChange={handleOrderForm}
                                                    name="date"
                                                    placeholder="Enter Date"
                                                />
                                                {orderError && !orderDetails?.date && (
                                                    <span style={{ color: "red" }} className="invalidInput" >
                                                        Required*
                                                    </span>
                                                )}
                                            </div>
                                            <div className="col-sm-12 dash-form-col">
                                                <label>Buyer<span className="text-danger">*</span></label>
                                                <div className="add-m">
                                                    <Select
                                                        isClearable
                                                        // options={buyersList.map((data, index) => {
                                                        //     return {
                                                        //         label: data.name,
                                                        //         value: data.id,
                                                        //     };
                                                        // })}
                                                        // value={selectedBuyer}
                                                        value={[findById(createOptionsArray(buyersList, 'name', 'id'), selectedBuyer)]}
                                                        options={createOptionsArray(buyersList, 'name', 'id')}
                                                        onChange={(e) => handleOrderDetails(e, 'buyer')}
                                                        placeholder="Select Buyer"
                                                    />
                                                    {(userData?.role === 1)?
                                                        <button type="button" className="add-p" onClick={() => modalBuyerFunnction('open')}>
                                                            <i className="fa-solid fa-circle-plus"></i> 
                                                        </button>
                                                    :""}
                                                </div>
                                                {orderError && !selectedBuyer && (
                                                    <span style={{ color: "red" }} className="invalidInput" >
                                                        Required*
                                                    </span>
                                                )}
                                            </div>
                                            <div className="col-sm-12 dash-form-col">
                                                <label>Seller<span className="text-danger">*</span></label>
                                                <div className="add-m">
                                                    <Select
                                                        isClearable
                                                        // options={sellersList.map((data, index) => {
                                                        //     return {
                                                        //         label: data.name,
                                                        //         value: data.id,
                                                        //     };
                                                        // })}
                                                        value={[findById(createOptionsArray(sellersList, 'name', 'id','over_price'), selectedSeller)]}
                                                        options={createOptionsArray(sellersList, 'name', 'id', 'over_price')}
                                                        placeholder="Select Seller"
                                                        onChange={(e) => handleOrderDetails(e, 'seller')}
                                                    />
                                                    {(userData?.role === 1)?
                                                        <button type="button" className="add-p" onClick={() => modalSellerFunnction('open')}>
                                                            <i className="fa-solid fa-circle-plus"></i>
                                                        </button>
                                                    :""}
                                                </div>
                                                {orderError && !selectedSeller && (
                                                    <span style={{ color: "red" }} className="invalidInput" >
                                                        Required*
                                                    </span>
                                                )}
                                            </div>
                                            <div className="col-sm-12 dash-form-col">
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" type="checkbox" id="inlineCheckbox1" 
                                                        value={1}
                                                        checked={revisedChecked}
                                                        onChange={(e) => handleItemDetails(e,'revised')}/>
                                                    <label className="form-check-label" htmlFor="inlineCheckbox1">Revised</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    {/* <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"/> */}
                                                    <input
                                                        name="direct"
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="inlineCheckbox2"
                                                        value={1}  // Set the value to "1"
                                                        checked={isDirectChecked}
                                                        onChange={(e) => handleOrderDetails(e, 'direct')}
                                                    />
                                                    <label className="form-check-label" htmlFor="inlineCheckbox2">Direct Order</label>
                                                </div>
                                                {(revisedChecked) ?
                                                    <div className="col-sm-6 dash-form-col">
                                                        <label>Revised Date</label>
                                                        <input className="form-control" type="date"
                                                            value={orderDetails.revised_date || ''}
                                                            onChange={handleOrderForm}
                                                            name="revised_date"
                                                            />
                                                    </div>
                                                :""}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="box-shd">
                                    <div className="box-head">
                                        <h4>Payment Terms</h4>
                                    </div>
                                    <div className="box-body">
                                        <div className="row">
                                            <div className="col-sm-6 dash-form-col m-0">
                                                <label>Payment Terms<span className="text-danger">*</span></label>
                                                <CreatableSelect
                                                    isClearable
                                                    // options={termsList.map((data, index) => {
                                                    //     return {
                                                    //         label: data.name,
                                                    //         value: data.id,
                                                    //     };
                                                    // })}
                                                    onInputChange={(inputValue) => inputValue.length > 230 ? inputValue.substring(0, 230) : inputValue}
                                                    value={[findById(createOptionsArray(termsList, 'name', 'id'), selectedTerm)]}
                                                    options={createOptionsArray(termsList, 'name', 'id')}
                                                    onChange={(e) => handleOrderDetails(e, 'terms')}
                                                    placeholder="Select Type"
                                                />
                                                {orderError && !selectedTerm && (
                                                    <span style={{ color: "red" }} className="invalidInput" >
                                                        Required*
                                                    </span>
                                                )}
                                            </div>
                                            <div className="col-sm-6 dash-form-col">
                                                <label>Validity</label>
                                                <input className="form-control" type="text" id="validity" name="validity"
                                                    value={orderDetails.validity || ''}
                                                    onChange={handleOrderForm}
                                                    placeholder="Awaiting"
                                                    maxLength="255"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="box-shd">
                                    <div className="box-head">
                                        <h4>Shipping Terms</h4>
                                    </div>
                                    <div className="box-body">
                                        <div className="row">
                                            <div className="col-sm-6 dash-form-col">
                                                <label>Shipment Date</label>
                                                <input className="form-control" type="date"
                                                    value={orderDetails.shipment_date || ''}
                                                    onChange={handleOrderForm}
                                                    name="shipment_date"
                                                    />
                                            </div>
                                            <div className="col-sm-6 dash-form-col">
                                                <label>Pre-Remainder Date</label>
                                                <input className="form-control" type="date"
                                                    value={orderDetails.pre_remainder_date || ''}
                                                    onChange={handleOrderForm}
                                                    name="pre_remainder_date"
                                                    />
                                            </div>
                                            <div className="col-sm-12 dash-form-col">
                                                <label>Shipment Terms</label>
                                                <input className="form-control" type="text" id="" name="shipment_term" readOnly
                                                    value={orderDetails.shipment_term || ''}
                                                    onChange={handleOrderForm}
                                                    placeholder="Partial / Trans Shipment Allowed" 
                                                    maxLength="255"
                                                    />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="table-responsive dash-table box-shd">
                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                        <thead>
                                            <tr>
                                                <th>Edit</th>
                                                <th>Delete</th>
                                                <th>Item Number</th>
                                                <th>Barcode</th>
                                                <th>Basic Price</th>
                                                <th>Over Price</th>
                                                <th>Price</th>
                                                <th>Size</th>
                                                <th>Ratio</th>
                                                <th>Style</th>
                                                <th>For</th>
                                                <th>Assortment</th>
                                                <th>Category</th>
                                                <th>Fabric</th>
                                                <th>Color</th>
                                                <th>Wash</th>
                                                <th>Brand</th>
                                                <th>Fonts</th>
                                                <th>Item Color</th>
                                                <th>Qnty Value</th>
                                                <th>Quantity</th>
                                                <th>Value</th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-nowrap">
                                        {(selectedItemArray.length > 0 ) ?
                                            <>
                                                {selectedItemArray.map((item, index) => (
                                                    <tr key={index}>
                                                        <td> 
                                                            <Link onClick={() => editSelectedItem(index)}> <i className="fa-solid fa-pencil"></i> </Link> 
                                                        </td>
                                                        <td>
                                                            <Link  onClick={() => removeItem(index)}><i className="fa-solid fa-trash-can-xmark"></i></Link>
                                                        </td>
                                                        <td>{!(item?.item_no) ? '-' : (items.find(itemData => itemData.id === item?.item_no)?.item_no || item?.item_no)}</td>
                                                        <td>{(item?.barcode) ? item?.barcode :'-'}</td>
                                                        <td>{(item?.basic_price) ? item?.basic_price :'-'}</td>
                                                        <td>{(item?.over_price) ? item?.over_price :'-'}</td>
                                                        <td>{(item?.price) ? item?.price :'-'}</td>
                                                        <td>{!(item?.size) ? '-' : (sizesList.find(size => size.id === item?.size)?.name || item?.size)}</td>
                                                        <td>{!(item?.ratio) ? '-' : (ratiosList.find(ratio => ratio.id === item?.ratio)?.name || item?.ratio)}</td>
                                                        <td>{!(item?.style) ? '-' : (stylesList.find(style => style.id === item?.style)?.name || item?.style)}</td>
                                                        <td>{!(item?.for) ? '-' : (forList.find(fors => fors.id === item?.for)?.name || item?.for)}</td>
                                                        <td>{!(item?.assortment) ? '-' : (assortmentsList.find(assortments => assortments.id === item?.assortment)?.name || item?.assortment)}</td>
                                                        <td>{!(item?.category) ? '-' : (categoriesList.find(category => category.id === item?.category)?.name || item?.category)}</td>
                                                        <td>{!(item?.fabrics) ? '-' : (fabricsList.find(fabric => fabric.id === item?.fabrics)?.name || item?.fabric)}</td>
                                                        <td>{(item?.color) ? item?.color :'-'}</td>
                                                        <td>{(item?.wash) ? item?.wash :'-'}</td>
                                                        <td>{!(item?.brand) ? '-' : (brandsList.find(brand => brand.id === item?.brand)?.name || item?.brand)}</td>
                                                        <td>
                                                            {item?.fonts ? Object.entries(item.fonts)
                                                                .filter(([key, value]) => value === true)
                                                                .map(([key, value]) => (
                                                                    <span key={key}>{key},</span>
                                                                ))
                                                            : '-'}
                                                        </td>
                                                        <td>{(item?.item_color) ? item?.item_color :'-'}</td>
                                                        <td>{(item?.qnty_value) ? (item?.qnty_value === 1) ? "DNZ" : "PCS"  :'-'}</td>
                                                        <td>{(item?.quantity) ? item?.quantity :'-'}</td>
                                                        <td>{(item?.cnfValue) ? '$' + item?.cnfValue :'-'}</td>
                                                    </tr>
                                                ))}
                                            </>
                                        : (
                                            <tr>
                                                <td colSpan={21} align={'center'}> No selected items </td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="box-shd">
                                    <div className="box-head">
                                        <h4>Total Costing</h4>
                                    </div>
                                    <div className="box-body">
                                        <div className="row">
                                            <div className="col-sm-6 dash-form-col">
                                                <label>Total Amount US($)</label>
                                                <input type="text" value={(totalCnfValue) ? parseFloat(totalCnfValue).toFixed(2) : '-'} readOnly className="form-control" placeholder="$0.00"/>
                                            </div>
                                            <div className="col-sm-6 dash-form-col">
                                                <label>Total Qty</label>
                                                <input type="text" value={totalQuantity} readOnly className="form-control" placeholder="0"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="box-shd">
                                    <div className="box-head">
                                        <h4>Order Message</h4>
                                    </div>
                                    <div className="box-body">
                                        <div className="row">
                                            <div className="col-sm-12 dash-form-col">
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input"
                                                        type="checkbox"
                                                        id="inlineCheckbox6"
                                                        value={1}
                                                        checked={messageChecked}
                                                        onChange={(e) => handleItemDetails(e,'message')}
                                                    />
                                                    <label className="form-check-label" htmlFor="inlineCheckbox6">Message</label>
                                                </div>
                                            </div>
                                            {(messageChecked) ? 
                                                <div className="col-sm-12 dash-form-col">
                                                    <label>Message Info</label>
                                                    <textarea className="form-control" type="text" id="message_info" name="message_info"
                                                        value={orderDetails.message_info || ''}
                                                        onChange={handleOrderForm}
                                                        placeholder="Type Message..." />
                                                </div>
                                            : "" }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="dash-btn-group">
                                    <button type="submit" className="cus-btn">Save</button>
                                    <Link to={`/order/order-report/${id}`} ><button className="cus-btn border-btn">View</button></Link>
                                    <Link to={"/orders"} type="button" className="cus-btn border-btn">Cancel</Link>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                    <div className="col-lg-4">
                        <div className="box-shd">
                            <div className="box-head">
                                <h4>Item Information</h4>
                            </div>
                            <form onSubmit={handleItemSubmit} onKeyDown={(e) => { if (e.key === 'Enter') e.preventDefault(); }}>
                                <div className="box-body">
                                    <div className="row">
                                        <div className="col-sm-6 dash-form-col">
                                            <label>Item Number<span className="text-danger">*</span></label>
                                            <CreatableSelect
                                                isClearable
                                                // options={items.map((data, index) => {
                                                //     return {
                                                //         label: data.item_no,
                                                //         value: data.id,
                                                //     };
                                                // })}
                                                value={[findById(createOptionsArray(items, 'item_no', 'id'), selectedItem)]}
                                                options={createOptionsArray(items, 'item_no', 'id')}
                                                onChange={(e) => handleItemDetails(e, 'item')}
                                                onInputChange={(inputValue) => inputValue.length > 250 ? inputValue.substring(0, 250) : inputValue}
                                                placeholder="Select Item"
                                            />
                                            {itemError && !selectedItem && (
                                                <span style={{ color: "red" }} className="invalidInput" >
                                                    Required*
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-sm-6 dash-form-col">
                                            <label>Bar Code</label>
                                            <input className="form-control" type="number" id="barcode" name="barcode"
                                                value={itemDetails.barcode || ''}
                                                onChange={handleItemFormDetails}
                                                placeholder="Enter Bar Code"
                                                min="0" />
                                        </div>
                                        <div className="col-sm-6 dash-form-col">
                                            <label>Basic Price US ($)</label>
                                            <input className="form-control" type="integer" id="basicPrice" name="basicPrice"
                                                value={itemDetails.basicPrice || ''}
                                                onChange={handleItemFormDetails}
                                                placeholder="$0.00"  min="0"/>
                                        </div>
                                        <div className="col-sm-6 dash-form-col">
                                            <label>Over Price US ($)</label>
                                            <input className="form-control" type="integer"
                                                name="overPrice"
                                                readOnly={selllerOverPrice ? false : true}
                                                style={selllerOverPrice ? {} : {opacity: "0.5"}}
                                                value={itemDetails.overPrice || ''}
                                                onChange={handleItemFormDetails}
                                                placeholder="$0.00"
                                                min="0"
                                            />
                                        </div>
                                        <div className="col-sm-4 dash-form-col">
                                            <label>Price US ($)</label>
                                            <input className="form-control" type="number" id="price" name="price" readOnly
                                                value={parseFloat(itemPrice || '').toFixed(2)}
                                                onChange={handleItemFormDetails}
                                                placeholder="$0.00" 
                                            />
                                        </div>
                                        <div className="col-sm-4 dash-form-col">
                                            <label>Quantity<span className="text-danger">*</span></label>
                                            <input className="form-control" type="number" id="quantity" name="quantity"
                                                value={itemDetails.quantity || ''}
                                                onChange={handleItemFormDetails}
                                                placeholder="0" 
                                                min="0"
                                            />
                                            {itemError && !itemDetails?.quantity && (
                                                <span style={{ color: "red" }} className="invalidInput" >
                                                    Required*
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-sm-4 dash-form-col">
                                            <label>Qnty Value</label>
                                            <select className="form-control" value={itemDetails.qntyValue} onChange={handleItemFormDetails} type="number" id="qntyValue" name="qntyValue">
                                                <option value={1}>DNZ</option>
                                                <option value={2}>PCS</option>
                                            </select>
                                        </div>
                                        <div className="col-sm-6 dash-form-col">
                                            <label>Size</label>
                                            <CreatableSelect
                                                isClearable
                                                value={[findById(createOptionsArray(sizesList, 'name', 'id'), selectedSize)]}
                                                options={createOptionsArray(sizesList, 'name', 'id')}
                                                placeholder="Select Size"
                                                onChange={(e) => handleItemDetails(e, 'size')}
                                                onInputChange={(inputValue) => inputValue.length > 250 ? inputValue.substring(0, 250) : inputValue}
                                            />
                                        </div>
                                        <div className="col-sm-6 dash-form-col">
                                            <label>Ratio</label>
                                            <CreatableSelect
                                                isClearable
                                                value={[findById(createOptionsArray(ratiosList, 'name', 'id'), selectedRatio)]}
                                                options={createOptionsArray(ratiosList, 'name', 'id')}
                                                placeholder="Select Ratio"
                                                onChange={(e) => handleItemDetails(e, 'ratio')}
                                                onInputChange={(inputValue) => inputValue.length > 250 ? inputValue.substring(0, 250) : inputValue}
                                            />
                                        </div>
                                        <div className="col-sm-6 dash-form-col">
                                            <label>Style</label>
                                            <CreatableSelect
                                                isClearable
                                                value={[findById(createOptionsArray(stylesList, 'name', 'id'), selectedStyle)]}
                                                options={createOptionsArray(stylesList, 'name', 'id')}
                                                placeholder="Select Style"
                                                onChange={(e) => handleItemDetails(e, 'style')}
                                                onInputChange={(inputValue) => inputValue.length > 250 ? inputValue.substring(0, 250) : inputValue}
                                            />
                                        </div>
                                        <div className="col-sm-6 dash-form-col">
                                            <label>For</label>
                                            <CreatableSelect
                                                isClearable
                                                value={[findById(createOptionsArray(forList, 'name', 'id'), selectedFor)]}
                                                options={createOptionsArray(forList, 'name', 'id')}
                                                placeholder="Select For"
                                                onChange={(e) => handleItemDetails(e, 'for')}
                                                onInputChange={(inputValue) => inputValue.length > 250 ? inputValue.substring(0, 250) : inputValue}
                                            />
                                        </div>
                                        <div className="col-sm-6 dash-form-col">
                                            <label>Assortment</label>
                                            <CreatableSelect
                                                isClearable
                                                value={[findById(createOptionsArray(assortmentsList, 'name', 'id'), selectedAssortment)]}
                                                options={createOptionsArray(assortmentsList, 'name', 'id')}
                                                placeholder="Select Assortment"
                                                onChange={(e) => handleItemDetails(e, 'assortment')}
                                                onInputChange={(inputValue) => inputValue.length > 250 ? inputValue.substring(0, 250) : inputValue}
                                            />
                                        </div>
                                        <div className="col-sm-6 dash-form-col">
                                            <label>Category</label>
                                            <CreatableSelect
                                                isClearable
                                                value={[findById(createOptionsArray(categoriesList, 'name', 'id'), selectedCategory)]}
                                                options={createOptionsArray(categoriesList, 'name', 'id')}
                                                placeholder="Select Category"
                                                onChange={(e) => handleItemDetails(e, 'category')}
                                                onInputChange={(inputValue) => inputValue.length > 250 ? inputValue.substring(0, 250) : inputValue}
                                            />
                                        </div>
                                        <div className="col-sm-6 dash-form-col">
                                            <label>Fabric</label>
                                            <CreatableSelect
                                                isClearable
                                                value={[findById(createOptionsArray(fabricsList, 'name', 'id'), selectedFabrics)]}
                                                options={createOptionsArray(fabricsList, 'name', 'id')}
                                                placeholder="Select Fabric"
                                                onChange={(e) => handleItemDetails(e, 'fabric')}
                                                onInputChange={(inputValue) => inputValue.length > 250 ? inputValue.substring(0, 250) : inputValue}
                                            />
                                        </div>
                                        <div className="col-sm-6 dash-form-col">
                                            <label>Color</label>
                                            <input className="form-control" type="text" id="color" name="color"
                                                value={itemDetails.color || ''}
                                                onChange={handleItemFormDetails}
                                                placeholder="Enter Color" maxLength="255"/>
                                        </div>
                                        <div className="col-sm-6 dash-form-col">
                                            <label>Wash</label>
                                            <input className="form-control" type="text" id="wash" name="wash"
                                                value={itemDetails.wash || ''}
                                                onChange={handleItemFormDetails}
                                                placeholder="Enter Wash" maxLength="255"/>
                                        </div>
                                        <div className="col-sm-6 dash-form-col">
                                            <label>Brand</label>
                                            <CreatableSelect
                                                isClearable
                                                value={[findById(createOptionsArray(brandsList, 'name', 'id'), selectedBrand)]}
                                                options={createOptionsArray(brandsList, 'name', 'id')}
                                                placeholder="Select Brand"
                                                onChange={(e) => handleItemDetails(e, 'brand')}
                                                onInputChange={(inputValue) => inputValue.length > 250 ? inputValue.substring(0, 250) : inputValue}
                                            />
                                        </div>
                                        <div className="col-sm-6 dash-form-col">
                                            <label>Value US($)</label>
                                            <input className="form-control" type="text" readOnly
                                                value={cnfValue || ''}
                                                // onChange={handleItemFormDetails}
                                                placeholder="$0.00" />
                                        </div>
                                        <div className="col-sm-12 dash-form-col">
                                            <label>Extra Info</label>
                                            <textarea className="form-control" type="text" id="extra" name="extra"
                                                value={itemDetails.extra || ''}
                                                onChange={handleItemFormDetails}
                                                placeholder="Type..." />
                                        </div>
                                        <div className="col-sm-12 dash-form-col">
                                            <div className="form-check form-check-inline">
                                                {/* <input className="form-check-input" type="checkbox" id="inlineCheckbox3" value="option1"/> */}
                                                <input className="form-check-input"
                                                    type="checkbox"
                                                    id="inlineCheckbox3"
                                                    value={fontValue.bold}
                                                    checked={fontValue.bold}
                                                    onChange={() => handleFontCheckboxChange('bold')}
                                                />
                                                <label className="form-check-label" htmlFor="inlineCheckbox3">Bold</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                {/* <input className="form-check-input" type="checkbox" id="inlineCheckbox4" value="option2"/> */}
                                                <input className="form-check-input"
                                                    type="checkbox"
                                                    id="inlineCheckbox4"
                                                    value={fontValue.italic}
                                                    checked={fontValue.italic}
                                                    onChange={() => handleFontCheckboxChange('italic')}
                                                />
                                                <label className="form-check-label" htmlFor="inlineCheckbox4">Italic</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                {/* <input className="form-check-input" type="checkbox" id="inlineCheckbox5" value="option2"/> */}
                                                <input className="form-check-input"
                                                    type="checkbox"
                                                    id="inlineCheckbox5"
                                                    value={fontValue.underline}
                                                    checked={fontValue.underline}
                                                    onChange={() => handleFontCheckboxChange('underline')}
                                                />
                                                <label className="form-check-label" htmlFor="inlineCheckbox5">Underline</label>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 dash-form-col">
                                            <label>Item Color</label>
                                            <Select
                                                isClearable
                                                options={options}
                                                value={options.find(option => option.value === selectedColor)}
                                                styles={{
                                                    option: (provided, state) => ({
                                                      ...provided,
                                                      backgroundColor: state.data.color,
                                                      color: 'white', // You can adjust text color based on the background color
                                                      padding: '8px',
                                                      borderRadius: '10px',
                                                    }),
                                                    singleValue: (provided, state) => ({
                                                        ...provided,
                                                        backgroundColor: state.data.color,
                                                        color: 'white',
                                                        padding: '8px',
                                                        borderRadius: '10px',
                                                    }),
                                                }}
                                                onChange={(e) => handleItemDetails(e, 'color')}
                                                placeholder="Choose Color"
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <div className="dash-btn-group">
                                                <button type="submit" className="cus-btn">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
            </div>
        </div>

        {/* Modal to create buyer */}
        <Modal isOpen={buyerModalIsOpen} onRequestClose={modalBuyerFunnction} style={customStyles} >
            <span className="close-modal float-end pe-4 pt-4 text-danger" style={{cursor:'pointer'}} onClick={modalBuyerFunnction}>X</span>
            <BuyerCreate {...salesProps} onReturn={handleReturnedBuyerValue} />
        </Modal>

        {/* Modal to show Item umpdated Price  */}
        <Modal isOpen={sellerModalIsOpen} onRequestClose={modalSellerFunnction} style={customStyles} >
            <span className="close-modal float-end pe-4 pt-4 text-danger" style={{cursor:'pointer'}} onClick={modalSellerFunnction}>X</span>
            <SellerCreate {...salesProps} onReturn={handleReturnedSellerValue}/>
        </Modal>
    </>);
}

export default OrderUpdate;
