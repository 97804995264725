import React, { useState, useEffect } from "react";
import { loginUser } from "../../../actions/authAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from 'react-toastify';


const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState("");
    const [error, setError] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!email || !password) {
            setError(true);
            return false;
        }
        dispatch(loginUser({ email: email, password: password }));
    };
   
    // hide or show password
    const togglePasswordVisibility = (type) => {
        setShowPassword(!showPassword);
    };

    const authuser = useSelector((state) => state.userLogin);
    useEffect(() => {
        const registerDeviceToken = async () => {
            if (authuser && authuser?.authuser && authuser?.authuser?.data && authuser?.authuser?.data?.status === true) {
                // navigate("/dashboard");
                window.location.href = "/dashboard";
                toast.success(authuser?.authuser?.data?.message,{theme: "dark",});
            }
            if(authuser && authuser?.authuser && authuser?.authuser?.data && authuser?.authuser?.data?.status === false){
                toast.error(authuser?.authuser?.data?.message,{theme: "dark",});
            }
        };
        registerDeviceToken();
    }, [authuser,navigate]);

    return (
        <>
            <div className="login-bg">
                <div className="ligin-container">
                    <div className="login-logo">
                        <img src="../img/logo.png" alt=""/>
                        <p>Enter the information you entered while registering</p>
                    </div>
                    <form action="#" onSubmit={handleSubmit}>
                        <div className="login-form">
                            <label htmlFor="u-name">Email</label>
                            <div className="login-form-col">
                                <input
                                    className="form-control"
                                    type="email"
                                    value={email}
                                    placeholder="Enter Email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    id="u-name"
                                    name="username"
                                />
                                {error && !email && (
                                    <span style={{ color: "red" }} className="invalidInput" >
                                        Required*
                                    </span>
                                )}
                            </div>
                            <label>Password</label>
                            <div className="login-form-col" style={{ position: 'relative' }}>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}
                                    placeholder="Enter Password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    id="u-password"
                                    className="form-control"
                                    name="password"
                                />
                                <button
                                    type="button"
                                    onClick={() => {togglePasswordVisibility('new')}}
                                    style={{
                                        position: 'absolute',
                                        right: '10px',
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                        cursor: 'pointer',
                                        border: 'none',
                                        background: 'none',
                                    }}
                                    >
                                    {showPassword ? (
                                        <i className="fa fa-eye-slash" style={{ fontSize: '18px' }}></i>
                                    ) : (
                                        <i className="fa fa-eye" style={{ fontSize: '18px' }}></i>
                                    )}
                                </button>
                                {error && !password && (
                                    <span style={{ color: "red" }} className="invalidInput">
                                        Required*
                                    </span>
                                )}
                            </div>
                            <button type="submit" className="cus-btn">Login</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default Login;
