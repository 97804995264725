import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import moment from 'moment';

const OrderStatusPDF = ({ orderDetails, orderQtyTotal,shippedQtyTotal,pendingQtyTotal,orderAmountTotal, shippedAmountTotal, pendingAmountTotal }) => {
    
    // This function adds commas to a number for every thousand separator.
    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const styles = StyleSheet.create({
        page: {
            backgroundColor: '#ffffff',
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 10,
            paddingBottom: 30
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1,
        },
        header: {
            fontSize: 24,
            marginBottom: 20,
            textAlign: 'center',
            color: 'black',
        },
        subheader: {
            fontSize: 15,
            marginBottom: 10,
            color: 'blue',
        },
        text: {
            fontSize: 10,
            marginBottom: 5,
            color: '#39566b',
        },
        table: {
            display: 'table',
            width: '100%',
            borderStyle: 'solid',
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0
        },
        tableRow: {
            margin: 'auto',
            flexDirection: 'row',
            width: '200%'
        },
        text1: {
            fontSize: 10,
            marginBottom: 5,
            color: '#39566b',
        },
        tableCol: {
            padding: '5px',
            width: '5%',
            borderStyle: 'solid',
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            backgroundColor: '#E6E2E2',
            fontSize : 6
        },
        tableFoot: {
            padding: '5px',
            width: '5%',
            borderStyle: 'solid',
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            backgroundColor: '#E6E2E2',
            fontSize : 8,
        },
        tableDataCol: {
            padding: '5px',
            width: '5%',
            borderStyle: 'solid',
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            backgroundColor: '#fff'
        },
        image: {
            width: 60,
            left: 10,
        },
        footer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            // position: 'absolute',
            // bottom: -5,
            left: 0,
            right: 0,
            // marginBottom: '10',
            padding: 10,
            borderTop: '1px',
        },

        text2: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginVertical: 10,
            display: "flex"
        },
        column: {
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            marginHorizontal: 5,
        },
        row: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
        },

        container: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center', // Align items vertically in the center
        },
        labelText: {
            marginRight: 7, // Adjust spacing between the label and value as needed
            fontSize: 10,
            fontWeight: '700'
        },
        valueText: {
            fontSize: 9,
            // marginBottom: 2,
            color: '#39566b',
        },
        colText: {
            fontSize: 7,
            color: '#39566b',
        },
        heading: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: "5px"
        },
        columnHeader: {
            fontSize: 9,
            marginBottom: 5,
            textAlign : 'center',
            // whiteSpace : 'nowrap'
        },
        logoContainer: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        logo: {
            width: '35%',
            resizeMode: 'contain',
        },
        companyInfo: {
            width: '65%',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 10,
        },

        statusContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center', // Align items horizontally in the center
            marginTop: 10,
        },
        setStatusContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            // justifyContent: 'space-between', // Align items horizontally in the center

            border: 1,
            padding: 2,
            borderBottom: 3,
            borderRight: 3,
            width: "100%",
        },
        inlineText: {
            marginHorizontal: 3, // Adjust as needed for spacing between the two Text components
        },
        companyName: {
            fontSize: 20,
            fontWeight: '700',
            textTransform: "uppercase",
        },
        companyDescription: {
            fontSize: 13,
            fontWeight: '500',
        },
        statusLabel: {
            fontSize: 13,
            fontWeight: '600',
            marginBottom: 20    
        },
        orderNumber: {
            fontSize: 18,
        },
        imageContainer: {
            alignItems: 'center',
            justifyContent: 'center',
        },
        titleCenter: {
            alignItems: 'center',
            width: "80%"
        },
        reportDescription:{ fontSize: 12, fontWeight: '600'},
    });

    var srcLogo = process.env.REACT_APP_PDF_LOGO+'img/logo.png';

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <View>
                        <View style={styles.logoContainer}>
                            <View style={styles.setStatusContainer}>
                                <View style={styles.imageContainer}>
                                    <Image src={srcLogo} style={styles.image} />
                                </View>
                                <View style={styles.titleCenter}>
                                    <Text style={[styles.companyName, styles.inlineText]}>Maruti Overseas Corp.</Text>
                                    <Text style={[styles.companyDescription]}>Importers & Indentors</Text>
                                </View>
                            </View>
                        </View>
                        <View style={styles.statusContainer}>
                            <Text style={styles.statusLabel}>Status by Order Number : {orderDetails?.order_no}</Text>
                        </View>
                    </View>

                    {/* <Text style={styles.subheader}>Order Details</Text> */}
                    <View style={styles.text2}>
                        <View style={styles.column}>
                            <View style={styles.heading}>
                                <Text style={styles.labelText}>Buyer :</Text>
                                <Text style={styles.valueText}>{(orderDetails?.buyers?.name) ? orderDetails?.buyers?.name : '-'}</Text>
                            </View>
                            <View style={styles.heading}>
                                <Text style={styles.labelText}>Seller :</Text>
                                <Text style={styles.valueText}>{(orderDetails?.sellers?.name) ? orderDetails?.sellers?.name : '-'}</Text>
                            </View>
                            <View style={styles.heading}>
                                <Text style={styles.labelText}>Order Date :</Text>
                                <Text style={styles.valueText}>{(orderDetails?.order_date) ? moment(orderDetails?.order_date).format('DD-MMM-YYYY') : '-'}</Text>
                            </View>
                            <View style={styles.heading}>
                                <Text style={styles.labelText}>Shipment Date :</Text>
                                <Text style={styles.valueText}>{(orderDetails?.shipment_date) ? moment(orderDetails?.order_date).format('DD-MMM-YYYY') : '-'}</Text>
                            </View>
                        </View>
                        <View style={styles.column}>
                            <View style={styles.heading}>
                                <Text style={styles.labelText}>Order Quantity [Dz] :</Text>
                                <Text style={styles.valueText}>{(orderQtyTotal) ? orderQtyTotal : 0}</Text>
                            </View>
                            <View style={styles.heading}>
                                <Text style={styles.labelText}>Shipped Quantity [Dz] :</Text>
                                <Text style={styles.valueText}>{(shippedQtyTotal) ? shippedQtyTotal : 0}</Text>
                            </View>
                            <View style={styles.heading}>
                                <Text style={styles.labelText}>Pending Quantity [Dz] :</Text>
                                <Text style={styles.valueText}>{(pendingQtyTotal) ? pendingQtyTotal : 0}</Text>
                            </View>
                        </View>
                        <View style={styles.column}>
                            <View style={styles.heading}>
                                <Text style={styles.labelText}>Order Amount US($) :</Text>
                                <Text style={styles.valueText}>{(orderAmountTotal) ? '$'+numberWithCommas(parseFloat(orderAmountTotal).toFixed(2)) : '0'}</Text>
                            </View>
                            <View style={styles.heading}>
                                <Text style={styles.labelText}>Shipped Amount US($) :</Text>
                                <Text style={styles.valueText}>{(shippedAmountTotal) ? '$'+numberWithCommas(parseFloat(shippedAmountTotal).toFixed(2)) : '0'}</Text>
                            </View>
                            <View style={styles.heading}>
                                <Text style={styles.labelText}>Pending Amount US($) :</Text>
                                <Text style={styles.valueText}>{(pendingAmountTotal) ? '$'+numberWithCommas(parseFloat(pendingAmountTotal).toFixed(2)) : '0'}</Text>
                            </View>
                        </View>
                    </View>
                    {/* <Text style={styles.subheader}>Order Items</Text> */}
                    <View style={styles.table}>
                        <View style={styles.tableRow} fixed>
                            <View style={styles.tableCol}>
                                <Text style={styles.columnHeader}>Invoice No.</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.columnHeader}>Invoice Date</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.columnHeader}>Item Number</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.columnHeader}>Item Price</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.columnHeader}>Order Qty (Dz)</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.columnHeader}>Shipped Qty (Dz)</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.columnHeader}>Pending Qty (Dz)</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.columnHeader}>Order Amount US($)</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.columnHeader}>Shipped Amount US($)</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.columnHeader}>Pending Amount US($)</Text>
                            </View>
                        </View>
                        {/* Loop through order items */}
                        {orderDetails?.order_item?.map((order,index) => (
                            (order.invoice_order?.length ? order.invoice_order : [null]).map((invoice,index) => (
                            <View style={styles.tableRow} key={index}>
                                <View style={styles.tableDataCol}>
                                    <Text style={styles.colText}>{(invoice?.invoice?.invoice_no)?(invoice?.invoice?.invoice_no) : '-'}</Text>
                                </View>
                                <View style={styles.tableDataCol}>
                                    <Text style={styles.colText}>{(invoice?.invoice?.invoice_date) ? moment(invoice?.invoice?.invoice_date).format('DD-MMM-YYYY') : '-'}</Text>
                                </View>
                                <View style={styles.tableDataCol}>
                                    <Text style={styles.colText}>{(order?.items?.item_no)?(order?.items?.item_no) : '-'}</Text>
                                </View>
                                <View style={styles.tableDataCol}>
                                    <Text style={styles.colText}>{(order?.items?.price)?(order?.items?.price) : '-'}</Text>
                                </View>
                                <View style={styles.tableDataCol}>
                                    <Text style={styles.colText}>{(order?.quantity) ? (order?.quantity) + ((order?.qnty_value === 1) ? ' (dzn)' : ' (pcs)') : (order?.quantity) ? order?.quantity : 0}</Text>
                                </View>
                                <View style={styles.tableDataCol}>
                                    <Text style={styles.colText}>{(invoice?.shipped_quantity)?(invoice?.shipped_quantity) : 0}</Text>
                                </View>
                                <View style={styles.tableDataCol}>
                                    {/* Calculate pending quantity */}
                                    <Text style={styles.colText}>{numberWithCommas((((invoice?.quantity) ? invoice?.quantity : (order?.quantity) ? order?.quantity : 0) - ((invoice?.shipped_quantity) ? (invoice?.shipped_quantity) : 0)))}</Text>
                                </View>
                                <View style={styles.tableDataCol}>
                                    {/* Calculate order amount */}
                                    <Text style={styles.colText}>{numberWithCommas(parseFloat((order?.items?.price * ((order?.quantity) ? order?.quantity : (order?.quantity) ? order?.quantity : 0))).toFixed(2))}</Text>
                                </View>
                                <View style={styles.tableDataCol}>
                                    {/* Calculate shipped amount */}
                                    <Text style={styles.colText}>{numberWithCommas(parseFloat(((order?.items?.price) ? order?.items?.price : 0) * ((invoice?.shipped_quantity) ? invoice?.shipped_quantity : 0)).toFixed(2))}</Text>
                                </View>
                                <View style={styles.tableDataCol}>
                                    {/* Calculate pending amount */}
                                    <Text style={styles.colText}>{numberWithCommas(parseFloat((order?.items?.price * (((invoice?.quantity) ? invoice?.quantity : (order?.quantity) ? order?.quantity : 0)  - ((invoice?.shipped_quantity) ? (invoice?.shipped_quantity) : 0)))).toFixed(2))}</Text>
                                </View>
                            </View>
                            ))
                        ))}
                        {/* Additional row for calculation data */}
                        <View style={styles.tableRow}>
                            <View style={styles.tableFoot}>
                                <Text>Total</Text>
                            </View>
                            <View style={styles.tableFoot}><Text></Text></View>
                            <View style={styles.tableFoot}><Text></Text></View>
                            <View style={styles.tableFoot}><Text></Text></View>
                            <View style={styles.tableFoot}>
                                <Text>{(orderQtyTotal) ? orderQtyTotal : 0}</Text>
                            </View>
                            <View style={styles.tableFoot}>
                                <Text>{(shippedQtyTotal) ? shippedQtyTotal : 0}</Text>
                            </View>
                            <View style={styles.tableFoot}>
                                <Text>{(pendingQtyTotal) ? pendingQtyTotal : 0}</Text>
                            </View>
                            <View style={styles.tableFoot}>
                                <Text>{(orderAmountTotal) ? '$'+numberWithCommas(parseFloat(orderAmountTotal).toFixed(2)) : '0'}</Text>
                            </View>
                            <View style={styles.tableFoot}>
                                <Text>{(shippedAmountTotal) ? '$'+numberWithCommas(parseFloat(shippedAmountTotal).toFixed(2)) : '0'}</Text>
                            </View>
                            <View style={styles.tableFoot}>
                                <Text>{(pendingAmountTotal) ? '$'+numberWithCommas(parseFloat(pendingAmountTotal).toFixed(2)) : '0'}</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={styles.footer} fixed>
                    <Text style={styles.reportDescription}>
                        File Name : <Text style={styles.text1}>Order Status Report</Text>
                    </Text>
                    <Text style={styles.reportDescription}>
                        User :  <Text style={styles.text1}>Ambuj</Text>
                    </Text>
                    <Text style={styles.reportDescription} > 
                        Print Date : <Text style={styles.text1}>{moment().format('DD-MMM-YYYY')}</Text>
                    </Text>
                    <Text style={styles.text1} render={({ pageNumber, totalPages }) => (
                        `Page ${pageNumber} of ${totalPages}`
                    )} fixed />
                </View>
            </Page>
        </Document>
    );
};

export default OrderStatusPDF;