import { dashboardConstants, userConstants, userListConstants, createUserConstants, viewUserConstants, updateUserConstants, clearUserMessages, 
    deleteUserConstants, changePasswordConstants, financialYearConstants, setFinancialYearConstants, salesOrderPrefixConstants, setSalesOrderPrefixConstants,
    recycleBinRecordsConstants, restoreRecordConstants, deleteRecordPermanentConstants} from "../constants/user.constant";

/************************** Reducer For Users *******************************/
export const dashboardReducer = (state = { dashboardData: [] }, action) => {
    switch (action.type) {
        case dashboardConstants.GET_DASHBOARDLIST_SUCCESS:
            return { dashboardData: action.payload };
        case dashboardConstants.GET_DASHBOARDLIST_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
};

export const userReducer = (state = { userData: [] }, action) => {
    switch (action.type) {
        case userConstants.GET_USER_SUCCESS:
            return { userData: action.payload };
        case userConstants.GET_USER_FAILURE:
            return { error: action.payload };
        // case clearUserMessages.CLEAR_USER_MESSAGE:
        //     return { userData: [], error: "" }
        default:
            return state;
    }
};

export const userListReducer = (state = { getUserList: [] }, action) => {
    switch (action.type) {
        case userListConstants.GET_USERLIST_SUCCESS:
            return { getUserList: action.payload };
        case userListConstants.GET_USERLIST_FAILURE:
            return { error: action.payload };
        case clearUserMessages.CLEAR_USER_MESSAGE:
            return { getUserList: [], error: "" }
        default:
            return state;
    }
};

export const createUserReducer = (state = { createUser: [] }, action) => {
    switch (action.type) {
        case createUserConstants.GET_CREATEUSER_SUCCESS:
            return { createUser: action.payload };
        case createUserConstants.GET_CREATEUSER_FAILURE:
            return { error: action.payload };
        case clearUserMessages.CLEAR_USER_MESSAGE:
            return { createUser: [], error: "" }
        default:
            return state;
    }
};

export const viewUserReducer = (state = { viewUser: [] }, action) => {
    switch (action.type) {
        case viewUserConstants.GET_VIEWUSER_SUCCESS:
            return { viewUser: action.payload };
        case viewUserConstants.GET_VIEWUSER_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
};

export const updateUserReducer = (state = { updateUser: [] }, action) => {
    switch (action.type) {
        case updateUserConstants.GET_UPDATEUSER_SUCCESS:
            return { updateUser: action.payload };
        case updateUserConstants.GET_UPDATEUSER_FAILURE:
            return { error: action.payload };
        case clearUserMessages.CLEAR_USER_MESSAGE:
            return { updateUser: [], error: "" }
        default:
            return state;
    }
};

export const deleteUserReducer = (state = { deleteUser: [] }, action) => {
    switch (action.type) {
        case deleteUserConstants.GET_DELETEUSER_SUCCESS:
            return { deleteUser: action.payload };
        case deleteUserConstants.GET_DELETEUSER_FAILURE:
            return { error: action.payload };
        case clearUserMessages.CLEAR_USER_MESSAGE:
            return { deleteUser: [], error: "" }
        default:
            return state;
    }
};

export const changePasswordReducer = (state = { changePassword: [] }, action) => {
    switch (action.type) {
        case changePasswordConstants.GET_CHANGEPASSWORD_SUCCESS:
            return { changePassword: action.payload };
        case changePasswordConstants.GET_CHANGEPASSWORD_FAILURE:
            return { error: action.payload };
        case clearUserMessages.CLEAR_USER_MESSAGE:
            return { changePassword: [], error: "" }
        default:
            return state;
    }
};

export const financialYearReducer = (state = { financialYear: [] }, action) => {
    switch (action.type) {
        case financialYearConstants.GET_FINANCIALYEAR_SUCCESS:
            return { financialYear: action.payload };
        case financialYearConstants.GET_FINANCIALYEAR_FAILURE:
            return { error: action.payload };
        case clearUserMessages.CLEAR_USER_MESSAGE:
            return { financialYear: [], error: "" }
        default:
            return state;
    }
};

export const setFinancialYearReducer = (state = { setFinancialYear: [] }, action) => {
    switch (action.type) {
        case setFinancialYearConstants.GET_SETFINANCIALYEAR_SUCCESS:
            return { setFinancialYear: action.payload };
        case setFinancialYearConstants.GET_SETFINANCIALYEAR_FAILURE:
            return { error: action.payload };
        case clearUserMessages.CLEAR_USER_MESSAGE:
            return { setFinancialYear: [], error: "" }
        default:
            return state;
    }
};

export const salesOrderPrefixReducer = (state = { salesOrderPrefix: [] }, action) => {
    switch (action.type) {
        case salesOrderPrefixConstants.GET_SALESORDERPREFIX_SUCCESS:
            return { salesOrderPrefix: action.payload };
        case salesOrderPrefixConstants.GET_SALESORDERPREFIX_FAILURE:
            return { error: action.payload };
        case clearUserMessages.CLEAR_USER_MESSAGE:
            return { salesOrderPrefix: [], error: "" }
        default:
            return state;
    }
};

export const setSalesOrderPrefixReducer = (state = { setSalesOrderPrefix: [] }, action) => {
    switch (action.type) {
        case setSalesOrderPrefixConstants.GET_SETSALESORDERPREFIX_SUCCESS:
            return { setSalesOrderPrefix: action.payload };
        case setSalesOrderPrefixConstants.GET_SETSALESORDERPREFIX_FAILURE:
            return { error: action.payload };
        case clearUserMessages.CLEAR_USER_MESSAGE:
            return { setSalesOrderPrefix: [], error: "" }
        default:
            return state;
    }
};

export const recycleBinRecordsReducer = (state = { recycleBinRecords: [] }, action) => {
    switch (action.type) {
        case recycleBinRecordsConstants.GET_RECYCLEBINRECORDS_SUCCESS:
            return { recycleBinRecords: action.payload };
        case recycleBinRecordsConstants.GET_RECYCLEBINRECORDS_FAILURE:
            return { error: action.payload };
        case clearUserMessages.CLEAR_USER_MESSAGE:
            return { recycleBinRecords: [], error: "" }
        default:
            return state;
    }
};

export const restoreRecordsReducer = (state = { restoreRecord: [] }, action) => {
    switch (action.type) {
        case restoreRecordConstants.GET_RESTORERECORD_SUCCESS:
            return { restoreRecord: action.payload };
        case restoreRecordConstants.GET_RESTORERECORD_FAILURE:
            return { error: action.payload };
        case clearUserMessages.CLEAR_USER_MESSAGE:
            return { restoreRecord: [], error: "" }
        default:
            return state;
    }
};

export const deleteRecordPermantReducer = (state = { deleteRecord: [] }, action) => {
    switch (action.type) {
        case deleteRecordPermanentConstants.GET_DELETERECORDPERMANENT_SUCCESS:
            return { deleteRecord: action.payload };
        case deleteRecordPermanentConstants.GET_DELETERECORDPERMANENT_FAILURE:
            return { error: action.payload };
        case clearUserMessages.CLEAR_USER_MESSAGE:
            return { deleteRecord: [], error: "" }
        default:
            return state;
    }
};