import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {Link} from "react-router-dom";
import { toast } from 'react-toastify';
import {useDispatch, useSelector} from "react-redux";
import {createBuyer, clearBuyerMsg} from "../../actions/buyerAction";
import {countriesAction} from "../../actions/commonActions";


const BuyerCreate = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [error, setError] = useState(false);
    const [countryList, setCountryList] = useState([]);

    const [buyerDetails, setBuyerDetails] = useState({
        id: "", buyer_name: "", email: "", address: "", city: "", country: "", phone_no: ""
    });

    // useEffect for call Api's
    useEffect(() => {
        dispatch(countriesAction());
    }, [dispatch]);

    // get the countries list from reducer and set here
    const countriesList = useSelector((state) => state.countries.countries)
    useEffect(() => {
        if (countriesList && countriesList?.data && countriesList?.data?.status === true) {
            setCountryList(countriesList?.data?.data);
        }

    }, [countriesList]);

    // Update the state when we change the value
    const handleBuyerFormDetails = (e) => {
        // Validate phone_no input against regex pattern
        if (e.target.name === 'phone_no' && !/^[0-9\-()+ ]{0,15}$/.test(e.target.value)) {
            return;
        }
        setBuyerDetails({
            ...buyerDetails,
            [e.target.name]: e.target.value
        })
    }

    // function to create buyer
    const buyerSubmit = async (e) => {
        e.preventDefault();
        if (!buyerDetails.buyer_name) {
            setError(true);
            return false;
        } else {
            dispatch(createBuyer({
                name: buyerDetails?.buyer_name,
                email: buyerDetails?.email,
                address: buyerDetails?.address,
                city: buyerDetails?.city,
                country: buyerDetails?.country,
                phone_no: buyerDetails?.phone_no
            }));
        }
    };

    // select data from reducer and set toaster here
    const createBuyerDtl = useSelector((state) => state.createBuyer.createBuyer)
    useEffect(() => {
        if (createBuyerDtl && createBuyerDtl?.data && createBuyerDtl?.data?.status === true) {
            toast.success(createBuyerDtl?.data?.message,{theme: "dark",});
            dispatch(clearBuyerMsg());
            if((props?.from) && props?.from === 'salesOrder'){
                props.onReturn(createBuyerDtl?.data);
            }else{
                navigate("/buyers")
            }
        }
        if (createBuyerDtl && createBuyerDtl?.data && createBuyerDtl?.data?.status === false) {
            toast.error(createBuyerDtl?.data?.message,{theme: "dark",});
        }
    }, [createBuyerDtl, navigate, dispatch,props]);

    return (
        <>
            <div className="dash-body">
                <div className="head-row">
                    <h3>Add Buyer</h3>
                </div>
                <form onSubmit={buyerSubmit}>
                    <div className="box-body box-shd">
                        <div className="row">
                            <div className="col-sm-6 col-lg-6 dash-form-col">
                                <label>Buyer Name<span className="text-danger">*</span></label>
                                <input className="form-control" type="text" id="buyer_name" name="buyer_name"
                                    value={buyerDetails.buyer_name}
                                    onChange={handleBuyerFormDetails}
                                    placeholder="Enter Buyer Name"
                                    maxLength="255"
                                    />
                                {error && !buyerDetails?.buyer_name && (
                                    <span style={{ color: "red" }} className="invalidInput" >
                                        Required*
                                    </span>
                                )}
                            </div>
                            <div className="col-sm-6 col-lg-6 dash-form-col">
                                <label>Address</label>
                                <input className="form-control" type="text" id="address" name="address"
                                    value={buyerDetails.address || ''}
                                    onChange={handleBuyerFormDetails}
                                    placeholder="Enter Address"
                                    maxLength="255"
                                />
                            </div>
                            <div className="col-sm-6 col-lg-3 dash-form-col">
                                <label>Phone No.</label>
                                <input className="form-control" type="text"
                                    value={buyerDetails.phone_no || ''}
                                    onChange={handleBuyerFormDetails}
                                    name="phone_no"
                                    placeholder="Enter No."
                                />
                            </div>
                            <div className="col-sm-6 col-lg-3 dash-form-col">
                                <label>Email</label>
                                <input className="form-control" type="email"
                                    value={buyerDetails.email || ''}
                                    onChange={handleBuyerFormDetails}
                                    name="email"
                                    placeholder="Enter Email"
                                    maxLength="255"
                                />
                            </div>
                            <div className="col-sm-6 col-lg-3 dash-form-col">
                                <label>Country</label>
                                <select className="form-control" name="country" onChange={handleBuyerFormDetails} value={buyerDetails.country || ''}>
                                    <option value="">--Select country--</option>
                                    {countryList.map((data,i) => (
                                        <option key={i} value={data.name}>{data.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-sm-6 col-lg-3 dash-form-col">
                                <label>City</label>
                                <input className="form-control"
                                    placeholder="Enter City"
                                    name="city"
                                    value={buyerDetails.city || ''}
                                    onChange={handleBuyerFormDetails}
                                    maxLength="255"
                                />
                            </div>
                            <div className="dash-btn-group mt-2">
                                <button type="submit" className="cus-btn">Save</button>
                                {((props?.from) && props?.from === 'salesOrder') ? "" :
                                <Link to="/buyers"><button className="cus-btn">Cancel</button></Link>}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );

};

export default BuyerCreate;
