/******************* Constants For User ******************/
export const dashboardConstants = {
    GET_DASHBOARDLIST_SUCCESS: 'GET_DASHBOARDLIST_SUCCESS',
    GET_DASHBOARDLIST_FAILURE: 'GET_DASHBOARDLIST_FAILURE'
}

export const userConstants = {
    GET_USER_SUCCESS: 'GET_USER_SUCCESS',
    GET_USER_FAILURE: 'GET_USER_FAILURE'
}

export const userListConstants = {
    GET_USERLIST_SUCCESS: 'GET_USERLIST_SUCCESS',
    GET_USERLIST_FAILURE: 'GET_USERLIST_FAILURE'
}

export const createUserConstants = {
    GET_CREATEUSER_SUCCESS: 'GET_CREATEUSER_SUCCESS',
    GET_CREATEUSER_FAILURE: 'GET_CREATEUSER_FAILURE'
}

export const viewUserConstants = {
    GET_VIEWUSER_SUCCESS: 'GET_VIEWUSER_SUCCESS',
    GET_VIEWUSER_FAILURE: 'GET_VIEWUSER_FAILURE'
}

export const updateUserConstants = {
    GET_UPDATEUSER_SUCCESS: 'GET_UPDATEUSER_SUCCESS',
    GET_UPDATEUSER_FAILURE: 'GET_UPDATEUSER_FAILURE',
}

export const deleteUserConstants = {
    GET_DELETEUSER_SUCCESS: 'GET_DELETEUSER_SUCCESS',
    GET_DELETEUSER_FAILURE: 'GET_DELETEUSER_FAILURE'
}

export const changePasswordConstants = {
    GET_CHANGEPASSWORD_SUCCESS: 'GET_CHANGEPASSWORD_SUCCESS',
    GET_CHANGEPASSWORD_FAILURE: 'GET_CHANGEPASSWORD_FAILURE'
}

export const clearUserMessages = {
    CLEAR_USER_MESSAGE: 'CLEAR_USER_MESSAGE'
}

export const financialYearConstants = {
    GET_FINANCIALYEAR_SUCCESS: 'GET_FINANCIALYEAR_SUCCESS',
    GET_FINANCIALYEAR_FAILURE: 'GET_FINANCIALYEAR_FAILURE'
}

export const setFinancialYearConstants = {
    GET_SETFINANCIALYEAR_SUCCESS: 'GET_SETFINANCIALYEAR_SUCCESS',
    GET_SETFINANCIALYEAR_FAILURE: 'GET_SETFINANCIALYEAR_FAILURE'
}

export const salesOrderPrefixConstants = {
    GET_SALESORDERPREFIX_SUCCESS: 'GET_SALESORDERPREFIX_SUCCESS',
    GET_SALESORDERPREFIX_FAILURE: 'GET_SALESORDERPREFIX_FAILURE'
}

export const setSalesOrderPrefixConstants = {
    GET_SETSALESORDERPREFIX_SUCCESS: 'GET_SETSALESORDERPREFIX_SUCCESS',
    GET_SETSALESORDERPREFIX_FAILURE: 'GET_SETSALESORDERPREFIX_FAILURE'
}

export const recycleBinRecordsConstants = {
    GET_RECYCLEBINRECORDS_SUCCESS: 'GET_RECYCLEBINRECORDS_SUCCESS',
    GET_RECYCLEBINRECORDS_FAILURE: 'GET_RECYCLEBINRECORDS_FAILURE'
}

export const restoreRecordConstants = {
    GET_RESTORERECORD_SUCCESS: 'GET_RESTORERECORD_SUCCESS',
    GET_RESTORERECORD_FAILURE: 'GET_RESTORERECORD_FAILURE'
}

export const deleteRecordPermanentConstants = {
    GET_DELETERECORDPERMANENT_SUCCESS: 'GET_DELETERECORDPERMANENT_SUCCESS',
    GET_DELETERECORDPERMANENT_FAILURE: 'GET_DELETERECORDPERMANENT_FAILURE'
}