import { orderStatusReportConstants, orderStatusViewConstants, totalPurchaseReportConstants, commissionReportConstants, totalSalesReportConstants, clearReportDataConstants, downloadReportXLSConstants } from "../constants/report.constants";

/************************** Reducer For Reports *******************************/
export const orderStatusReportReducer = (state = { orderStatusReport: [] }, action) => {
    switch (action.type) {
        case orderStatusReportConstants.GET_ORDERSTATUSREPORT_SUCCESS:
            return { orderStatusReport: action.payload };
        case orderStatusReportConstants.GET_ORDERSTATUSREPORT_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
};

export const orderStatusViewReducer = (state = { orderStatusView: [] }, action) => {
    switch (action.type) {
        case orderStatusViewConstants.GET_ORDERSTATUSVIEW_SUCCESS:
            return { orderStatusView: action.payload };
        case orderStatusViewConstants.GET_ORDERSTATUSVIEW_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
};

export const totalPurchaseReportReducer = (state = { totalPurchaseReport: [] }, action) => {
    switch (action.type) {
        case totalPurchaseReportConstants.GET_TOTALPURCHASEREPORT_SUCCESS:
            return { totalPurchaseReport: action.payload };
        case totalPurchaseReportConstants.GET_TOTALPURCHASEREPORT_FAILURE:
            return { error: action.payload };
        case clearReportDataConstants.CLEAR_REPORT_DATA:
            return { totalPurchaseReport: [], error: "" };
        default:
            return state;
    }
};

export const commissionReportReducer = (state = { commissionReport: [] }, action) => {
    switch (action.type) {
        case commissionReportConstants.GET_COMMISSIONREPORT_SUCCESS:
            return { commissionReport: action.payload };
        case commissionReportConstants.GET_COMMISSIONREPORT_FAILURE:
            return { error: action.payload };
        case clearReportDataConstants.CLEAR_REPORT_DATA:
            return { totalPurchaseReport: [], error: "" };
        default:
            return state;
    }
};

export const totalSalesReportReducer = (state = { totalSalesReport: [] }, action) => {
    switch (action.type) {
        case totalSalesReportConstants.GET_TOTALSALESREPORT_SUCCESS:
            return { totalSalesReport: action.payload };
        case totalSalesReportConstants.GET_TOTALSALESREPORT_FAILURE:
            return { error: action.payload };
        case clearReportDataConstants.CLEAR_REPORT_DATA:
            return { totalPurchaseReport: [], error: "" };
        default:
            return state;
    }
};

export const downloadReportXLSReducer = (state = { downloadReportXLS : [] }, action) => {
    switch (action.type) {
        case downloadReportXLSConstants.GET_DOWNLOADREPORTXLS_SUCCESS:
            return { downloadReportXLS: action.payload };
        case downloadReportXLSConstants.GET_DOWNLOADREPORTXLS_FAILURE:
            return { error: action.payload };
        case clearReportDataConstants.CLEAR_REPORT_DATA:
            return { downloadReportXLS: [], error: "" };
        default:
            return state;
    }
};