import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import Swal from 'sweetalert2'
import { useDispatch, useSelector } from "react-redux";
import { getBuyers, deleteBuyer, clearBuyerMsg } from "../../actions/buyerAction";
import $ from 'jquery';

const BuyerList = (props) => {
    const dispatch = useDispatch();

    const[buyersList,setBuyersList] = useState([]);
    const[userData,setUserData] = useState("");

    // useEffect for call Api's
    useEffect(() => {
        dispatch(getBuyers());
        setUserData(JSON.parse(localStorage.getItem("userData")));
    }, [dispatch]);

    //get all the buyers list from reducer and set here
    const getBuyerList = useSelector((state) => state.getBuyerList.getBuyerList)
    useEffect(() => {
        if (getBuyerList && getBuyerList?.data && getBuyerList?.data?.status === true) {
            setBuyersList(getBuyerList?.data?.data);
        }
        if (getBuyerList && getBuyerList?.data && getBuyerList?.data?.status === false) {
            toast.error(getBuyerList?.data?.message,{theme: "dark",});
        }
    }, [getBuyerList]);

    // Delete selected buyer and set updated list
    const handleDeleteBuyer = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to delete this Buyer?",
            icon: "warning",
            confirmButtonText: 'OK',
            showCancelButton: true,
        }).then(result => {
            if (result.isConfirmed) {
                dispatch(deleteBuyer(id));
            }
        });
    };

    //get response of deleted buyer and update the list
    const deletedBuyerDtl = useSelector((state) => state.deleteBuyer.deleteBuyer)
    useEffect(() => {
        if(deletedBuyerDtl && deletedBuyerDtl?.data && deletedBuyerDtl?.data?.status === true ){
            toast.success(deletedBuyerDtl?.data?.message,{theme: "dark",});
            if ($.fn.DataTable.isDataTable('#myTable')) {
                $('#myTable').DataTable().destroy(); // Destroy the existing DataTable instance
            }
            dispatch(getBuyers());
            dispatch(clearBuyerMsg());
        } 
        else if(deletedBuyerDtl && deletedBuyerDtl?.data && deletedBuyerDtl?.data?.status === false ){
            toast.error(deletedBuyerDtl?.data?.message,{theme: "dark",});
        }
    }, [deletedBuyerDtl,dispatch]);

    // impelement datatable from jquery
    useEffect(() => {
        buyersList && buyersList.length > 0 && $(document).ready(function () {
            $('#myTable').DataTable({
                bDestroy: true, 
                pageLength: 10
            });
        });
    }, [buyersList])

    return (
        <>
            <div className="dash-body">
                <div className="head-row">
                    <h3>Buyers</h3>
                    {(userData?.role === 1)?<>
                        <Link to={'/buyer/create'} className="cus-btn"><i className="fa-solid fa-circle-plus"></i> Create New Buyer </Link>
                    </>:''}
                </div>
                <div className="table-responsive dash-table data_tableUs">
                    <table  id="myTable" width="100%" border={0} cellSpacing={0} cellPadding={0}>
                        <thead>
                            <tr>
                                {(userData?.role === 1)?<>
                                <th>EDIT</th>
                                <th>DELETE</th></>:''}
                                <th>BUYER NAME</th>
                                <th>EMAIL</th>
                                <th>PHONE NO.</th>
                                <th>ADDRESS</th>
                                <th>CITY</th>
                                <th>COUNTRY</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(buyersList.length > 0) ? (
                                <>
                                    {buyersList.map((buyer, index) => (
                                        <tr key={buyer.id}>
                                            {(userData?.role === 1)?
                                            <>
                                                <td>
                                                    <Link to={`/buyer/update/${buyer.id}`}><i className="fa-solid fa-pencil"></i></Link>
                                                </td>
                                                <td>
                                                    <Link onClick={() => {handleDeleteBuyer(buyer.id)}}><i className="fa-solid fa-trash-can-xmark"></i></Link>
                                                </td>
                                            </>:''}
                                            <td>{buyer.name}</td>
                                            <td>{(buyer.email )? buyer.email  : '-'}</td>
                                            <td>{(buyer.phone_no) ? buyer.phone_no : '-'}</td>
                                            <td>{(buyer.address) ? buyer.address : '-'}</td>
                                            <td>{(buyer.city) ? buyer.city : '-'}</td>
                                            <td>{(buyer.country) ? buyer.country : '-'}</td>
                                        </tr>
                                    ))}
                                </>
                            ): (
                                <tr>
                                    <td colSpan={9} align={'center'}> No recent buyers available </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default BuyerList;
