import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import {useParams} from "react-router-dom";
import { viewUser, updateUser, clearUserMsg } from "../../actions/userAction";
import { countriesAction } from "../../actions/commonActions";


const UserUpdate = (props) => {

    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState(false);

    const [countryList, setCountryList] = useState([]);

    const [userDetails, setUserDetails] = useState({
        id:"", user_name: "", email: "", address: "", commission: "", city: "", country: "", phone_no: ""
    });

    // useEffect for call Api's
    useEffect(() => {
        dispatch(countriesAction());
        dispatch(viewUser(id));
    }, [dispatch,id]);

    // get the countries list from reducer and set here
    const countriesList = useSelector((state) => state.countries.countries)
    useEffect(() => {
        if (countriesList && countriesList?.data && countriesList?.data?.status === true) {
            setCountryList(countriesList?.data?.data);
        }
    }, [countriesList]);

    // get user data via id and set it in state
    const userDtl = useSelector((state) => state.viewUser.viewUser);
    useEffect(() => {
        if(userDtl && userDtl.data && userDtl.data.status === true ){
            setUserDetails({
                id: userDtl.data.data.id, 
                user_name: userDtl.data.data.name, 
                email: userDtl.data.data.email, 
                address: userDtl.data.data.address, 
                commission: userDtl.data.data.commission, 
                city: userDtl.data.data.city, 
                country: userDtl.data.data.country, 
                phone_no: userDtl.data.data.phone_no
            });
        }
    }, [userDtl]);

    // updating stored value in state
    const handleUserFormDetails = (e) => {
        setUserDetails({
            ...userDetails, 
            [e.target.name]: e.target.value
        })
    }

    // function to create user
    const handleUpdateUser = async (e) => {
        e.preventDefault();
        if (!userDetails.user_name) {
            setError(true);
            return false;
        } else {
            dispatch(
                updateUser({
                    id          : userDetails?.id,
                    name        : userDetails?.user_name,
                    email       : userDetails?.email,
                    address     : userDetails?.address,
                    commission   : userDetails?.commission,
                    city        : userDetails?.city,
                    country     : userDetails?.country,
                    phone_no    : userDetails?.phone_no,
                })
            );
        }
    };

    //select data from reducer and set toaster here
    const updateUserDtl = useSelector((state) => state.updateUser.updateUser)
    useEffect(() => {
        if (updateUserDtl && updateUserDtl?.data && updateUserDtl?.data?.status === true) {
            navigate("/dashboard")
            toast.success(updateUserDtl?.data?.message,{theme: "dark",});
            dispatch(clearUserMsg());
        }
        if (updateUserDtl && updateUserDtl?.data && updateUserDtl?.data?.status === false) {
            toast.error(updateUserDtl?.data?.message,{theme: "dark",});
        }
    }, [updateUserDtl,navigate,dispatch]);

    return (
        <>
        <form onSubmit={handleUpdateUser}>
            <h3>Edit User Details</h3>
            
            <label htmlFor="name">User name<span className="text-danger star-required">*</span></label>
            <input className="form-control" type="text" id="user_name" 
                name="user_name"
                value={userDetails.user_name}
                onChange={handleUserFormDetails}
                placeholder="Enter User Name" />
            {error && !userDetails?.user_name && (
                <span style={{ color: "red" }} className="invalidInput" >
                    Required*
                </span>
            )}
            <br/>
        
            <label htmlFor="event-budget">Email</label>
            <input className="form-control" type="text"
                value={userDetails.email || ''}
                onChange={handleUserFormDetails}
                name="email"
                placeholder="Email"
            />
                <br/>
        

            <label htmlFor="location">Address </label>
            <input className="form-control" type="text" id="address" name="address"
                value={userDetails?.address || ''}
                onChange={handleUserFormDetails}
                placeholder="Enter Address" />
                <br/>
        
    
            <label htmlFor="commission">Commission %</label>
            <input className="form-control" type="number" id="commission" name="commission"
                value={userDetails?.commission || ''}
                onChange={handleUserFormDetails}
                placeholder="Enter Commission" />
                <br/>
        
    
            <label htmlFor="event-budget">Phone No</label>
            <input className="form-control" type="text"
                value={userDetails?.phone_no || ''}
                onChange={handleUserFormDetails}
                name="phone_no"
                placeholder="Enter Phone No."
            />
            <br/>
        

            <label className="common-label" htmlFor="">City</label>
            <input className="form-control"
                placeholder="city"
                name="city"
                value={userDetails?.city || ''}
                onChange={handleUserFormDetails}
            >
            </input>
            <br/>
            
            <label htmlFor="regions">Country</label>
            <select className="form-control" onChange={handleUserFormDetails} name="country" value={userDetails.country || ''} >
                <option >--Select country--</option>
                {countryList.map((data,i) => (
                    <option key={i} value={data.name}>{data.name}</option>
                ))}
            </select>
            <br/>

            <button type="submit" className="btn btn-fill btn-info">
                Update Details
            </button>
        </form>
        </>
    );
};

export default UserUpdate;
