import { loginConstants, signupConstants } from "../constants/auth.constants";


/************************** Reducer For Authentication *******************************/
export const loginReducer = (state = { authuser: [] }, action) => {
    switch (action.type) {
        case loginConstants.GET_LOGIN_SUCCESS:
            return { authuser: action.payload };
        case loginConstants.GET_LOGIN_FAILURE:
            return { error: action.payload };
        // case clearLoginData.CLEAR_LOGIN_MESSAGE:
        //     return { authuser: [], error: "" }
        default:
            return state;
    }
};
export const registerReducer = (state = { authuser: [] }, action) => {
    switch (action.type) {
        case signupConstants.GET_SIGNUP_SUCCESS:
            return { authuser: action.payload };
        case signupConstants.GET_SIGNUP_FAILURE:
            return { error: action.payload };
        // case clearSignupData.CLEAR_SIGNUP_MESSAGE:
        //     return { authuser: [], error: "" }
        default:
            return state;
    }
};