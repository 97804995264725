import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "../components/auth/login/login";
import Header from "../components/includes/header";
import Dashboard from "../components/dashboard/dashboard";
import UserList from "../components/users/userList";
import UserCreate from "../components/users/createUser";
import UserUpdate from "../components/users/updateUser";
import ChangePassword from "../components/auth/password/changePassword";
import BuyerCreate from "../components/buyers/createBuyer";
import BuyerUpdate from "../components/buyers/updateBuyer";
import BuyerList from "../components/buyers/buyersList";
import SellerList from "../components/sellers/sellersList";
import SellerCreate from "../components/sellers/createSeller";
import SellerUpdate from "../components/sellers/updateSeller";
import ItemList from "../components/items/itemsList";
import ItemCreate from "../components/items/createItem";
import ItemUpdate from "../components/items/updateItem";
import SearchEngine from "../components/utilities/searchEngine";
import FinancialYear from "../components/utilities/financialYear";
import RecycleBin from "../components/utilities/recycleBin";
import SystemSetting from "../components/utilities/systemSetting";
import OrderList from "../components/order/orderList";
import OrderCreate from "../components/order/createOrder";
import OrderUpdate from "../components/order/updateOrder";
import InvoiceCreate from "../components/invoice/createInvoice";
import InvoiceList from "../components/invoice/invoicesList";
import InvoiceUpdate from "../components/invoice/updateInvoice";
// import { registerUser } from './actions/authAction';
import { PrivateRoute, AuthRoute } from "./privateRoute";
import OrderReport from "../components/order/orderReport";
import OrderStatusReport from "../components/reports/orderStatusReport/orderStatusReport";
import OrderStatusView from "../components/reports/orderStatusReport/orderStatusView";
import TotalPurchaseReport from "../components/reports/totalPurchaseReport/totalPurchaseReport";
import CommissionReport from "../components/reports/commissionReport/commissionReport";
import TotalSalesReport from "../components/reports/totalSalesReport/totalSalesReport";

const Routers = () => {
    return (
        <Routes>
            {/* Define a Authroute to check login */}
            <Route element={<AuthRoute />}>
                <Route path="*" element={<Navigate to='/login' />}></Route>
                <Route path="/" element={<Login />}></Route>
                <Route path="/login" element={<Login />}></Route>
            </Route>
            
            {/* Define a PrivateRoute component to handle authentication */}
            <Route element={<PrivateRoute />}>
                <Route path="/">
                    <Route path="" element={<Header />} >
                        <Route path="/dashboard" element={<Dashboard />}></Route>

                        {/****************** Route for User module ****************/}
                        <Route exact path="/users" element={<UserList />} />
                        <Route exact path="/user/create" element={<UserCreate />} />
                        <Route exact path="/user/update/:id" element={<UserUpdate />} />
                        <Route exact path="/user/change-password/:id" element={<ChangePassword />} />

                        {/****************** Route for Utilities ******************/}
                        <Route exact path="/user/set-financial-year" element={<FinancialYear />} />
                        <Route exact path="/user/system-setting" element={<SystemSetting />} />
                        <Route exact path="/user/search-engine" element={<SearchEngine />} />
                        <Route exact path="/user/recycle-bin" element={<RecycleBin />} />

                        {/****************** Route for Buyer module ****************/}
                        <Route exact path="/buyers" element={<BuyerList />} />
                        <Route exact path="/buyer/create" element={<BuyerCreate />} />
                        <Route exact path="/buyer/update/:id" element={<BuyerUpdate />} />

                        {/****************** Route for Seller module ****************/}
                        <Route exact path="/sellers" element={<SellerList />} />
                        <Route exact path="/seller/create" element={<SellerCreate />} />
                        <Route exact path="/seller/update/:id" element={<SellerUpdate />} />

                        {/****************** Route for Item module ****************/}
                        <Route exact path="/items" element={<ItemList />} />
                        <Route exact path="/item/create" element={<ItemCreate />} />
                        <Route exact path="/item/update/:id" element={<ItemUpdate />} />

                        {/****************** Route for Order module ****************/}
                        <Route exact path="/orders" element={<OrderList />} />
                        <Route exact path="/order/create" element={<OrderCreate />} />
                        <Route exact path="/order/update/:id" element={<OrderUpdate />} />
                        <Route exact path="/order/order-report/:id" element={<OrderReport />} />

                        {/****************** Route for Inovice module ****************/}
                        <Route exact path="/invoices" element={<InvoiceList />} />
                        <Route exact path="/invoice/create" element={<InvoiceCreate />} />
                        <Route exact path="/invoice/update/:id" element={<InvoiceUpdate />} />

                        {/****************** Route for Report module ****************/}
                        <Route exact path="/report/order-status-report" element={<OrderStatusReport />} />
                        <Route exact path="/report/order-status-view/:id" element={<OrderStatusView />} />
                        
                        <Route exact path="/report/total-purchase-report" element={<TotalPurchaseReport />} />
                        <Route exact path="/report/commission-report" element={<CommissionReport />} />
                        <Route exact path="/report/total-sales-report" element={<TotalSalesReport />} />

                    </Route>
                </Route>
            </Route>
        </Routes>      
  );
};
export default Routers;