import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import moment from "moment";
import $ from 'jquery';
// import Swal from 'sweetalert2'
import { getInvoices, 
    // deleteInvoice, 
    clearInvoiceMsg  } from "../../actions/invoiceAction";

const InvoiceList = () => {
    
    const dispatch = useDispatch();

    const [invoicesList,setInvoicesList] = useState([]);

    useEffect(()=>{
        dispatch(getInvoices());
    },[dispatch])

    const invoiceDtl = useSelector((state) => state.getInvoiceList.getInvoiceList)
    useEffect(() => {
        if (invoiceDtl && invoiceDtl?.data && invoiceDtl?.data?.status === true) {
            setInvoicesList(invoiceDtl?.data?.data);
        }
        if (invoiceDtl && invoiceDtl?.data && invoiceDtl?.data?.status === false) {
            toast.error(invoiceDtl?.data?.message,{theme: "dark",});
        }
    }, [invoiceDtl]);

    // Delete selected Invoice and set updated list
    // const handleDeleteInvoice = (id) => {
    //     Swal.fire({
    //         title: "Are you sure?",
    //         text: "You want to delete this Invoice?",
    //         icon: "warning",
    //         confirmButtonText: 'OK',
    //         showCancelButton: true,
    //     }).then(result => {
    //         if (result.isConfirmed) {
    //             dispatch(deleteInvoice(id));
    //         }
    //     });
    // };

    //get response of deleted Invoice and update the list
    const deletedInvoiceDtl = useSelector((state) => state.deleteInvoice.deleteInvoice)
    useEffect(() => {
        if(deletedInvoiceDtl && deletedInvoiceDtl?.data && deletedInvoiceDtl?.data?.status === true ){
            toast.success(deletedInvoiceDtl?.data?.message,{theme: "dark",});
            dispatch(getInvoices());
            dispatch(clearInvoiceMsg());
        } 
        else if(deletedInvoiceDtl && deletedInvoiceDtl?.data && deletedInvoiceDtl?.data?.status === false ){
            toast.error(deletedInvoiceDtl?.data?.message,{theme: "dark",});
        }
    }, [deletedInvoiceDtl,dispatch]);

    // impelement datatable from jquery
    useEffect(() => {
        invoicesList && invoicesList.length > 0 && $(document).ready(function () {
            $('#myTable').DataTable({
                bDestroy: true, 
                pageLength: 10
            });
        });
    }, [invoicesList])

    return (
        <>
            <div className="dash-body">
                <div className="head-row">
                    <h3>Generate Invoice Form</h3>
                    <Link to={"/invoice/create"} className="cus-btn"><i className="fa-solid fa-circle-plus"></i>Generate Invoice</Link>
                </div>
                <div className="table-responsive dash-table data_tableUs">
                    <table id="myTable" width="100%" binvoice="0" cellSpacing="0" cellPadding="0">
                        <thead>
                            <tr>
                                <th>Edit</th>
                                {/* <th>Report</th> */}
                                {/* <th>Delete</th> */}
                                <th>Invoice No</th>
                                <th>Date</th>
                                <th>Buyer</th>
                                <th>Seller</th>
                                <th>Total Quantity</th>
                                <th>Invoice Total</th>
                            </tr>
                        </thead>
                        <tbody>
                        {(invoicesList.length > 0) ? (
                            <>
                                {invoicesList.map((invoice, index) => (
                                    <tr key={index}>
                                        <td><Link to={`/invoice/update/${invoice.id}`}><i className="fa-solid fa-pencil"></i></Link></td>
                                        {/* <td><Link to={`/invoice/view-invoice/${invoice.id}`}><i className="fa-solid fa-file-lines"></i></Link></td> */}
                                        {/* <td><Link onClick={() => {handleDeleteInvoice(invoice.id)}}><i className="fa-solid fa-trash-can-xmark"></i></Link></td> */}
                                        <td>{(invoice?.invoice_no) ? invoice?.invoice_no : '-'}</td>
                                        <td>{(invoice?.invoice_date) ? moment(invoice?.invoice_date).format('DD-MMM-YYYY') : '-'}</td>
                                        <td>{(invoice?.buyers?.name) ? invoice?.buyers?.name : '-'}</td>
                                        <td>{(invoice?.sellers?.name) ? invoice?.sellers?.name : '-'}</td>
                                        <td>{(invoice?.total_quantity) ? invoice?.total_quantity : '-'}</td>
                                        <td>{(invoice?.invoice_total) ? '$'+invoice?.invoice_total : '-'}</td>
                                    </tr>
                                ))}
                                </>
                            ): (
                                <tr>
                                    <td colSpan={9} align={'center'}> No recent invoices available </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );

};
export default InvoiceList;
