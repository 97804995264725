import React, {useEffect, useState, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
// import {toast} from 'react-toastify';
import moment from "moment";
import Select from 'react-select';
import { getBuyers } from "../../../actions/buyerAction";
import { getSellers } from "../../../actions/sellerAction";
import { totalSalesReport, clearReportData } from "../../../actions/reportAction";
import { BlobProvider, PDFDownloadLink } from '@react-pdf/renderer';
import TotalSalesPDF from "./totalSalesPDF";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

const TotalSalesReport = () => {

    const dispatch = useDispatch();
	const reportTemplateRef = useRef(null);

    const [error, setError] = useState(false);
    const [flag, setFlag] = useState(false);
    const [buyersList, setBuyersList] = useState([]);
    const [sellersList, setSellersList] = useState([]);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [selectedBuyer, setSelectedBuyer] = useState("All");
    const [selectedSeller, setSelectedSeller] = useState("All");
    
    const [invoiceList, setInvoiceList] = useState([]);
    
    const [invoiceAmountTotal,setInvoiceTotalAmount] = useState(0);
    const [totalQuantity,setTotalQuantity] = useState(0);

    useEffect(() => {
        // dispatch(
        //     totalSalesReport({
        //         from_date   : "2024-02-1",
        //         to_date     : "2024-03-11",
        //         buyer       : "All",
        //         seller      : "All",
        //     })
        // );
        dispatch(getBuyers());
        dispatch(getSellers());
        dispatch(clearReportData());
    }, [dispatch])

    // This function adds commas to a number for every thousand separator.
    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    //get all the buyers and sellers list from reducer and set here
    const getBuyerList = useSelector((state) => state.getBuyerList.getBuyerList)
    const getSellerList = useSelector((state) => state.getSellerList.getSellerList)
    useEffect(() => {
        if (getBuyerList && getBuyerList?.data && getBuyerList?.data?.status === true) {
            setBuyersList(getBuyerList?.data?.data);
        }
        if (getSellerList && getSellerList?.data && getSellerList?.data?.status === true) {
            setSellersList(getSellerList?.data?.data);
        }
    }, [getBuyerList,getSellerList]);

    // set item properties in state
    const handleForDetails = (e, type) => {
        if(type === 'buyer'){
            setSelectedBuyer(e ? e.value : "");
        }else if(type === 'seller'){
            setSelectedSeller(e ? e.value : "");
        } 
    }

    // function for submit the props for getting purchase report accrodingly
    const handleReportFunction = async (e) => {
        e.preventDefault();
        if (!fromDate || !toDate || !selectedBuyer || !selectedSeller) {
            setError(true);
            return false;
        } else {
            dispatch(
                totalSalesReport({
                    from_date   : fromDate,
                    to_date     : toDate,
                    buyer       : selectedBuyer,
                    seller      : selectedSeller,
                })
            );
        }
    }

    const salesReportDtl = useSelector((state) => state.totalSalesReport.totalSalesReport)
    useEffect(() => {
        if (salesReportDtl && salesReportDtl?.data && salesReportDtl?.data?.status === true) {
            setInvoiceList(salesReportDtl?.data?.data);
            setFlag(true);
        }else{
            setInvoiceList([]);
            setFlag(false);
        }
    }, [salesReportDtl]);

    useEffect(() => {
        // Initialize a variable to store the sum of order values
        let quantityTotal = 0;
        invoiceList.forEach(invoice => { quantityTotal += parseInt(invoice.total_quantity) || 0; });
        setTotalQuantity(quantityTotal);
        let invoiceAmountTotal = 0;
        invoiceList.forEach(invoice => {invoiceAmountTotal += parseFloat((invoice?.invoice_total)) || 0;});
        setInvoiceTotalAmount(invoiceAmountTotal);
    }, [invoiceList]);
    
    return (
        <>
            <div className="dash-body">
                <div className="head-row">
                    <h3>Total Sales Report</h3>
                </div>
                <div className="box-body box-shd">
                    <form onSubmit={handleReportFunction}>
                        <div className="row">
                            <div className="col-sm-6 col-lg-2 dash-form-col">
                                <label>From Date</label>
                                <input type="date" className="form-control" onChange={(e) => setFromDate(e.target.value)} placeholder="Select a date"/>
                                {error && !fromDate && (
                                    <span style={{ color: "red" }} className="invalidInput" >
                                        Required*
                                    </span>
                                )}
                            </div>
                            <div className="col-sm-6 col-lg-2 dash-form-col">
                                <label>To Date</label>
                                <input type="date" className="form-control" onChange={(e) => setToDate(e.target.value)} placeholder="Select a date"/>
                                {error && !toDate && (
                                    <span style={{ color: "red" }} className="invalidInput" >
                                        Required*
                                    </span>
                                )}
                            </div>
                            <div className="col-sm-6 col-lg-4 dash-form-col">
                                <label>Buyer</label>
                                <Select
                                    isClearable
                                    options={[
                                        { label: 'All', value: 'All' }, // Default option for ALL
                                        ...buyersList.map((data, index) => ({
                                            label: data.name,
                                            value: data.id,
                                        })),
                                    ]}
                                    onChange={(e) => handleForDetails(e, 'buyer')}
                                    defaultValue={{ label: 'All', value: 'All' }}
                                />
                            </div>
                            <div className="col-sm-6 col-lg-4 dash-form-col">
                                <label>Seller <span className="text-danger">*</span></label>
                                <Select
                                    isClearable
                                    options={[
                                        { label: 'All', value: 'All' }, // Default option for ALL
                                        ...sellersList.map((data, index) => ({
                                            label: data.name,
                                            value: data.id,
                                        })),
                                    ]}
                                    onChange={(e) => handleForDetails(e, 'seller')}
                                    defaultValue={{ label: 'All', value: 'All' }}
                                />
                                {error && !selectedSeller && (
                                    <span style={{ color: "red" }} className="invalidInput" >
                                        Required*
                                    </span>
                                )}
                            </div>

                            <div className="dash-btn-group mt-2">
                                <button className="cus-btn" type="submit">View</button>
                            </div>
                        </div>
                    </form>
                </div>
            
            {(flag) ?
                <div style={{backgroundColor:"#fff"}} >
                    {(invoiceList?.length > 0) &&
                    <div className="align-items-md-center d-flex gap-1 justify-content-end px-2">
                        <BlobProvider document={
                            <TotalSalesPDF invoiceList={invoiceList} invoiceAmountTotal={invoiceAmountTotal} totalQuantity={totalQuantity}
                                buyersList={buyersList} sellersList={sellersList} selectedBuyer={selectedBuyer}
                                selectedSeller={selectedSeller} fromDate={fromDate} toDate={toDate}/>
                        }>
                            {({ url }) => (
                                <a href={url} target="_blank" rel="noopener noreferrer" title="Preview PDF"> <FontAwesomeIcon icon={faFilePdf} className="icon" /></a>
                            )}
                        </BlobProvider>
                        <PDFDownloadLink
                            document={
                                <TotalSalesPDF invoiceList={invoiceList} invoiceAmountTotal={invoiceAmountTotal} totalQuantity={totalQuantity}
                                    buyersList={buyersList} sellersList={sellersList} selectedBuyer={selectedBuyer}
                                    selectedSeller={selectedSeller} fromDate={fromDate} toDate={toDate}/>
                            }
                            style={{padding: '5px 5px 5px 5px', float: 'right' }}
                            fileName="total-sales-report.pdf"
                            className="MuiButtonBase-root MuiButton-root MuiButton-contained btn-download MuiButton-containedPrimary MuiButton-containedSizeLarge MuiButton-sizeLarge"
                        >
                            {({ blob, url, loading, error }) =>
                                loading ? "Loading document..." : <i className="fa-solid fa-download" title="Download PDF"></i>
                            }
                        </PDFDownloadLink>
                    </div>
                    }
                    <div ref={reportTemplateRef} style={{padding:'4em 4em'}}>
                        <table border="0" cellSpacing="0" cellPadding="0" style={{ fontFamily: 'sans-serif', color: '#000', fontSize: '16px', margin: 'auto', maxWidth: '1650px', width: '100%' }}>
                            <tbody>
                                <tr>
                                    <td>
                                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <table style={{border: "3px solid #000",width: "100%",borderWidth: "2px 5px 5px 2px",}}>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td valign="center">
                                                                                        <img src="../../img/m-logo.png" alt="" style={{ maxWidth: 120, width: '100%'}} />
                                                                                    </td>
                                                                                    <td valign="top" style={{ textAlign: "center" }}>
                                                                                        <table width="100%" border={0} cellSpacing={0} cellPadding={0}>
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <td colSpan={4} style={{ fontSize: 45,fontWeight: 700,textTransform: "uppercase", fontFamily: "ui-rounded" }}>
                                                                                                        MARUTI OVERSEAS CORP.
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td colSpan={4} style={{ fontSize: 20, fontWeight: 700, textTransform: "uppercase", fontFamily: "ui-rounded" }}>
                                                                                                        IMPORTERS &amp; INDENTORS
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td colSpan={4} height="15px" />
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td height="30px" />
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'center', fontSize: '18px' }}>
                                                                        <div style={{display:'flex', justifyContent:'space-between', marginTop:'10px'}}>
                                                                            <span style={{ fontWeight: 600 }}>
                                                                                Buyer: <span style={{fontWeight:'normal', marginRight:'0.5em'}}>{!(selectedBuyer) ? '-' : (buyersList.find(buyer => buyer.id === selectedBuyer)?.name || selectedBuyer)}</span>  
                                                                                Seller: <span style={{fontWeight:'normal'}}>{!(selectedSeller) ? '-' : (sellersList.find(seller => seller.id === selectedSeller)?.name || selectedSeller)}</span>
                                                                            </span>
                                                                            <span style={{ fontWeight: 600 }}>Total Sales Report</span>

                                                                            <span style={{ fontWeight: 600 }}>
                                                                                From Date: <span style={{fontWeight:'normal', marginRight:'0.5em'}}>{(fromDate) ? moment(fromDate).format('DD-MMM-YYYY') : '-'}</span>  
                                                                                To Date: <span style={{fontWeight:'normal'}}>{(toDate) ? moment(toDate).format('DD-MMM-YYYY') : '-'}</span>
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td height="50px"></td>
                                </tr>
                                <tr>
                                    <td>
                                        <table width="100%" border="0" cellSpacing="0" cellPadding="0" style={{ borderRadius: '6px', border: '1px solid #bbb' }}>
                                            <thead>
                                                <tr style={{ backgroundColor: '#F8F7FD' }}>
                                                    <th style={{ textAlign: 'start', backgroundColor: '#e6e2e2', padding: '20px' }}>Invoice Number</th>
                                                    <th style={{ textAlign: 'start', backgroundColor: '#e6e2e2', padding: '20px' }}>Invoice Date</th>
                                                    <th style={{ textAlign: 'start', backgroundColor: '#e6e2e2', padding: '20px' }}>Quantity (DZ)</th>
                                                    <th style={{ textAlign: 'start', backgroundColor: '#e6e2e2', padding: '20px' }}>Invoice Total Amount US($)</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{whiteSpace:"nowrap"}}>
                                                {(invoiceList?.length > 0) ? (
                                                    <>
                                                        {invoiceList?.map((invoice, index) => (
                                                            <tr key={invoice.id}>
                                                                <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderBottom:'0.5px solid #ccc',borderRight:'0.5px solid #ccc'}}>
                                                                    {(invoice?.invoice_no) ? (invoice?.invoice_no) : '-'}</td>
                                                                <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderBottom:'0.5px solid #ccc',borderRight:'0.5px solid #ccc'}}>
                                                                    {(invoice?.invoice_date) ?  moment(invoice?.invoice_date).format('DD-MMM-YYYY') : '-'}</td>
                                                                <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderBottom:'0.5px solid #ccc',borderRight:'0.5px solid #ccc'}}>
                                                                    {parseInt((invoice?.total_quantity) ? (invoice?.total_quantity) : '-')}</td>
                                                                <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderBottom:'0.5px solid #ccc',borderRight:'0.5px solid #ccc'}}>
                                                                    {numberWithCommas((invoice?.invoice_total) ? (invoice?.invoice_total) : '-')}</td>
                                                            </tr>
                                                        ))}
                                                    </>
                                                ): (
                                                    <tr>
                                                        <td colSpan={12} align={'center'} style={{fontWeight:'700',padding:'1em'}}> No Record Found. </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                            {(invoiceList?.length > 0) ? (
                                                <>
                                                    <tfoot>
                                                        <tr>
                                                            <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderTop:'2px solid #000',borderRight:'0.5px solid #ccc',fontWeight: 'bold'}}>Total</td>
                                                            <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderTop:'2px solid #000',borderRight:'0.5px solid #ccc',fontWeight: 'bold'}}>&nbsp;</td>
                                                            <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderTop:'2px solid #000',borderRight:'0.5px solid #ccc',fontWeight: 'bold'}}>{(totalQuantity) ? (totalQuantity) : '0'}</td>
                                                            <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderTop:'2px solid #000',borderRight:'0.5px solid #ccc',fontWeight: 'bold'}}>{(invoiceAmountTotal) ? '$'+numberWithCommas(parseFloat(invoiceAmountTotal).toFixed(2)) : '0'}</td>
                                                        </tr>
                                                    </tfoot>
                                                </>
                                            ):""}
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            : ""}
            </div>
        </>
    );

}

export default TotalSalesReport;
