import React from "react";
// import {useNavigate} from "react-router";
// import {useDispatch, useSelector} from "react-redux";
import { Link } from "react-router-dom";

const SearchEngine = () => {
    // const dispatch = useDispatch();
    // const navigate = useNavigate();

    return (
    <>
        <div className="dash-body">
            <div className="head-row">
                <h3>Search Engine</h3>
            </div>
            <div className="box-body box-shd">
                <div className="row">
                    <div className="col-sm-6 col-lg-6 dash-form-col">
                        <div className="search-bar">
                            <input type="text" className="form-control" placeholder="Mellissa Parker"/>
                            <button className="cus-btn">
                                <i className="fa-regular fa-magnifying-glass"></i>
                                Search</button>
                        </div>
                    </div>
                    <div className="col-sm-12 dash-form-col">
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1"/>
                            <label className="form-check-label" htmlFor="inlineCheckbox1">Match Case</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2"/>
                            <label className="form-check-label" htmlFor="inlineCheckbox2">Match Whole Word</label>
                        </div>
                    </div>
                </div>
            </div>
            <h4 className="mb-3">Search Results</h4>
            <div className="table-responsive dash-table">
                <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                    <thead>
                        <tr>
                            <th>Order Name</th>
                            <th>Date</th>
                            <th>Buyer</th>
                            <th>Seller</th>
                            <th>Total Quantity</th>
                            <th>Total Amount</th>
                            <th>Shipment Date</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>JB-12456</td>
                            <td>31-May-2020</td>
                            <td>Estivaneki INT, S.A</td>
                            <td>Indo Accord Apparels</td>
                            <td>1356.00</td>
                            <td>$440.00</td>
                            <td>12-Jul-2021</td>
                            <td>
                                <Link href="#" className="txt-pend">Pending</Link>
                            </td>
                        </tr>
                        <tr>
                            <td>JB-12456</td>
                            <td>31-May-2020</td>
                            <td>Estivaneki INT, S.A</td>
                            <td>Indo Accord Apparels</td>
                            <td>1356.00</td>
                            <td>$440.00</td>
                            <td>12-Jul-2021</td>
                            <td>
                                <Link href="#" className="txt-comp">Complete</Link>
                            </td>
                        </tr>
                        <tr>
                            <td>JB-12456</td>
                            <td>31-May-2020</td>
                            <td>Estivaneki INT, S.A</td>
                            <td>Indo Accord Apparels</td>
                            <td>1356.00</td>
                            <td>$440.00</td>
                            <td>12-Jul-2021</td>
                            <td>
                                <Link href="#" className="txt-comp">Complete</Link>
                            </td>
                        </tr>
                        <tr>
                            <td>JB-12456</td>
                            <td>31-May-2020</td>
                            <td>Estivaneki INT, S.A</td>
                            <td>Indo Accord Apparels</td>
                            <td>1356.00</td>
                            <td>$440.00</td>
                            <td>12-Jul-2021</td>
                            <td>
                                <Link href="#" className="txt-comp">Complete</Link>
                            </td>
                        </tr>
                        <tr>
                            <td>JB-12456</td>
                            <td>31-May-2020</td>
                            <td>Estivaneki INT, S.A</td>
                            <td>Indo Accord Apparels</td>
                            <td>1356.00</td>
                            <td>$440.00</td>
                            <td>12-Jul-2021</td>
                            <td>
                                <Link href="#" className="txt-pend">Pending</Link>
                            </td>
                        </tr>
                        <tr>
                            <td>JB-12456</td>
                            <td>31-May-2020</td>
                            <td>Estivaneki INT, S.A</td>
                            <td>Indo Accord Apparels</td>
                            <td>1356.00</td>
                            <td>$440.00</td>
                            <td>12-Jul-2021</td>
                            <td>
                                <Link href="#" className="txt-comp">Complete</Link>
                            </td>
                        </tr>
                        <tr>
                            <td>JB-12456</td>
                            <td>31-May-2020</td>
                            <td>Estivaneki INT, S.A</td>
                            <td>Indo Accord Apparels</td>
                            <td>1356.00</td>
                            <td>$440.00</td>
                            <td>12-Jul-2021</td>
                            <td>
                                <Link href="#" className="txt-pend">Pending</Link>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </>
    );
}
export  default SearchEngine;
