import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from 'react-toastify';
import Swal from 'sweetalert2'
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { salesOrderPrefix, setSalesOrderPrefix, clearUserMsg } from "../../actions/userAction";

const SystemSetting = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const [error, setError] = useState(false);
    const [prefix, setPrefix] = useState("");


    useEffect(() => {
        dispatch(salesOrderPrefix());
    },[dispatch])

    //get the sales order prefix from reducer and set in state
    const prefixDtl = useSelector((state) => state.salesOrderPrefix.salesOrderPrefix)
    useEffect(() => {
        if (prefixDtl && prefixDtl?.data && prefixDtl?.data?.status === true) {
            setPrefix(prefixDtl?.data?.data?.sales_order_prefix)
        }
    }, [prefixDtl,dispatch]);
    
   
    // function to create seller
    const handleSystemSetting = async (e) => {
        e.preventDefault();
        if (!prefix) {
            setError(true);
            return false;
        } else {
            Swal.fire({
                title: "Are you sure?",
                text: "You want to change that Prefix?",
                icon: "warning",
                confirmButtonText: 'OK',
                showCancelButton: true,
            }).then(result => {
                if (result.isConfirmed) {
                    dispatch(
                        setSalesOrderPrefix({
                            sales_order_prefix  : prefix,
                        })
                    );
                }
            });
        }
    };
        
    //get the api response data from reducer and set it here
    const setPrefixDtl = useSelector((state) => state.setSalesOrderPrefix.setSalesOrderPrefix)
    useEffect(() => {
        if (setPrefixDtl && setPrefixDtl?.data && setPrefixDtl?.data?.status === true) {
            toast.success(setPrefixDtl?.data?.message,{theme: "dark",});
            dispatch(clearUserMsg());
        }
        if (setPrefixDtl && setPrefixDtl?.data && setPrefixDtl?.data?.status === false) {
            toast.error(setPrefixDtl?.data?.message,{theme: "dark",});
        }
    }, [setPrefixDtl,navigate,dispatch]);

    return (
        <>
            <div className="dash-body">
                <div className="head-row">
                    <h3>System Settings</h3>
                </div>
                <form onSubmit={handleSystemSetting}>
                    <div className="box-body box-shd">
                        <h4>Sales Order</h4>
                        <div className="row">
                            <div className="col-sm-6 col-lg-4 dash-form-col">
                            <label>Sales Order Number Prefix</label>
                            <input className="form-control" type="text" id="prefix" name="prefix"
                                value={prefix || ''}
                                onChange={(e) => setPrefix(e.target.value)} 
                                placeholder="JB-" />
                            {error && !prefix && (
                                <span style={{ color: "red" }} className="invalidInput" >
                                    Required*
                                </span>
                            )}
                            </div>
                            <div className="dash-btn-group mt-2">
                                <button className="cus-btn">Save</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default SystemSetting;
