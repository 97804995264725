import React from "react";
import { Navigate, Outlet } from "react-router-dom";

export const PrivateRoute = () => {
    const auth = localStorage.getItem('token');
    const userData = JSON.parse(localStorage.getItem('userData'));
    if (auth) {
        return (auth && userData) ? <Outlet /> : <Navigate to="/dashboard " replace />;
    } else {
        return <Navigate to="/login" replace />;
    }
};


export const AuthRoute = () => {
    const auth = localStorage.getItem('token');
    const userData = JSON.parse(localStorage.getItem('userData'));
    if (!auth) {
        return !auth ? <Outlet /> : <Navigate to="/login" replace />;
    } else {
        return auth && userData ? <Navigate to="/dashboard" replace /> : <Navigate to="/login" replace />;
    }
};

