import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate} from "react-router-dom";
import { toast } from 'react-toastify';
import moment from "moment";
import { orderStatusView } from "../../../actions/reportAction";
import { BlobProvider, PDFDownloadLink } from '@react-pdf/renderer';
import OrderStatusPDF from "./orderStatusPDF";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

const OrderStatusView = () =>{
    
    const {id} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
	const reportTemplateRef = useRef(null);

    const [orderDetails,setOrderDetails] = useState([]);
    
    const [orderQtyTotal,setOrderQtyTotal] = useState(0);
    const [shippedQtyTotal,setShippedQtyTotal] = useState(0);
    const [pendingQtyTotal,setPendingQtyTotal] = useState(0);
    const [orderAmountTotal,setOrderAmountTotal] = useState(0);
    const [shippedAmountTotal,setShippedAmountTotal] = useState(0);
    const [pendingAmountTotal,setPendingAmountTotal] = useState(0);

    useEffect(()=>{
        dispatch(orderStatusView(id));
    },[dispatch,id])

    // This function adds commas to a number for every thousand separator.
    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const reportDtl = useSelector((state) => state.orderStatusView.orderStatusView)
    useEffect(() => {
        if (reportDtl && reportDtl?.data && reportDtl?.data?.status === true) {
            setOrderDetails(reportDtl?.data?.data);
        }
        if (reportDtl && reportDtl?.data && reportDtl?.data?.status === false) {
            toast.error(reportDtl?.data?.message,{theme: "dark",});
        }
    }, [reportDtl]);

    
    useEffect(() => {
        // Initialize a variable to store the sum of order values
        const totalQuantity = orderDetails?.order_item?.reduce((acc, order) => { return acc + (order?.quantity ? parseFloat(order.quantity) : 0);}, 0);
        setOrderQtyTotal(totalQuantity);
        
        // const totalQuantity = orderDetails?.order_item?.reduce((acc, order) => acc + (order.invoice_order?.reduce((acc, invoice) => acc + ((invoice?.quantity) ? invoice?.quantity : 0), 0) || 0), 0) || 0;
        // setOrderQtyTotal(totalQuantity);

        const shippedQuantity = orderDetails?.order_item?.reduce((acc, order) => acc + (order.invoice_order?.reduce((acc, invoice) => acc + ((invoice?.shipped_quantity) ? invoice?.shipped_quantity : 0), 0) || 0), 0) || 0;
        setShippedQtyTotal(shippedQuantity);
        
        // const pendingQuantity = orderDetails?.order_item?.reduce((acc, order) => acc + (order.invoice_order?.reduce((acc, invoice) => acc + ((((invoice?.quantity) ? invoice?.quantity : (order?.quantity) ? order?.quantity : 0) - ((invoice?.shipped_quantity) ? (invoice?.shipped_quantity) : 0))), 0) || 0), 0) || 0;
        const pendingQuantity = totalQuantity - shippedQuantity;
        setPendingQtyTotal(pendingQuantity);
        
        // const orderAmount = orderDetails?.order_item?.reduce((acc, order) => acc + (order.invoice_order?.reduce((acc, invoice) => acc + ((order?.items?.price * ((invoice?.quantity) ? invoice?.quantity : (order?.quantity) ? order?.quantity : 0))), 0) || 0), 0) || 0;
        // setOrderAmountTotal(orderAmount);

        const orderAmount = orderDetails?.order_item?.reduce((acc, order) => { return acc + (order?.cnf_value ? parseFloat(order.cnf_value) : 0);}, 0);
        setOrderAmountTotal(orderAmount);
        
        const shippedAmount = orderDetails?.order_item?.reduce((acc, order) => acc + (order.invoice_order?.reduce((acc, invoice) => acc + (((order?.items?.price) * (invoice?.shipped_quantity))), 0) || 0), 0) || 0;
        setShippedAmountTotal(shippedAmount);
        
        // const pendingAmount = orderDetails?.order_item?.reduce((acc, order) => acc + (order.invoice_order?.reduce((acc, invoice) => acc + (order?.items?.price * (((invoice?.quantity) ? invoice?.quantity : (order?.quantity) ? order?.quantity : 0) - ((invoice?.shipped_quantity) ? (invoice?.shipped_quantity) : 0))), 0) || 0), 0) || 0;
        const pendingAmount = orderAmount - shippedAmount;
        setPendingAmountTotal(pendingAmount);
    }, [orderDetails]);
    
    return (
        <div className="dash-body">
            <div style={{ backgroundColor: "#fff" }} >
                <div className="align-items-md-center d-flex gap-1 justify-content-between px-2">
                    <div>
                        <a href={"javascript:void(0)"} className="icon" title="go back" onClick={() => navigate(-1)}><i className="fa fa-arrow-left" aria-hidden="true"></i></a>
                    </div>
                    {(orderDetails?.order_item?.length > 0) &&
                    <div>
                    <BlobProvider document={
                        <OrderStatusPDF orderDetails={orderDetails} orderQtyTotal={orderQtyTotal} shippedQtyTotal={shippedQtyTotal} pendingQtyTotal={pendingQtyTotal}
                        orderAmountTotal={orderAmountTotal} shippedAmountTotal={shippedAmountTotal} pendingAmountTotal={pendingAmountTotal}/>
                    }>
                        {({ url, loading}) => (
                            loading ? "Loading..." : <a href={url} target="_blank" rel="noopener noreferrer" title="Preview PDF"> <FontAwesomeIcon icon={faFilePdf} className="icon px-2" /></a>
                        )}
                    </BlobProvider>
                    <PDFDownloadLink
                        document={
                            <OrderStatusPDF orderDetails={orderDetails} orderQtyTotal={orderQtyTotal} shippedQtyTotal={shippedQtyTotal} pendingQtyTotal={pendingQtyTotal}
                            orderAmountTotal={orderAmountTotal} shippedAmountTotal={shippedAmountTotal} pendingAmountTotal={pendingAmountTotal}/>
                        }
                        // style={{padding: '5px 5px 5px 5px', float: 'right' }}
                        fileName={orderDetails?.order_no+".order-status-report.pdf"}
                        className="MuiButtonBase-root MuiButton-root MuiButton-contained btn-download MuiButton-containedPrimary MuiButton-containedSizeLarge MuiButton-sizeLarge"
                    >
                        {({ blob, url, loading, error }) =>
                            loading ? "Loading document..." : <i className="fa-solid fa-download" title="Download PDF"></i>
                        }
                    </PDFDownloadLink>
                    </div>
                    }
                </div>
                <div ref={reportTemplateRef} style={{ padding: '4em 4em' }}>
                    <table border="0" cellSpacing="0" cellPadding="0" style={{ fontFamily: 'sans-serif', color: '#000', fontSize: '16px', margin: 'auto', maxWidth: '1650px', width: '100%' }}>
                        <tbody>
                            <tr>
                                <td>
                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <table style={{border: "3px solid #000",width: "100%",borderWidth: "2px 5px 5px 2px",}}>
                                                        <tbody>
                                                            <tr>
                                                                <td valign="center">
                                                                    <img src="../../img/m-logo.png" alt="" style={{ maxWidth: 120, width: '100%'}} />
                                                                </td>
                                                                <td valign="top" style={{ textAlign: "center" }}>
                                                                    <table width="100%" border={0} cellSpacing={0} cellPadding={0}>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td colSpan={4} style={{ fontSize: 45,fontWeight: 700,textTransform: "uppercase", fontFamily: "ui-rounded" }}>
                                                                                    MARUTI OVERSEAS CORP.
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan={4} style={{ fontSize: 20, fontWeight: 700, textTransform: "uppercase", fontFamily: "ui-rounded" }}>
                                                                                    IMPORTERS &amp; INDENTORS
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan={4} height="15px" />
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td height="10px" />
                                            </tr>
                                            <tr>
                                                <td style={{ textAlign: 'center', fontSize: '18px' }}>
                                                    <span style={{ fontWeight: 600 }}>Status by Order Number : {orderDetails.order_no ? orderDetails.order_no : 'N/A'}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table width="100%" border="0" cellSpacing="10px" cellPadding="10px">
                                                        <tbody>
                                                            <tr>
                                                                <td height="20px"></td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td style={{ padding: '10px 0px' }}>
                                                                                    <span style={{ color: '#000', fontWeight: 600, fontSize: '16px', marginBottom: '8px' }}>Buyer :</span>
                                                                                    <span> {(orderDetails?.buyers?.name) ? orderDetails?.buyers?.name : '-'}</span>
                                                                                </td>
                                                                                <td style={{ padding: '10px 0px' }}>
                                                                                    <span style={{ color: '#000', fontWeight: 600, fontSize: '16px', marginBottom: '8px' }}>Order Quantity [Dz] :</span>
                                                                                    <span > {(orderQtyTotal) ? '$'+numberWithCommas(orderQtyTotal) : '-'}</span>
                                                                                </td>
                                                                                <td style={{ padding: '10px 0px' }}>
                                                                                    <span style={{ color: '#000', fontWeight: 600, fontSize: '16px', marginBottom: '8px' }}>Order Amount US($) :</span>
                                                                                    <span > {(orderAmountTotal) ? '$'+numberWithCommas(parseFloat(orderAmountTotal).toFixed(2)) : '-'}</span>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ padding: '10px 0px' }}>
                                                                                    <span style={{ color: '#000', fontWeight: 600, fontSize: '16px', marginBottom: '8px' }}>Seller :</span>
                                                                                    <span> {(orderDetails?.sellers?.name) ? orderDetails?.sellers?.name : '-'}</span>
                                                                                </td>
                                                                                <td style={{ padding: '10px 0px' }}>
                                                                                    <span style={{ color: '#000', fontWeight: 600, fontSize: '16px', marginBottom: '8px' }}>Shipped Quantity [Dz] :</span>
                                                                                    <span> {shippedQtyTotal}</span>
                                                                                </td>
                                                                                <td style={{ padding: '10px 0px' }}>
                                                                                    <span style={{ color: '#000', fontWeight: 600, fontSize: '16px', marginBottom: '8px' }}>Shipped Amount US($) :</span>
                                                                                    <span> {(shippedAmountTotal) ? '$'+numberWithCommas(parseFloat(shippedAmountTotal).toFixed(2)) : '0'}</span>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ padding: '10px 0px' }}>
                                                                                    <span style={{ color: '#000', fontWeight: 600, fontSize: '16px', marginBottom: '8px' }}>Order Date :</span>
                                                                                    <span> {(orderDetails?.order_date) ?moment(orderDetails?.order_date).format('DD-MMM-YYYY'):'-'}</span>
                                                                                </td>
                                                                                <td style={{ padding: '10px 0px' }}>
                                                                                    <span style={{ color: '#000', fontWeight: 600, fontSize: '16px', marginBottom: '8px' }}>Pending Quantity :</span>
                                                                                    <span> {(pendingQtyTotal) ? (pendingQtyTotal) : '0'}</span>
                                                                                </td>
                                                                                <td style={{ padding: '10px 0px' }}>
                                                                                    <span style={{ color: '#000', fontWeight: 600, fontSize: '16px', marginBottom: '8px' }}>Pending Amount US($) :</span>
                                                                                    <span> {(pendingAmountTotal) ? '$'+numberWithCommas(parseFloat(pendingAmountTotal).toFixed(2)) : '0'}</span>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ padding: '10px 0px' }}>
                                                                                    <span style={{ color: '#000', fontWeight: 600, fontSize: '16px', marginBottom: '8px' }}>Shipment Date :</span>
                                                                                    <span> {(orderDetails?.shipment_date) ?moment(orderDetails?.shipment_date).format('DD-MMM-YYYY'):'-'}</span>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td height="20px"></td>
                            </tr>
                            <tr>
                                <td>
                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0" style={{ borderRadius: '6px', border: '1px solid #bbb' }}>
                                        <tbody>
                                            <tr style={{ backgroundColor: '#F8F7FD' }}>
                                                <th style={{ textAlign: 'center', backgroundColor: '#e6e2e2', padding: '20px' }}>Invoice Number</th>
                                                <th style={{ textAlign: 'center', backgroundColor: '#e6e2e2', padding: '20px' }}>Invoice Date</th>
                                                <th style={{ textAlign: 'center', backgroundColor: '#e6e2e2', padding: '20px' }}>Item Number</th>
                                                <th style={{ textAlign: 'center', backgroundColor: '#e6e2e2', padding: '20px' }}>Item Price</th>
                                                <th style={{ textAlign: 'center', backgroundColor: '#e6e2e2', padding: '20px' }}>Order Qty (Dz)</th>
                                                <th style={{ textAlign: 'center', backgroundColor: '#e6e2e2', padding: '20px' }}>Shipped Qty (Dz)</th>
                                                <th style={{ textAlign: 'center', backgroundColor: '#e6e2e2', padding: '20px' }}>Pending Qty (Dz)</th>
                                                <th style={{ textAlign: 'center', backgroundColor: '#e6e2e2', padding: '20px' }}>Order Amount US($)</th>
                                                <th style={{ textAlign: 'center', backgroundColor: '#e6e2e2', padding: '20px' }}>Shipped Amount US($)</th>
                                                <th style={{ textAlign: 'center', backgroundColor: '#e6e2e2', padding: '20px' }}>Pending Amount US($)</th>
                                            </tr>
                                            
                                            {(orderDetails?.order_item?.length > 0) ? (
                                                <>
                                                    {orderDetails?.order_item?.map((order,index) => (
                                                        (order.invoice_order?.length ? order.invoice_order : [null]).map((invoice,index) => (
                                                        <tr key={index} style={{ whiteSpace:"nowrap"}}>
                                                            <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderBottom:'0.5px solid #ccc',borderRight:'0.5px solid #ccc'}}>
                                                                {(invoice?.invoice?.invoice_no)?(invoice?.invoice?.invoice_no) : '-'}</td>
                                                            
                                                            <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderBottom:'0.5px solid #ccc',borderRight:'0.5px solid #ccc'}}>
                                                                {(invoice?.invoice?.invoice_date) ? moment(invoice?.invoice?.invoice_date).format('DD-MMM-YYYY') : '-'}</td>
                                                            
                                                            <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderBottom:'0.5px solid #ccc',borderRight:'0.5px solid #ccc'}}>
                                                                {(order?.items?.item_no)?(order?.items?.item_no) : '-'}</td>
                                                            
                                                            <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderBottom:'0.5px solid #ccc',borderRight:'0.5px solid #ccc'}}>
                                                                {(order?.items?.price)?(order?.items?.price) : '-'}</td>
                                                            
                                                            <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderBottom:'0.5px solid #ccc',borderRight:'0.5px solid #ccc'}}>
                                                                {(order?.quantity) ? (order?.quantity) + ((order?.qnty_value === 1) ? ' (dzn)' : ' (pcs)') : (order?.quantity) ? order?.quantity : 0}</td>
                                                            
                                                            <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderBottom:'0.5px solid #ccc',borderRight:'0.5px solid #ccc'}}>
                                                                {(invoice?.shipped_quantity)?(invoice?.shipped_quantity) : 0}</td>
                                                            
                                                            <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderBottom:'0.5px solid #ccc',borderRight:'0.5px solid #ccc'}}>
                                                                {numberWithCommas((((invoice?.quantity) ? invoice?.quantity : (order?.quantity) ? order?.quantity : 0) - ((invoice?.shipped_quantity) ? (invoice?.shipped_quantity) : 0)))}</td>
                                                            
                                                            <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderBottom:'0.5px solid #ccc',borderRight:'0.5px solid #ccc'}}>
                                                                {numberWithCommas(parseFloat((order?.items?.price * ((order?.quantity) ? order?.quantity : (order?.quantity) ? order?.quantity : 0))).toFixed(2))}</td>
                                                            
                                                            <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderBottom:'0.5px solid #ccc',borderRight:'0.5px solid #ccc'}}>
                                                                {numberWithCommas(parseFloat(((order?.items?.price) ? order?.items?.price : 0) * ((invoice?.shipped_quantity) ? invoice?.shipped_quantity : 0)).toFixed(2))}</td>
                                                            
                                                            <td style={{ padding: '20px', color: '#333333', backgroundColor: '#fff', borderBottom: '0.5px solid #ccc', borderRight: '0.5px solid #ccc' }}>
                                                                {numberWithCommas(parseFloat((order?.items?.price * (((invoice?.quantity) ? invoice?.quantity : (order?.quantity) ? order?.quantity : 0)  - ((invoice?.shipped_quantity) ? (invoice?.shipped_quantity) : 0)))).toFixed(2))}</td>
                                                        </tr>
                                                        ))
                                                    ))}
                                                </>
                                            ): (
                                                <tr>
                                                    <td colSpan={10} align={'center'} style={{fontWeight:'700',padding:'1em'}}> No orders available </td>
                                                </tr>
                                            )}
                                        </tbody>
                                        {(orderDetails?.order_item?.length > 0) ? (
                                        <tfoot>
                                            <tr>
                                                <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderTop:'2px solid #000',borderRight:'0.5px solid #ccc',fontWeight: 'bold'}}>Total</td>
                                                <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderTop:'2px solid #000',borderRight:'0.5px solid #ccc',fontWeight: 'bold'}}>&nbsp;</td>
                                                <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderTop:'2px solid #000',borderRight:'0.5px solid #ccc',fontWeight: 'bold'}}>&nbsp;</td>
                                                <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderTop:'2px solid #000',borderRight:'0.5px solid #ccc',fontWeight: 'bold'}}>&nbsp;</td>
                                                <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderTop:'2px solid #000',borderRight:'0.5px solid #ccc',fontWeight: 'bold'}}>{(orderQtyTotal) ? orderQtyTotal : 0}</td>
                                                <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderTop:'2px solid #000',borderRight:'0.5px solid #ccc',fontWeight: 'bold'}}>{(shippedQtyTotal) ? shippedQtyTotal : 0}</td>
                                                <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderTop:'2px solid #000',borderRight:'0.5px solid #ccc',fontWeight: 'bold'}}>{(pendingQtyTotal) ? pendingQtyTotal : 0}</td>
                                                <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderTop:'2px solid #000',borderRight:'0.5px solid #ccc',fontWeight: 'bold'}}>{(orderAmountTotal) ? '$'+numberWithCommas(parseFloat(orderAmountTotal).toFixed(2)) : '0'}</td>
                                                <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderTop:'2px solid #000',borderRight:'0.5px solid #ccc',fontWeight: 'bold'}}>{(orderAmountTotal) ? '$'+numberWithCommas(parseFloat(shippedAmountTotal).toFixed(2)) : '0'}</td>
                                                <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderTop:'2px solid #000',borderRight:'0.5px solid #ccc',fontWeight: 'bold'}}>{(pendingAmountTotal) ? '$'+numberWithCommas(parseFloat(pendingAmountTotal).toFixed(2)) : '0'}</td>
                                                
                                            </tr>
                                        </tfoot>
                                        ): ""}
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default OrderStatusView;