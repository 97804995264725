/******************* Constants For Orders ******************/

export const orderListConstants = {
    GET_ORDERLIST_SUCCESS: 'GET_ORDERLIST_SUCCESS',
    GET_ORDERLIST_FAILURE: 'GET_ORDERLIST_FAILURE'
}

export const orderByIdsConstants = {
    GET_ORDERBYIDS_SUCCESS: 'GET_ORDERBYIDS_SUCCESS',
    GET_ORDERBYIDS_FAILURE: 'GET_ORDERBYIDS_FAILURE',
}

export const createOrderConstants = {
    GET_CREATEORDER_SUCCESS: 'GET_CREATEORDER_SUCCESS',
    GET_CREATEORDER_FAILURE: 'GET_CREATEORDER_FAILURE'
}

export const viewOrderConstants = {
    GET_VIEWORDER_SUCCESS: 'GET_VIEWORDER_SUCCESS',
    GET_VIEWORDER_FAILURE: 'GET_VIEWORDER_FAILURE'
}

export const orderReportConstants = {
    GET_ORDERREPORT_SUCCESS: 'GET_ORDERREPORT_SUCCESS',
    GET_ORDERREPORT_FAILURE: 'GET_ORDERREPORT_FAILURE'
}

export const updateOrderConstants = {
    GET_UPDATEORDER_SUCCESS: 'GET_UPDATEORDER_SUCCESS',
    GET_UPDATEORDER_FAILURE: 'GET_UPDATEORDER_FAILURE',
}

export const deleteOrderConstants = {
    GET_DELETEORDER_SUCCESS: 'GET_DELETEORDER_SUCCESS',
    GET_DELETEORDER_FAILURE: 'GET_DELETEORDER_FAILURE'
}

export const clearOrderMessages = {
    CLEAR_ORDER_MESSAGE: 'CLEAR_ORDER_MESSAGE'
}