import axios from "../services/axios";
import { orderStatusReportConstants,orderStatusViewConstants, totalPurchaseReportConstants, commissionReportConstants, totalSalesReportConstants, clearReportDataConstants, downloadReportXLSConstants} from "../constants/report.constants";

export const orderStatusReport = () => {
  return (dispatch) => {
    axios.get("/report/order-status-report").then((response) => {
        if (response.data.hasOwnProperty("error")) {
          alert(response.data.error);
        } else {
            dispatch({ type: orderStatusReportConstants.GET_ORDERSTATUSREPORT_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({type: orderStatusReportConstants.GET_ORDERSTATUSREPORT_FAILURE,payload: err.message,});
        console.log(err);
      });
  };
};

export const orderStatusView = (id) => {
  return (dispatch) => {
    axios.get(`/report/order-status-view/${id}`).then((response) => {
        if (response.data.hasOwnProperty("error")) {
          alert(response.data.error);
        } else {
            dispatch({ type: orderStatusViewConstants.GET_ORDERSTATUSVIEW_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({type: orderStatusViewConstants.GET_ORDERSTATUSVIEW_FAILURE,payload: err.message,});
        console.log(err);
      });
  };
};

export const totalPurchaseReport = (formData) => {
  return (dispatch) => {
    axios.post("/report/total-purchase-report",formData).then((response) => {
        if (response.data.hasOwnProperty("error")) {
          alert(response.data.error);
        } else {
            dispatch({ type: totalPurchaseReportConstants.GET_TOTALPURCHASEREPORT_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({type: totalPurchaseReportConstants.GET_TOTALPURCHASEREPORT_FAILURE,payload: err.message,});
        console.log(err);
      });
  };
};

export const commissionReport = (formData) => {
  return (dispatch) => {
    axios.post("/report/commission-report",formData).then((response) => {
        if (response.data.hasOwnProperty("error")) {
          alert(response.data.error);
        } else {
            dispatch({ type: commissionReportConstants.GET_COMMISSIONREPORT_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({type: commissionReportConstants.GET_COMMISSIONREPORT_FAILURE,payload: err.message,});
        console.log(err);
      });
  };
};

export const totalSalesReport = (formData) => {
  return (dispatch) => {
    axios.post("/report/total-sales-report",formData).then((response) => {
        if (response.data.hasOwnProperty("error")) {
          alert(response.data.error);
        } else {
            dispatch({ type: totalSalesReportConstants.GET_TOTALSALESREPORT_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({type: totalSalesReportConstants.GET_TOTALSALESREPORT_FAILURE,payload: err.message,});
        console.log(err);
      });
  };
};

export const downloadReportXLS = (id) => {
  return (dispatch) => {
    axios.get(`/order/download-report-excel/${id}`).then((response) => {
      console.log('response',response);
        if (response.data.hasOwnProperty("error")) {
          alert(response.data.error);
        } else {
            dispatch({ type: downloadReportXLSConstants.GET_DOWNLOADREPORTXLS_SUCCESS, payload: { data: response },});
        }
      })
      .catch((err) => {
        dispatch({type: downloadReportXLSConstants.GET_DOWNLOADREPORTXLS_FAILURE,payload: err.message,});
        console.log(err);
      });
  };
};

export const clearReportData = () => async (dispatch) => {
  try {
    dispatch({ type: clearReportDataConstants.CLEAR_REPORT_DATA, payload: [] })
  } catch (err) {
      console.log(`Error while calling login api ${err}`);
  }
}