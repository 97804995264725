/******************* Constants For Invoices ******************/

export const invoiceListConstants = {
    GET_INVOICELIST_SUCCESS: 'GET_INVOICELIST_SUCCESS',
    GET_INVOICELIST_FAILURE: 'GET_INVOICELIST_FAILURE'
}

export const createInvoiceConstants = {
    GET_CREATEINVOICE_SUCCESS: 'GET_CREATEINVOICE_SUCCESS',
    GET_CREATEINVOICE_FAILURE: 'GET_CREATEINVOICE_FAILURE'
}

export const viewInvoiceConstants = {
    GET_VIEWINVOICE_SUCCESS: 'GET_VIEWINVOICE_SUCCESS',
    GET_VIEWINVOICE_FAILURE: 'GET_VIEWINVOICE_FAILURE'
}

export const updateInvoiceConstants = {
    GET_UPDATEINVOICE_SUCCESS: 'GET_UPDATEINVOICE_SUCCESS',
    GET_UPDATEINVOICE_FAILURE: 'GET_UPDATEINVOICE_FAILURE',
}

export const deleteInvoiceConstants = {
    GET_DELETEINVOICE_SUCCESS: 'GET_DELETEINVOICE_SUCCESS',
    GET_DELETEINVOICE_FAILURE: 'GET_DELETEINVOICE_FAILURE'
}

export const clearInvoiceMessages = {
    CLEAR_INVOICE_MESSAGE: 'CLEAR_INVOICE_MESSAGE'
}