import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import moment from "moment";
import { orderReport } from "../../actions/orderAction";
// import OrderReportPDF from "./orderReportPDF";
// import { PDFDownloadLink, BlobProvider } from '@react-pdf/renderer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import Axios from "axios";

const OrderReport = () =>{
    
    const {id} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
	const reportTemplateRef = useRef(null);

    const [orderDetails,setOrderDetails] = useState([]);
    
    const [quantityTotal,setQuantityTotal] = useState(0);
    const [amountTotal,setAmountTotal] = useState(0);

    useEffect(()=>{
        dispatch(orderReport(id));
    },[dispatch,id])

    // This function adds commas to a number for every thousand separator.
    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const reportDtl = useSelector((state) => state.orderReport.orderReport)
    useEffect(() => {
        if (reportDtl && reportDtl?.data && reportDtl?.data?.status === true) {
            setOrderDetails(reportDtl?.data?.data);
        }
        if (reportDtl && reportDtl?.data && reportDtl?.data?.status === false) {
            toast.error(reportDtl?.data?.message,{theme: "dark",});
        }
    }, [reportDtl]);
    
    useEffect(() => {
        const totalQuantity = orderDetails?.order_item?.reduce((acc, order) => { return acc + (order?.quantity ? order.quantity : 0);}, 0);
        setQuantityTotal(totalQuantity);
        const totalAmount = orderDetails?.order_item?.reduce((acc, order) => { return acc + (order?.cnf_value ? parseFloat(order?.cnf_value) : 0)}, 0);
        setAmountTotal(totalAmount);
    }, [orderDetails]);


    const token = localStorage.getItem('token');
    // Function to handle Excel file download and cleanup
    const handleXLSDownload = (id) => {
        // Define headers for the request
        const headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'Authorization': `Bearer ${token}`,
        };
        // Make GET request to download Excel file
        Axios.get(`${process.env.REACT_APP_BASE_URL}api/order/download-report-excel/${id}`, { headers })
        .then((response) => {
            // Handle response from server
            if (response.data.hasOwnProperty('error')) {
                // If there's an error in response, show alert
                alert(response.data.error);
            } else {
                // If download is successful, open the file in a new tab
                const downloadUrl = response.data.url;
                window.open(downloadUrl, '_blank');
                // If filePath is provided in response, schedule cleanup after 1 minute
                if (response.data.filePath) {
                    setTimeout(() => {
                        // Make POST request to unlink the file path
                        Axios.post(`${process.env.REACT_APP_BASE_URL}api/order/unlink-file-path`, { 'filePath': response.data.filePath }, { headers })
                        .then((response) => {
                            console.log('File cleanup response', response.data);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                    }, 60000); // 1 minute = 60,000 milliseconds
                }
            }
        })
        .catch((err) => {
            // Handle error if request fails
            console.log(err);
        });
    };


    const handlePDFDownload = (id) => {
        const headers = {
            'Content-Type': 'application/pdf',
            'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'Authorization': `Bearer ${token}`,
        };
        Axios.get(`${process.env.REACT_APP_BASE_URL}api/download-reporf-pdf/${id}`, { headers, responseType: 'blob', })
        .then( async (response) => {
            if (response.data.hasOwnProperty('error')) {
                alert(response.data.error);
            } else {
                if (response.data instanceof Blob) {
                    const urlBlob = window.URL.createObjectURL(response.data);
                    const link = document.createElement('a');
                    link.href = urlBlob;
                    link.setAttribute('download', `order_report_${id}.pdf`); // You can dynamically set the file name if needed
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                } else {
                    const reader = new FileReader();
                    reader.onload = () => {
                        const errorData = JSON.parse(reader.result);
                        alert(errorData.error || 'Unknown error occurred');
                    };
                    reader.readAsText(response.data);
                }
                // if (response.data.filePath) {
                //     setTimeout(() => {
                //         Axios.post(`${process.env.REACT_APP_BASE_URL}api/order/unlink-file-path`, { 'filePath': response.data.filePath }, { headers })
                //         .then((response) => {
                //             console.log('File cleanup response', response.data);
                //         })
                //         .catch((err) => {
                //             console.log(err);
                //         });
                //     }, 60000); 
                // }
            }
        })
        .catch((err) => {
            // Handle error if request fails
            console.log(err);
        });
    };
    return(<>
        <div className="dash-body">
            <div style={{backgroundColor:"#fff"}} >
                <div className="align-items-md-center d-flex gap-1 justify-content-between px-2">
                    <div>
                        <a href={"javascript:void(0)"} className="icon" title="go back" onClick={() => navigate(-1)}><i className="fa fa-arrow-left" aria-hidden="true"></i></a>
                    </div>
                    <div>
                        {/* <BlobProvider 
                            document={
                                <OrderReportPDF orderDetails={orderDetails} quantityTotal={quantityTotal} amountTotal={amountTotal} />
                            }
                        >
                            {({ url }) => (
                                <a href={url} target="_blank" rel="noopener noreferrer" title="Preview PDF"> <FontAwesomeIcon icon={faFilePdf} className="icon px-2" /></a>
                            )}
                        </BlobProvider>
                        <PDFDownloadLink
                            document={
                                <OrderReportPDF orderDetails={orderDetails} quantityTotal={quantityTotal} amountTotal={amountTotal} />
                            }
                            // style={{padding: '5px 5px 5px 5px', float: 'right' }}
                            fileName={orderDetails?.order_no+".order-report.pdf"}
                            className="MuiButtonBase-root MuiButton-root MuiButton-contained btn-download MuiButton-containedPrimary MuiButton-containedSizeLarge MuiButton-sizeLarge"
                        >
                            {({ blob, url, loading, error }) =>
                                loading ? "Loading document..." : <i className="fa-solid fa-download" title="Download PDF"></i>
                            }
                        </PDFDownloadLink> */}
                        <a href="#" onClick={() => handlePDFDownload(id)} rel="noopener noreferrer" title="Download XLS"> <i className="fa-solid fa-download" title="Download PDF"></i></a>
                        <a href="#" onClick={() => handleXLSDownload(id)} rel="noopener noreferrer" title="Download XLS"> <FontAwesomeIcon icon={faFileExcel} className="icon px-2" /></a>
                    </div>
                </div>
                    
                <div ref={reportTemplateRef} style={{backgroundColor: '#fff', padding:'0em 8em'}}>
                    <table width={980} border={0} cellSpacing={0} cellPadding={0} style={{margin: "auto",fontWeight: 500,fontFamily: '"Helvetica", sans-serif',color: "#000",fontSize: 13,padding: 15,lineHeight: "1.4",backgroundColor: "#fff",width: '100%'}}>
                        <tbody>
                            <tr>
                                <td>
                                    <table style={{border: "3px solid #000",width: "100%",borderWidth: "2px 5px 5px 2px",}}>
                                        <tbody>
                                            <tr>
                                                <td valign="top" style={{ textAlign: "center" }}>
                                                    <table width="100%" border={0} cellSpacing={0} cellPadding={0}>
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan={4} style={{
                                                                    fontSize: '55px',
                                                                    fontWeight: 700,
                                                                    textTransform: 'uppercase',
                                                                    padding: '20px',
                                                                }}>
                                                                    <p style={{
                                                                        borderBottom: '1px solid #000',
                                                                        marginBottom: '3px',
                                                                        maxWidth: '800px',
                                                                        margin: '0 auto 3px',
                                                                    }}> {(orderDetails?.sellers?.name) ? orderDetails?.sellers?.name : '-'} </p>
                                                                    <p style={{
                                                                        margin: '0',
                                                                        borderTop: '1px solid',
                                                                        maxWidth: '800px',
                                                                        margin: '0 auto 3px',
                                                                    }}></p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td height="10px" />
                            </tr>
                            <tr>
                                <td>
                                    <table style={{ width: "100%" }}>
                                        <tbody>
                                            <tr style={{ justifyContent: "space-between",display: "flex"}}>
                                                <td style={{ lineHeight: "1.7",textAlign: "left",fontSize: 16,width: "20%",}}>
                                                    {(orderDetails?.direct === 1) ? 
                                                        <strong>DIRECT</strong>
                                                    :""}
                                                </td>
                                                <td style={{ lineHeight: "1.7",textAlign: "center",fontSize: 16,width: "60%",}}>
                                                    <strong>Order Number : </strong> {orderDetails?.order_no}
                                                </td>
                                                <td style={{ lineHeight: "1.7",textAlign: "left",fontSize: 16,width: "20%",}}>
                                                    <strong>Order Date : </strong> {(orderDetails?.order_date) ? moment(orderDetails?.order_date).format('DD-MMM-YYYY') : '-'}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table style={{ width: "100%" }}>
                                        <tbody>
                                            
                                            <tr>
                                                <td style={{ lineHeight: "1.7",textAlign: "left",fontSize: 16,width: "60%",}}>
                                                    <strong>Buyer : </strong>
                                                    {(orderDetails?.buyers?.name) ? orderDetails?.buyers?.name : '-'}<br/>
                                                    <span style={{marginLeft:"60px"}}>
                                                    {(orderDetails?.buyers?.city) ? (orderDetails?.buyers?.city)+',' : ''} {(orderDetails?.buyers?.country) ? orderDetails?.buyers?.country : ''}
                                                    </span>
                                                </td>
                                                {(orderDetails?.revised_date !== null) ? 
                                                    <td style={{ lineHeight: "1.7",textAlign: "left",fontSize: 16,width: "60%",}}>
                                                        <strong>Revised Date : </strong>
                                                        {(orderDetails?.revised_date) ? moment(orderDetails?.revised_date).format('DD-MMM-YYYY') : '-'}
                                                    </td>
                                                :""}
                                            </tr>
                                            <tr>
                                                <td style={{ lineHeight: "1.7",textAlign: "left",fontSize: 16,width: "80%",}}>
                                                    <strong>Seller : </strong>
                                                    {(orderDetails?.sellers?.name) ? orderDetails?.sellers?.name : '-'} <br/>    
                                                    {/* <span style={{ marginLeft:"60px"}}>
                                                        {(orderDetails?.sellers?.city) ? (orderDetails?.sellers?.city)+',' : ''} {(orderDetails?.sellers?.country) ? orderDetails?.sellers?.country : ''}
                                                    </span> */}
                                                </td>
                                                <td style={{ lineHeight: "1.7",textAlign: "left",fontSize: 16,width: "40%,",display: "block"}}>
                                                    <strong>Quantity (Dz) : </strong>
                                                    {(orderDetails?.total_quantity)?orderDetails?.total_quantity:'0'}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ lineHeight: "1.7",textAlign: "left",fontSize: 16,width: "60%",}}>
                                                    <span style={{ marginLeft:"60px"}}>
                                                        {(orderDetails?.sellers?.city) ? (orderDetails?.sellers?.city)+',' : ''} {(orderDetails?.sellers?.country) ? orderDetails?.sellers?.country : ''}
                                                    </span>
                                                </td>
                                                <td style={{ lineHeight: "1.7",textAlign: "left",fontSize: 16,width: "40%,",}}>
                                                    <strong>Order Amount US($) : </strong>
                                                    {(orderDetails?.total_cnf)?orderDetails?.total_cnf: 0}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ lineHeight: "1.7",textAlign: "left",fontSize: 16,width: "60%",}}>
                                                    <strong>Payment Terms : </strong>
                                                    {(orderDetails?.payment_term?.name) ? orderDetails?.payment_term?.name : '-'}
                                                </td>
                                                <td style={{ lineHeight: "1.7",textAlign: "left",fontSize: 16,width: "40%,",}}>
                                                    <strong>Shipment Date : </strong>
                                                    {(orderDetails?.shipment_date) ?moment(orderDetails?.shipment_date).format('DD-MMM-YYYY'):'-'}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ lineHeight: "1.7",textAlign: "left",fontSize: 16,width: "60%",}}>
                                                    <strong>Terms : </strong>
                                                    {(orderDetails?.shipment_term)?orderDetails?.shipment_term: '-'}
                                                </td>
                                                <td style={{ lineHeight: "1.7",textAlign: "left",fontSize: 16,width: "40%,",}}>
                                                    <strong>Validity : </strong>
                                                    {(orderDetails?.validity)?orderDetails?.validity: '-'}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td height={5} />
                            </tr>
                            <tr>
                                <td>
                                    <table cellPadding={0} cellSpacing={0} width="100%" style={{fontFamily: '"Helvetica", sans-serif', borderCollapse: "collapse", fontSize: 16,}}>
                                        <tbody>
                                            <tr style={{ textAlign: "left", border: "1px solid #000", borderBottom: "none",}} >
                                                <th scope="col" style={{ textAlign: "center", padding: 5, width: "5%",borderRight: "1px solid #000", backgroundColor: '#e6e2e2'}}>Sl.No</th>
                                                <th scope="col" style={{ textAlign: "center", padding: 5, width: "10%",borderRight: "1px solid #000", backgroundColor: '#e6e2e2'}}>Ref. No.</th>
                                                <th scope="col" style={{ textAlign: "center", padding: 5, width: "50%",borderRight: "1px solid #000", backgroundColor: '#e6e2e2'}}> Description</th>
                                                <th scope="col" style={{ textAlign: "center", padding: 5, width: "10%",borderRight: "1px solid #000", backgroundColor: '#e6e2e2'}}>Quantity</th>
                                                <th scope="col" style={{ textAlign: "center", padding: 5, width: "15%",borderRight: "1px solid #000", backgroundColor: '#e6e2e2'}}>Unit Price US($)</th>
                                                <th scope="col" style={{ textAlign: "center", padding: 5, width: "10%", backgroundColor: '#e6e2e2'}}>Amount US($)</th>
                                            </tr>
                                            {(orderDetails?.order_item?.length > 0) ? (
                                                <>
                                                    {orderDetails?.order_item?.map((order, index) => (
                                                        <>
                                                            <tr key={index} style={{ border: "1px solid #000", borderBottom: "1px solid #000", verticalAlign: "top", 
                                                                    ...(JSON.parse(order.fonts).italic ? {fontStyle: 'italic'} : {}), 
                                                                    ...(JSON.parse(order.fonts).bold ? {fontWeight: '700'} : {}),
                                                                    ...(JSON.parse(order.fonts).underline ? {textDecoration: 'underline'} : {})}}
                                                                >
                                                                <td style={{ textAlign: "center", padding: 5, borderRight: "1px solid #000", color:order.item_color}}>
                                                                    {index+1}
                                                                </td>
                                                                <td style={{ textAlign: "center", padding: 5, borderRight: "1px solid #000", color:order.item_color}}>
                                                                    {(order?.items?.item_no)?(order?.items?.item_no) : '-'}
                                                                </td>
                                                                <td style={{ padding: 5, borderRight: "1px solid #000", color:order.item_color}}>
                                                                    <p style={{ margin: 0 }}>
                                                                        <span style={{ display: "block" }}>
                                                                            {" "}
                                                                            Barcode : {(order?.items?.barcode)?(order?.items?.barcode) : '-'}
                                                                        </span>
                                                                        <span style={{ display: "block" }}> 
                                                                            Style : {(order?.items?.item_style?.name)?(order?.items?.item_style?.name) : '-'}
                                                                        </span>
                                                                        <span style={{ display: "block" }}> 
                                                                            For : {(order?.items?.item_for?.name)?(order?.items?.item_for?.name) : '-'}
                                                                        </span>
                                                                        <span style={{ display: "block" }}> 
                                                                            Category : {(order?.items?.item_category?.name)?(order?.items?.item_category?.name) : '-'}
                                                                        </span>
                                                                        <span style={{ display: "block" }}>
                                                                            {" "}
                                                                            Fabric : {(order?.items?.item_fabric?.name)?(order?.items?.item_fabric?.name) : '-'}
                                                                        </span>
                                                                        <span style={{ display: "block" }}>
                                                                            {" "}
                                                                            Wash : {(order?.items?.wash)?(order?.items?.wash) : '-'}
                                                                        </span>
                                                                        <span style={{ display: "block" }}> Size : {(order?.item_size?.name)?(order?.item_size?.name) : '-'}</span>
                                                                        <span style={{ display: "block" }}>Ratio : {(order?.item_ratio?.name)?(order?.item_ratio?.name) : '-'}</span>
                                                                        <span style={{ display: "block" }}>
                                                                            Color : {(order?.items?.color)?(order?.items?.color) : '-'}
                                                                        </span>
                                                                        <span style={{ display: "block" }}>
                                                                            Brand : {(order?.items?.item_brand?.name)?(order?.items?.item_brand?.name) : '-'}
                                                                        </span>
                                                                        <span style={{ display: "block" }}>
                                                                            Extra : {(order?.items?.extra)?(order?.items?.extra) : '-'}
                                                                        </span>
                                                                    </p>
                                                                </td>
                                                                <td style={{textAlign: "center",padding: 5,borderRight: "1px solid #000",color:order.item_color}}>
                                                                    {(order?.quantity)? (order?.quantity) : '-'}
                                                                </td>
                                                                <td style={{textAlign: "center",padding: 5,borderRight: "1px solid #000",color:order.item_color}}>
                                                                    {(order?.item_price?.unit_price) ? numberWithCommas(order?.item_price?.unit_price) : '-'}
                                                                </td>
                                                                <td style={{ textAlign: "center", padding: 5, color:order.item_color }}>{(order?.cnf_value)?numberWithCommas(order?.cnf_value) : 0}</td>
                                                            </tr>
                                                        </>
                                                    ))}
                                                </>
                                            ): (
                                                <tr>
                                                    <td colSpan={6} align={'center'} style={{fontWeight:'700',padding:'1em'}}> No items available </td>
                                                </tr>
                                            )}
                                            {(orderDetails?.is_message === 1 && orderDetails?.message_info !== null) ? 
                                                <tr style={{ textAlign: "left", border: "1px solid #000",}} >
                                                    <td colSpan={6} align={'center'} style={{fontWeight:'700',padding:'1em'}}>{orderDetails?.message_info}</td>
                                                </tr>
                                            :""}
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td height="10px" />
                            </tr>
                            <tr>
                                <td>
                                    <table cellPadding={0} cellSpacing={0} width="100%"
                                        style={{ fontFamily: '"Helvetica", sans-serif', borderCollapse: "collapse", fontSize: 16,}}
                                    >
                                        <tbody>
                                            <tr style={{ textAlign: "left", border: "1px solid #ff0000",borderBottom: "5px solid #ff0000",}}>
                                                <td style={{ padding: "5px 10px", width: "65%", fontWeight: 600 }}>
                                                    TOTAL
                                                </td>
                                                <td style={{ textAlign: "center", padding: 5, width: "10%" }}>
                                                    <strong>{(quantityTotal) ? quantityTotal : '0'}</strong>
                                                </td>
                                                <td style={{ textAlign: "center", padding: 5, width: "15%" }} />
                                                <td style={{ textAlign: "center", padding: 5, width: "10%" }}>
                                                    <strong>{(amountTotal) ? numberWithCommas(parseFloat(amountTotal).toFixed(2)) : '0'}</strong>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td height="10px" />
                            </tr>
                            <tr>
                                <td height="10px" />
                            </tr>
                            <tr>
                                <td>
                                    <table cellPadding={0} cellSpacing={0} width="100%"
                                        style={{ fontFamily: '"Helvetica", sans-serif', borderCollapse: "collapse", fontSize: 16, }} >
                                        <tbody>
                                            <tr>
                                                <td style={{ padding: "5px 10px", width: "40%", fontWeight: 600 }}>
                                                    <input
                                                        type="text"
                                                        id="fname"
                                                        name="fname"
                                                        style={{
                                                            border: "none",
                                                            borderBottom: "1px solid #000",
                                                            width: "100%",
                                                            height: 35,
                                                            outline: "none",
                                                            fontSize: 15,
                                                        }}
                                                    />
                                                    <label
                                                        style={{
                                                            display: "block",
                                                            textAlign: "center",
                                                            paddingTop: 15,
                                                        }}
                                                        htmlFor="fname"
                                                    >
                                                        BUYER
                                                    </label>
                                                </td>
                                                <td
                                                    style={{
                                                        padding: "5px 10px",
                                                        width: "15%",
                                                        fontWeight: 400,
                                                        fontStyle: "italic",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    Signatures
                                                </td>
                                                <td
                                                    style={{ padding: "5px 10px", width: "45%", fontWeight: 600 }}
                                                >
                                                    <input
                                                        type="text"
                                                        id="fname"
                                                        name="fname"
                                                        style={{
                                                            border: "none",
                                                            borderBottom: "1px solid #000",
                                                            width: "100%",
                                                            height: 35,
                                                            outline: "none",
                                                            fontSize: 15,
                                                        }}
                                                    />
                                                    <label
                                                        style={{
                                                            display: "block",
                                                            textAlign: "center",
                                                            paddingTop: 15,
                                                        }}
                                                        htmlFor="fname"
                                                    >
                                                        SELLER
                                                    </label>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            {/* <tr>
                                <td>
                                    <label htmlFor="fname" style={{ fontSize: 16, display: "block" }}>
                                        Note:
                                    </label>
                                    <input
                                        type="text"
                                        id="fname"
                                        name="fname"
                                        style={{
                                            border: "1px solid #000",
                                            width: "99%",
                                            display: "block",
                                            minHeight: 35,
                                            outline: "none",
                                            fontSize: 15,
                                        }}
                                    />
                                </td>
                            </tr> */}
                            <tr>
                                <td height={20} />
                            </tr>
                            <tr>
                                <td>
                                    <table cellPadding={0} cellSpacing={0} width="100%" style={{ fontFamily: '"Helvetica", sans-serif', borderCollapse: "collapse",fontSize: 16,}}>
                                        <tbody>
                                            <tr style={{ borderTop: "2px solid #000" }}>
                                                <td style={{textAlign: "start",padding: "10px 5px",width: "40%",}}>
                                                    <strong>Order Number : </strong>
                                                    {orderDetails?.order_no}
                                                </td>
                                                <td style={{textAlign: "start",padding: "10px 5px",width: "20%",color : "blue"}}>
                                                    * IN GOD WE TRUST *
                                                </td>
                                                <td style={{ textAlign: "end", padding: "10px 5px", width: "40%" }}>
                                                    Page 1 of 1
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </>);
}

export default OrderReport;