import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { toast } from 'react-toastify';
import { getItemProp } from "../../actions/itemAction";
import { getBuyers } from "../../actions/buyerAction";
import { getSellers } from "../../actions/sellerAction";
import { getOrderByIds } from "../../actions/orderAction";
import { getItemByOrderId, getItemByOrderItemId } from "../../actions/itemAction"; 
import { updateInvoice, clearInvoiceMsg, viewInvoice } from "../../actions/invoiceAction";
import CreatableSelect from 'react-select/creatable';

const InvoiceUpdate = () => {

    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [error, setError] = useState(false);
    const [itemError, setItemError] = useState(false);
    const [buyersList, setBuyersList] = useState([]);
    const [sellersList, setSellersList] = useState([]);

    const [sizesList, setSizeslist] = useState([]);
    // const [ratiosList, setRatioslist] = useState([]);
    // const [stylesList, setStyleslist] = useState([]);
    const [forList, setForlist] = useState([]);
    const [categoriesList, setCategorieslist] = useState([]);
    const [brandsList, setBrandsList] = useState([]);
    const [fabricsList, setFabricslist] = useState([]);
    const [assortmentsList, setAssortmentsList] = useState([]);
    const [termsList, setTermslist] = useState([]);
    

    const [selectedBuyer, setSelectedBuyer] = useState("");
    const [selectedSeller, setSelectedSeller] = useState("");
    const [isSpecialCommissionChecked, setIsSpecialCommissionChecked] = useState(false);
    const [selectedTerm, setSelectedTerm] = useState("");
    
    const [selectedOrder, setSelectedOrder] = useState("");
    const [selectedOrderItem, setSelectedOrderItem] = useState("");
    const [selectedItemId, setSelectedItemId] = useState("");
    const [itemSize, setItemSize] = useState("");
    // const [itemRatio, setItemRatio] = useState("");
    const [itemStyle, setItemStyle] = useState("");
    const [itemFor, setItemFor] = useState("");
    const [itemCategory, setItemCategory] = useState("");
    const [itemFabrics, setItemFabrics] = useState("");
    const [itemBrand, setItemBrand] = useState("");
    const [itemAssortment, setItemAssortment] = useState("");
    const [shippedQty, setShippedQty] = useState("");
    const [pendingQty, setPendingQty] = useState("");
    const [amount, setAmount] = useState("");
    const [invoiceTotal, setInvoiceTotal] = useState("");
    const [totalNetAmount, setTotalNetAmount] = useState("");
    const [totalShippedQuantity, setTotalShippedQuantity] = useState("");
       
    // manage state for invoice
    const [invoiceDetails, setInvoiceDetails] = useState({
        invoice_no:"", 
        date:new Date().toISOString().substr(0, 10), 
        commission:"", 
        credit_note:"", 
    });

    //manage state for item
    const [itemDetails, setItemDetails] = useState({
        id:"", 
        item_no:"", 
        barcode:"", 
        basicPrice:"", 
        overPrice:"", 
        price:"",
        quantity:"",
        wash:"", 
        color:"", 
        extra:"",
    });

    const [orders, setOrders] = useState([]);
    const [items, setItems] = useState([]);
    const [selectedItemArray, setSelectedItemArray] = useState([]);
    const [selectedServerItemArray, setSelectedServerItemArray] = useState([]);

    // useEffect for call Api's
    useEffect(() => {
        dispatch(viewInvoice(id))
        dispatch(getItemProp());
        dispatch(getBuyers());
        dispatch(getSellers());
    }, [dispatch,id]);

    //get all the buyers and sellers list from reducer and set here
    const getBuyerList = useSelector((state) => state.getBuyerList.getBuyerList)
    const getSellerList = useSelector((state) => state.getSellerList.getSellerList)
    useEffect(() => {
        if (getBuyerList && getBuyerList?.data && getBuyerList?.data?.status === true) {
            setBuyersList(getBuyerList?.data?.data);
        }
        if (getSellerList && getSellerList?.data && getSellerList?.data?.status === true) {
            setSellersList(getSellerList?.data?.data);
        }
    }, [getBuyerList,getSellerList]);

    // get the all item properties list from reducer and set in state
    const itemProperties = useSelector((state) => state.getItemProp.getItemProp)
    useEffect(() => {
        if (itemProperties && itemProperties?.data && itemProperties?.data?.status === true) {
            setSizeslist(itemProperties?.data?.data.sizes);
            // setRatioslist(itemProperties?.data?.data.ratios);
            // setStyleslist(itemProperties?.data?.data.styles);
            setForlist(itemProperties?.data?.data.for);
            setCategorieslist(itemProperties?.data?.data.categories);
            setFabricslist(itemProperties?.data?.data.fabrics);
            setBrandsList(itemProperties?.data?.data.brands);
            setAssortmentsList(itemProperties?.data?.data?.assortments);
            setTermslist(itemProperties?.data?.data.terms);
        }
    }, [itemProperties]);

    // get invoice data via id and set it in state
    const invoiceDtl = useSelector((state) => state.viewInvoice.viewInvoice);
    useEffect(() => {
        if(invoiceDtl && invoiceDtl?.data && invoiceDtl?.data?.status === true ){
            setInvoiceDetails({
                id          : invoiceDtl?.data?.data?.id, 
                invoice_no  : invoiceDtl?.data?.data?.invoice_no, 
                date        : invoiceDtl?.data?.data?.invoice_date,
                commission  : invoiceDtl?.data?.data?.commission,
                credit_note : invoiceDtl?.data?.data?.credit_note,
            });
            setItemDetails({
                quantity : invoiceDtl?.data?.data?.quantity,
            });
            setSelectedBuyer(invoiceDtl?.data?.data?.buyer_id);
            setSelectedSeller(invoiceDtl?.data?.data?.seller_id);
            setSelectedTerm(invoiceDtl?.data?.data?.payment_term_id);
            setIsSpecialCommissionChecked(invoiceDtl?.data?.data?.special_commission);

            setSelectedItemArray(invoiceDtl?.data?.data?.invoice_order);
            setSelectedServerItemArray(invoiceDtl?.data?.data?.invoice_order)
            // setInvoiceTotal(invoiceDtl?.data?.data?.invoice_total);
            setInvoiceTotal(invoiceDtl?.data?.data?.invoice_order.reduce((acc, item) => acc + (parseFloat(item.amount) || 0), 0).toFixed(2));
            setTotalNetAmount(invoiceDtl?.data?.data?.net_amount);
            setTotalShippedQuantity(invoiceDtl?.data?.data?.total_quantity);

        }
    }, [invoiceDtl]);

    // call the API for get orders by selected buyer or seller
    useEffect(() => {
        const requestData = {
            buyer_id  : selectedBuyer  || undefined,
            seller_id : selectedSeller || undefined,
        };
        if(selectedBuyer && selectedSeller){
            dispatch(getOrderByIds(requestData));
        }
    }, [selectedBuyer, selectedSeller, dispatch]);

    // get the response of order by selected ids
    const orderListById = useSelector((state) => state.getOrderById.getOrderById)
    useEffect(() => {
        if (orderListById && orderListById?.data && orderListById?.data?.status === true) {
            setOrders(orderListById?.data?.data);
        }
        if(orderListById && orderListById?.data && orderListById?.data?.status === false){
            toast.error(orderListById?.data?.message,{theme: "dark",})
        }
    }, [orderListById,navigate,dispatch]);

    // call the API for get items by selected order id
    useEffect(() => {
        if(selectedOrder){
            dispatch(getItemByOrderId({order_id  : selectedOrder || undefined}));
        }
    }, [selectedOrder,dispatch])
    
    // get the response of order by selected ids
    const itemListByOrderId = useSelector((state) => state.getItemByOrderId.getItemByOrderId)
    useEffect(() => {
        if (itemListByOrderId && itemListByOrderId?.data && itemListByOrderId?.data?.status === true) {
            setItems(itemListByOrderId?.data?.data);
        }
        if(itemListByOrderId && itemListByOrderId?.data && itemListByOrderId?.data?.status === false){
            toast.error(itemListByOrderId?.data?.message,{theme: "dark",})
        }
    }, [itemListByOrderId,navigate,dispatch]);

    // call the get item API by selected item ID
    useEffect(() => {
        if(selectedOrderItem){
            dispatch(getItemByOrderItemId({"order_item_id": selectedOrderItem,"invoice_id":id}));
        }
    }, [selectedOrderItem, dispatch]);

    // get item data via order_item_id and set it in state
    const itemDtl = useSelector((state) => state.getItemByOrderItemId.getItemByOrderItemId);
    useEffect(() => {
        if(itemDtl && itemDtl.data && itemDtl.data.status === true ){
            setItemDetails({
                id          : itemDtl?.data?.data?.id, 
                item_no     : itemDtl?.data?.data?.item_no, 
                date        : itemDtl?.data?.data?.date,
                quantity    : itemDtl?.data?.data?.remaining_quantity,
                wash        : itemDtl?.data?.data?.wash,
                color       : itemDtl?.data?.data?.color,
                extra       : itemDtl?.data?.data?.extra,
                barcode     : itemDtl?.data?.data?.barcode,
                basicPrice  : itemDtl?.data?.data?.basic_price, 
                overPrice   : itemDtl?.data?.data?.over_price, 
                price       : itemDtl?.data?.data?.price,
                itemPrice   : itemDtl?.data?.data?.item_price
            });
            setItemSize(itemDtl?.data?.data?.size_id);
            // setSelectedRatio(itemDtl?.data?.data?.ratio_id);
            setItemStyle(itemDtl?.data?.data?.style_id);
            setItemFor(itemDtl?.data?.data?.for_id);
            setItemCategory(itemDtl?.data?.data?.category_id);
            setItemFabrics(itemDtl?.data?.data?.fabric_id);
            setItemBrand(itemDtl?.data?.data?.brand_id);
            setItemAssortment(itemDtl?.data?.data?.assortment_id);
            // setCnfValue("");
        }
    }, [itemDtl]);
    // Update the state when we change the invoice value
    const handleInvoiceForm = (e) => {
        // Check if the input value is a valid number with at most 5 digits
        if (e.target.name === 'commission' && !/^\d{0,5}(\.\d{0,2})?$/.test(e.target.value)) {
            return;
        }
        if (e.target.name === 'credit_note' && !/^\d{0,7}(\.\d{0,2})?$/.test(e.target.value)) {
            return;
        }
        setInvoiceDetails({
            ...invoiceDetails, 
            [e.target.name]: e.target.value
        })
    }

    // set invoice properties in state
    const handleInvoiceDetails = (e, type) => {
        if(type === 'buyer'){
            setSelectedBuyer(e ? e.value : "");
        }else if(type === 'seller'){
            setSelectedSeller(e ? e.value : "");
        }else if(type === 'specialCommission'){
            setIsSpecialCommissionChecked(!isSpecialCommissionChecked);}
        else if(type === 'terms'){
            setSelectedTerm(e ? e.value : "");
        }    
    }

    // function to create order
    const handleInvoiceSubmit = async (e) => {
        e.preventDefault();
        if (!invoiceDetails.invoice_no || !invoiceDetails.date ||  !selectedBuyer || !selectedSeller || !selectedTerm) {
            setError(true);
            return false;
        } else {
            dispatch(
                updateInvoice({
                    id              : id,
                    invoice_no      : invoiceDetails?.invoice_no,
                    invoice_date    : invoiceDetails?.date,
                    buyer_id        : selectedBuyer,
                    seller_id       : selectedSeller,
                    payment_term_id : selectedTerm,
                    commission      : invoiceDetails?.commission,
                    special_commission  : isSpecialCommissionChecked,

                    credit_note     : invoiceDetails?.credit_note,
                    itemArray       : selectedServerItemArray,
                    invoice_total   : invoiceTotal - (invoiceDetails.credit_note ? invoiceDetails.credit_note : 0),
                    net_amount      : totalNetAmount,
                    total_shipped_quantity  : totalShippedQuantity,
                })
            );
        }
    };


    //set amount value and quantity of perticular item
    useEffect(()=>{
        if(itemDetails.price && shippedQty){
            setAmount(parseFloat(itemDetails.price * shippedQty).toFixed(2));
        }else if(shippedQty === ''){
            setAmount(parseFloat(itemDetails.price * shippedQty).toFixed(2));
        }
        if(shippedQty && itemDetails.quantity){
            setPendingQty(itemDetails.quantity - shippedQty);
        }else if(shippedQty === ''){
            setPendingQty('');
        }
    },[itemDetails.price,shippedQty,itemDetails.quantity])

    //set invoice total amount via credit note
    // useEffect(()=>{
    //     if(invoiceDetails?.credit_note && invoiceTotal ){
    //         setNetAmount(parseFloat(invoiceTotal) - parseFloat(invoiceDetails?.credit_note));
    //     }else if(invoiceDetails?.credit_note === ''){
    //         invoiceDetails.credit_note = 0;
    //         setNetAmount(parseFloat(invoiceTotal) - parseFloat(invoiceDetails?.credit_note));
    //     }
    // },[invoiceTotal,invoiceDetails.credit_note])

    // set item properties in state
    const handleItemDetails = (e, type) =>{
        let value = e ? e.value : "";
        if(type === 'orderNo'){
            setSelectedOrder(value);
        }else if(type === 'itemNo'){
            setSelectedOrderItem(value)
            setSelectedItemId(e ? e.item_id : "")
        }
    }
    // function to create or update item
    const handleItemSubmit = async (e) => {
        e.preventDefault();
        if (!selectedOrder) {
            setItemError(true);
            return false;
        } else {
            const indexById = selectedItemArray.findIndex(item => 
                item.order_id === selectedOrder && item.item_id === selectedItemId,
            );
            const newItem = {
                invoice_id    : parseInt(id),
                order_item_id : selectedOrderItem,
                order_id    : selectedOrder,
                item_id     : selectedItemId,
                item_name   : items.find(itemData => itemData.item_id === selectedItemId)?.item_no || selectedItemId,
                barcode     : itemDetails?.barcode,
                basic_price : itemDetails?.basicPrice, 
                over_price  : itemDetails?.overPrice, 
                price       : itemDetails?.price,
                quantity    : itemDetails?.quantity,
                shipped_quantity    : shippedQty,
                pending_quantity    : pendingQty,
                size        : itemSize,
                style       : itemStyle,
                for         : itemFor,
                category    : itemCategory,
                fabrics     : itemFabrics,
                color       : itemDetails?.color,
                wash        : itemDetails?.wash,
                assortment  : itemAssortment,
                brand       : itemBrand,
                netAmount   : itemDetails?.basicPrice * shippedQty,
                amount      : amount,
                extra       : itemDetails?.extra,
            };
            const serverItemArray = {
                invoice_id  : parseInt(id),
                order_item_id : selectedOrderItem,
                order_id    : selectedOrder,
                item_id     : selectedItemId,
                quantity    : itemDetails?.quantity,
                shipped_quantity    : shippedQty,
                pending_quantity    : pendingQty,
                amount      : amount,
            };
            let temp = [...selectedItemArray];
            let temp2 = [...selectedServerItemArray];
            // if item already exist then edit or insert accordingly
            if (indexById === -1) {
                temp = [...temp, newItem];
                temp2 = [...temp2, serverItemArray];
            } else {
                temp[indexById] = newItem;
                temp2[indexById] = serverItemArray;
            }
            setSelectedItemArray(temp);
            setSelectedServerItemArray(temp2);
            // Calculate the sum of cnfValue and quantity
            setInvoiceTotal(temp.reduce((acc, item) => acc + (parseFloat(item.amount) || 0), 0).toFixed(2));
            setTotalNetAmount(temp.reduce((acc, item) => acc + (parseFloat(item.netAmount) || 0), 0).toFixed(2));
            setTotalShippedQuantity(temp.reduce((acc, item) => acc + (parseInt(item.shipped_quantity) || 0), 0));
        }
    };

    // Remove the selected item from item array list
    const removeItem = (index) => {
        let temp = [...selectedItemArray];
        let temp1 = [...selectedServerItemArray];
        temp.splice(index, 1);
        temp1.splice(index, 1);
        setSelectedItemArray(temp);
        setSelectedServerItemArray(temp1);

        // Recalculate the totals after removing the item
        setInvoiceTotal(temp.reduce((acc, item) => acc + (parseFloat(item.amount) || 0), 0).toFixed(2));
        setTotalNetAmount(temp.reduce((acc, item) => acc + (parseFloat(item.netAmount) || 0), 0).toFixed(2));
        setTotalShippedQuantity(temp.reduce((acc, item) => acc + (parseInt(item.shipped_quantity) || 0), 0));
    };

    // Edit the selected item or set that value again in item state
    const editSelectedItem = (index) => {
        let temp = selectedItemArray[index];
        console.log('temp',temp);
        setItemDetails({
            id          : temp?.id, 
            item_no     : temp?.order_item_id, 
            date        : temp?.date,
            extra       : temp?.extra,
            barcode     : temp?.barcode,
            basicPrice  : temp?.basic_price, 
            overPrice   : temp?.over_price, 
            price       : temp?.price,
            quantity    : temp?.quantity,
            itemPrice   : temp?.item_price,
            wash        : temp?.wash,
            color       : temp?.color,
        });
        setSelectedOrder(temp?.order_id);
        setSelectedItemId(temp?.item_id);
        setSelectedOrderItem(temp?.order_item_id);
        setShippedQty(temp?.shipped_quantity);
        setAmount(temp?.amount)

        setItemSize(temp?.size);
        setItemStyle(temp?.style);
        setItemFor(temp?.for);
        setItemCategory(temp?.category);
        setItemFabrics(temp?.fabrics);
        setItemBrand(temp?.brand);
    };
    //select data from reducer and set here
    const updateInvoiceDtl = useSelector((state) => state.updateInvoice.updateInvoice)
    useEffect(() => {
        if (updateInvoiceDtl && updateInvoiceDtl?.data && updateInvoiceDtl?.data?.status === true) {
            navigate("/invoices")
            toast.success(updateInvoiceDtl?.data?.message,{theme: "dark",});
            dispatch(clearInvoiceMsg());
        }
        if (updateInvoiceDtl && updateInvoiceDtl?.data && updateInvoiceDtl?.data?.status === false) {
            toast.error(updateInvoiceDtl?.data?.message,{theme: "dark",});
            dispatch(clearInvoiceMsg());
        }
    }, [updateInvoiceDtl,navigate,dispatch]);

    // return array in lable value pair for each select option 
    const createOptionsArray = (dataArray, labelColumnName, valueColumnName, idColumnName=null) => {
        return dataArray.map((data, index) => {
            return {
                label: data[labelColumnName],
                value: data[valueColumnName],
                item_id   : idColumnName ? data[idColumnName]  : null,
            };
        });
    };
    
    // return lable value index object with selected value in DB
    const findById = (optionsArray, targetId) => {
        return optionsArray.find(option => option.value === targetId);
    };

    return (<>
        <div className="dash-body">
            <div className="head-row">
                <h3>Update Invoice</h3>
            </div>
            <div className="row">
                <div className="col-lg-8">
                    <form onSubmit={handleInvoiceSubmit} onKeyDown={(e) => { if (e.key === 'Enter') e.preventDefault(); }}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="box-shd height-full">
                                    <div className="box-head">
                                        <h4>Invoice Information</h4>
                                    </div>
                                    <div className="box-body">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="row">
                                                    <div className="col-sm-6 dash-form-col">
                                                        <label>Invoice Number<span className="text-danger">*</span></label>
                                                        <input type="text" className="form-control"
                                                            name="invoice_no"
                                                            onChange={handleInvoiceForm}
                                                            value={invoiceDetails?.invoice_no || ''}
                                                            placeholder="Enter Invoice No."
                                                            maxLength="255"
                                                        />
                                                        {error && !invoiceDetails?.invoice_no && (
                                                            <span style={{ color: "red" }} className="invalidInput" >
                                                                Required*
                                                            </span>
                                                        )}
                                                    </div>
                                                    <div className="col-sm-6 dash-form-col">
                                                        <label>Invoice Date<span className="text-danger">*</span></label>
                                                        <input className="form-control" type="date"
                                                            value={invoiceDetails?.date || ''}
                                                            onChange={handleInvoiceForm}
                                                            name="date"
                                                            placeholder="Select Date"
                                                        />
                                                        {error && !invoiceDetails?.date && (
                                                            <span style={{ color: "red" }} className="invalidInput" >
                                                                Required*
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 dash-form-col">
                                                <label>Payment Term<span className="text-danger">*</span></label>
                                                <CreatableSelect
                                                    isClearable
                                                    // options={termsList.map((data, index) => {
                                                    //     return {
                                                    //         label: data.name,
                                                    //         value: data.id,
                                                    //     };
                                                    // })}
                                                    value={[findById(createOptionsArray(termsList, 'name', 'id'), selectedTerm)]}
                                                    options={createOptionsArray(termsList, 'name', 'id')}
                                                    onChange={(e) => handleInvoiceDetails(e, 'terms')}
                                                    onInputChange={(inputValue) => inputValue.length > 30 ? inputValue.substring(0, 30) : inputValue}
                                                    placeholder="Select Type"
                                                />
                                                {error && !selectedTerm && (
                                                    <span style={{ color: "red" }} className="invalidInput" >
                                                        Required*
                                                    </span>
                                                )}
                                            </div>
                                            <div className="col-sm-6 dash-form-col">
                                                <label>Buyer<span className="text-danger">*</span></label>
                                                <div className="add-m">
                                                    <Select
                                                        isClearable
                                                        value={[findById(createOptionsArray(buyersList, 'name', 'id'), selectedBuyer)]}
                                                        options={createOptionsArray(buyersList, 'name', 'id')}
                                                        onChange={(e) => handleInvoiceDetails(e, 'buyer')}
                                                        placeholder="Select Buyer"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-6 dash-form-col">
                                                <label>Seller<span className="text-danger">*</span></label>
                                                <div className="add-m">
                                                    <Select
                                                        isClearable
                                                        value={[findById(createOptionsArray(sellersList, 'name', 'id'), selectedSeller)]}
                                                        options={createOptionsArray(sellersList, 'name', 'id')}
                                                        placeholder="Select Seller"
                                                        onChange={(e) => handleInvoiceDetails(e, 'seller')}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-6 dash-form-col">
                                                <label>Commission %</label>
                                                <input className="form-control" type="text" id="commission" name="commission"
                                                    readOnly={isSpecialCommissionChecked ? false : true}
                                                    style={isSpecialCommissionChecked ? {} : {opacity: "0.5"}}
                                                    value={invoiceDetails?.commission || ''}
                                                    onChange={handleInvoiceForm}
                                                    placeholder="0.00%" 
                                                    min="0"/>
                                            </div>
                                            <div className="col-sm-6 dash-form-col">
                                                <label>&nbsp;</label>
                                                <div className="form-check">
                                                    {/* <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="special_commission"/> */}
                                                    <input
                                                        name="special_commission"
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="inlineCheckbox1"
                                                        value={1}  // Set the value to "1"
                                                        checked={isSpecialCommissionChecked}
                                                        onChange={(e) => handleInvoiceDetails(e, 'specialCommission')}
                                                    />
                                                    <label className="form-check-label" htmlFor="inlineCheckbox1">Special Commission</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="table-responsive dash-table box-shd">
                                    <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                        <thead>
                                            <tr>
                                                <th>Edit</th>
                                                <th>DELETE</th>
                                                <th>ORDER NO.</th>
                                                <th>ITEM NO.</th>
                                                <th>BARCODE</th>
                                                <th>BASIC PRICE($)</th>
                                                <th>OVER PRICE US($)</th>
                                                <th>Price US($)</th>
                                                <th>ORDER QUANTITY(DZ)</th>
                                                <th>SHIPPED QUANTITY</th>
                                                <th>NET AMOUNT</th>
                                                <th>AMOUNT</th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-nowrap">
                                        {(selectedItemArray.length > 0 ) ?
                                            <>
                                                {selectedItemArray.map((item, index) => {
                                                    const net_amount = (item.basic_price) * (item.shipped_quantity);
                                                    return (
                                                        <tr key={index}>
                                                            <td><Link onClick={() => editSelectedItem(index)}><i className="fa-solid fa-pencil"></i></Link></td>
                                                            <td><Link onClick={() => removeItem(index)}><i className="fa-solid fa-trash-can-xmark"></i></Link></td>                                               
                                                            <td>{!(item?.order_id) ? '-' : (orders.find(orderData => orderData.id === item?.order_id)?.order_no || item?.order_no)}</td>
                                                            {/* <td>{!(item?.item_id) ? '-' : (items.find(itemData => itemData.item_id === item?.item_id)?.item_no || item?.item_no)}</td> */}
                                                            <td>{(item.item_name) ?(item.item_name) : '-'}</td>
                                                            <td>{(item.barcode) ?? '-'}</td>
                                                            <td>{(item.basic_price) ?? '-'}</td>
                                                            <td>{(item.over_price) ?? '-'}</td>
                                                            <td>{(item.price) ?? '-'}</td>
                                                            <td>{(item.quantity) ?? '-'}</td>
                                                            <td>{(item.shipped_quantity) ? (item.shipped_quantity) : '-'}</td>
                                                            <td>{'$'+parseFloat(net_amount).toFixed(2)}</td>
                                                            <td>{(item.amount) ? '$'+(item.amount) :'-'}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </>
                                        : (
                                            <tr>
                                                <td colSpan={11} align={'center'}> No selected items </td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="box-shd">
                                    <div className="box-head">
                                        <h4>Total Costing</h4>
                                    </div>
                                    <div className="box-body">
                                        <div className="row">
                                            <div className="col-sm-4 dash-form-col">
                                                <label>Credit Note/Adjustments US ($)</label>
                                                <input className="form-control" type="text" id="credit_note" name="credit_note"
                                                    value={invoiceDetails?.credit_note || ''}
                                                    onChange={handleInvoiceForm}
                                                    placeholder="$0.00"  
                                                    min="0"/>
                                            </div>
                                            <div className="col-sm-4 dash-form-col">
                                                <label>Invoice Total US ($)</label>
                                                <input type="text" value={(invoiceTotal) ? parseFloat((invoiceTotal) - (invoiceDetails.credit_note ? invoiceDetails.credit_note : 0)).toFixed(2) : '-'} className="form-control" readOnly placeholder="$0.00"/>
                                            </div>
                                            {/* <div className="col-sm-4 dash-form-col">
                                                <label>Fright Charge US ($)</label>
                                                <input type="text" className="form-control" placeholder="$0.00"/>
                                            </div> */}
                                            <div className="col-sm-4 dash-form-col">
                                                <label>Net Amount</label>
                                                <input type="text" value={(totalNetAmount) ? parseFloat(totalNetAmount).toFixed(2) : '-'} className="form-control" readOnly placeholder="$0.00"/>
                                            </div>
                                            <div className="col-sm-4 dash-form-col">
                                                <label>Total Quantity Shipped($)</label>
                                                <input type="text" value={totalShippedQuantity || ''} className="form-control" readOnly placeholder="-"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="dash-btn-group">
                                    <button className="cus-btn">Save</button>
                                    <a href={"javascript:void(0)"} className="cus-btn border-btn" onClick={() => navigate(-1)}>Cancel</a>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="col-lg-4">
                    <div className="box-shd">
                        <div className="box-head">
                            <h4>Item Information</h4>
                        </div>
                        <div className="box-body">
                            <form  onSubmit={handleItemSubmit} onKeyDown={(e) => { if (e.key === 'Enter') e.preventDefault(); }}>
                                <div className="row">
                                    <div className="col-sm-6 dash-form-col">
                                        <label>Order Number<span className="text-danger">*</span></label>
                                        <Select
                                            isClearable
                                            value={[findById(createOptionsArray(orders, 'order_no', 'id'), selectedOrder)]}
                                            options={createOptionsArray(orders, 'order_no', 'id')}
                                            onChange={(e) => handleItemDetails(e, 'orderNo')}
                                            placeholder="Select Order"
                                        />
                                        {itemError && !selectedOrder && (
                                            <span style={{ color: "red" }} className="invalidInput" >
                                                Required*
                                            </span>
                                        )}
                                    </div>
                                    <div className="col-sm-6 dash-form-col">
                                        <label>Item Number</label>
                                        <Select
                                            isClearable
                                            value={[findById(createOptionsArray(items, 'item_no', 'id', 'item_id'), selectedOrderItem)]}
                                            options={createOptionsArray(items, 'item_no', 'id', "item_id")}
                                            onChange={(e) => handleItemDetails(e, 'itemNo')}
                                            placeholder="Select Item"
                                        />
                                    </div>
                                    <div className="col-sm-6 dash-form-col">
                                        <label>Bar Code</label>
                                        <input className="form-control" type="number" id="barcode" name="barcode" style={{opacity: "0.5"}} readOnly
                                                value={itemDetails.barcode || ''}
                                                placeholder="-" />
                                    </div>
                                    <div className="col-sm-6 dash-form-col">
                                        <label>Basic Price</label>
                                        <input className="form-control" type="number" id="basicPrice" name="basicPrice" style={{opacity: "0.5"}} readOnly
                                                value={itemDetails.basicPrice || ''}
                                                placeholder="-" />
                                    </div>
                                    <div className="col-sm-6 dash-form-col">
                                        <label>Over Price</label>
                                        <input className="form-control" type="number" id="overPrice" name="overPrice" style={{opacity: "0.5"}} readOnly
                                                value={itemDetails.overPrice || ''}
                                                placeholder="-" />
                                    </div>
                                    <div className="col-sm-6 dash-form-col">
                                        <label>Price</label>
                                        <input className="form-control" type="number" id="price" name="price" style={{opacity: "0.5"}} readOnly
                                                value={itemDetails.price || ''}
                                                placeholder="-" />
                                    </div>
                                    <div className="col-sm-4 dash-form-col">
                                        <label>Shipped Qty (DZ)</label>
                                        <input className="form-control" type="text" id="price" name="price"
                                                value={shippedQty || ''}
                                                // onChange={(e) => setShippedQty(e.target.value)}
                                                // onChange={(e) => {
                                                //     const value = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
                                                //     setShippedQty(value.slice(0, 5)); // Limit to 5 digits
                                                // }}
                                                onChange={(e) => {
                                                    // Regular expression to match 5 digits or decimal values with up to 2 decimal places
                                                    const regex = /^\d{0,5}(\.\d{0,2})?$/;
                                                    if (regex.test(e.target.value.replace(/[^\d.]/g, ''))) {
                                                        setShippedQty(e.target.value.replace(/[^\d.]/g, ''));
                                                    }
                                                }}
                                                placeholder="0" />
                                    </div>
                                    <div className="col-sm-4 dash-form-col">
                                        <label>Pending Qty (DZ)</label>
                                        <input className="form-control" type="text" style={{opacity: "0.5"}} readOnly
                                                value={pendingQty || ''}
                                                placeholder="0" />
                                    </div>
                                    <div className="col-sm-4 dash-form-col">
                                        <label>Item Quantity</label>
                                        <input className="form-control" type="text" style={{opacity: "0.5"}} readOnly
                                                value={itemDetails.quantity || ''}
                                                placeholder="0" />
                                    </div>
                                    <div className="col-sm-6 dash-form-col">
                                        <label>Size</label>
                                        <input className="form-control" readOnly style={{opacity: "0.5"}} value={!(itemSize) ? '-' : (sizesList.find(size => size.id === itemSize)?.name || itemSize)}></input>
                                    </div>
                                    <div className="col-sm-6 dash-form-col">
                                        <label>Assortment</label>
                                        <input className="form-control" readOnly style={{opacity: "0.5"}} value={!(itemAssortment) ? '-' : (assortmentsList.find(assortment => assortment.id === itemAssortment)?.name || itemAssortment)}></input>
                                    </div>
                                    <div className="col-sm-6 dash-form-col">
                                        <label>For</label>
                                        <input className="form-control" readOnly style={{opacity: "0.5"}} value={!(itemFor) ? '-' : (forList.find(forr => forr.id === itemFor)?.name || itemFor)}></input>
                                    </div>
                                    <div className="col-sm-6 dash-form-col">
                                        <label>Category</label>
                                        <input className="form-control" readOnly style={{opacity: "0.5"}} value={!(itemCategory) ? '-' : (categoriesList.find(category => category.id === itemCategory)?.name || itemStyle)}></input>
                                    </div>
                                    <div className="col-sm-6 dash-form-col">
                                        <label>Fabric</label>
                                        <input className="form-control" readOnly style={{opacity: "0.5"}} value={!(itemFabrics) ? '-' : (fabricsList.find(fabrics => fabrics.id === itemFabrics)?.name || itemFabrics)}></input>
                                    </div>
                                    <div className="col-sm-6 dash-form-col">
                                        <label>Brand</label>
                                        <input className="form-control" readOnly style={{opacity: "0.5"}} value={!(itemBrand) ? '-' : (brandsList.find(brands => brands.id === itemBrand)?.name || itemBrand)}></input>
                                    </div>
                                    <div className="col-sm-6 dash-form-col">
                                        <label>Color</label>
                                        <input className="form-control" type="text" id="color" name="color" style={{opacity: "0.5"}} readOnly
                                            value={itemDetails.color || ''}
                                            placeholder="-" />
                                    </div>
                                    <div className="col-sm-6 dash-form-col">
                                        <label>Wash</label>
                                        <input className="form-control" type="text" id="wash" name="wash" style={{opacity: "0.5"}} readOnly
                                                value={itemDetails.wash || ''}
                                                placeholder="-" />
                                    </div>
                                    <div className="col-sm-6 dash-form-col">
                                        <label>Amount US($)</label>
                                        <input type="text" className="form-control" style={{opacity: "0.5"}} readOnly
                                            value={amount || ''}
                                            placeholder="-"/>
                                    </div>
                                    <div className="col-sm-6 dash-form-col">
                                        <label>Extra Info</label>
                                        <input type="text" className="form-control" style={{opacity: "0.5"}} readOnly
                                            value={itemDetails.extra || ''}
                                            placeholder="..."/>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="dash-btn-group">
                                            <button type="submit" className="cus-btn">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
};
        
export default InvoiceUpdate;
