import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import moment from "moment";
import $ from 'jquery';
import { orderStatusReport } from "../../../actions/reportAction";

const OrderStatusReport = () =>{

    const dispatch = useDispatch();
    const [ordersList,setOrdersList] = useState([]);

    useEffect(()=>{
        dispatch(orderStatusReport());
    },[dispatch])

    const orderStatusDtl = useSelector((state) => state.orderStatusReport.orderStatusReport)
    useEffect(() => {
        if (orderStatusDtl && orderStatusDtl?.data && orderStatusDtl?.data?.status === true) {
            setOrdersList(orderStatusDtl?.data?.data);
        }
        if (orderStatusDtl && orderStatusDtl?.data && orderStatusDtl?.data?.status === false) {
            toast.error(orderStatusDtl?.data?.message,{theme: "dark",});
        }
    }, [orderStatusDtl]);


    // impelement datatable from jquery
    useEffect(() => {
        ordersList && ordersList.length > 0 && $(document).ready(function () {
            $('#myTable').DataTable({
                bDestroy: true, 
                pageLength: 10
            });
        });
    }, [ordersList])

    return(
        <>
            <div className="dash-body">
                <div className="head-row">
                    <h3>Order Status Report</h3>
                </div>
                <div className="table-responsive dash-table data_tableUs">
                    <table id="myTable" width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <thead>
                            <tr>
                                <th>Report</th>
                                <th>Order Name</th>
                                <th>Date</th>
                                <th>Buyer</th>
                                <th>Seller</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                        {(ordersList.length > 0) ? (
                            <>
                                {ordersList.map((order, index) => (
                                    <tr key={index}>
                                        <td><Link to={`/report/order-status-view/${order.id}`}><i className="fa-solid fa-file-lines"></i></Link></td>
                                        <td>{(order?.order_no) ? order?.order_no : '-'}</td>
                                        <td>{(order?.order_date) ? moment(order?.order_date).format('DD-MMM-YYYY') : '-'}</td>
                                        <td>{(order?.buyers?.name) ? order?.buyers?.name : '-'}</td>
                                        <td>{(order?.sellers?.name) ? order?.sellers?.name : '-'}</td>
                                        <td>{(order?.order_pending > 0) ? <div className="txt-pend">Pending</div> : (order?.order_pending !== 'NA') ? <div className="txt-comp">Complete</div> : '--'}</td>
                                    </tr>
                                ))}
                                </>
                            ): (
                                <tr>
                                    <td colSpan={10} align={'center'}> No recent order status </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default OrderStatusReport;