import { buyerListConstants, createBuyerConstants, viewBuyerConstants, updateBuyerConstants, clearBuyerMessages, deleteBuyerConstants } from "../constants/buyer.constant";

/************************** Reducer For Buyers *******************************/
export const buyerListReducer = (state = { getBuyerList: [] }, action) => {
    switch (action.type) {
        case buyerListConstants.GET_BUYERLIST_SUCCESS:
            return { getBuyerList: action.payload };
        case buyerListConstants.GET_BUYERLIST_FAILURE:
            return { error: action.payload };
        case clearBuyerMessages.CLEAR_BUYER_MESSAGE:
            return { getBuyerList: [], error: "" }
        default:
            return state;
    }
};

export const createBuyerReducer = (state = { createBuyer: [] }, action) => {
    switch (action.type) {
        case createBuyerConstants.GET_CREATEBUYER_SUCCESS:
            return { createBuyer: action.payload };
        case createBuyerConstants.GET_CREATEBUYER_FAILURE:
            return { error: action.payload };
        case clearBuyerMessages.CLEAR_BUYER_MESSAGE:
            return { createBuyer: [], error: "" }
        default:
            return state;
    }
};

export const viewBuyerReducer = (state = { viewBuyer: [] }, action) => {
    switch (action.type) {
        case viewBuyerConstants.GET_VIEWBUYER_SUCCESS:
            return { viewBuyer: action.payload };
        case viewBuyerConstants.GET_VIEWBUYER_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
};

export const updateBuyerReducer = (state = { updateBuyer: [] }, action) => {
    switch (action.type) {
        case updateBuyerConstants.GET_UPDATEBUYER_SUCCESS:
            return { updateBuyer: action.payload };
        case updateBuyerConstants.GET_UPDATEBUYER_FAILURE:
            return { error: action.payload };
        case clearBuyerMessages.CLEAR_BUYER_MESSAGE:
            return { updateBuyer: [], error: "" }
        default:
            return state;
    }
};

export const deleteBuyerReducer = (state = { deleteBuyer: [] }, action) => {
    switch (action.type) {
        case deleteBuyerConstants.GET_DELETEBUYER_SUCCESS:
            return { deleteBuyer: action.payload };
        case deleteBuyerConstants.GET_DELETEBUYER_FAILURE:
            return { error: action.payload };
        case clearBuyerMessages.CLEAR_BUYER_MESSAGE:
            return { deleteBuyer: [], error: "" }
        default:
            return state;
    }
};