import axios from "../services/axios";
import { sellerListConstants, createSellerConstants, viewSellerConstants, updateSellerConstants, clearSellerMessages, deleteSellerConstants } from "../constants/seller.constant";

// action for call the get all the sellers list
export const getSellers = () => {
  return (dispatch) => {
    axios.get("/seller/list").then((response) => {
        if (response.data.hasOwnProperty("error")) {
          alert(response.data.error);
        } else {
            dispatch({ type: sellerListConstants.GET_SELLERLIST_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({type: sellerListConstants.GET_SELLERLIST_FAILURE,payload: err.message,});
        console.log(err);
      });
  };
};

// action for call the create seller API
export const createSeller = (formData) => {
  return (dispatch) => {
    axios.post("/seller/create", formData).then((response) => {
        if (response.data.hasOwnProperty("error")) {
          alert(response.data.error);
        } else {
            dispatch({ type: createSellerConstants.GET_CREATESELLER_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({type: createSellerConstants.GET_CREATESELLER_FAILURE,payload: err.message,});
        console.log(err);
      });
  };
};

// action for call the seller view information
export const viewSeller = (id) => {
  return (dispatch) => {
    axios.get(`/seller/view/${id}`).then((response) => {
        if (response.data.hasOwnProperty("error")) {
          alert(response.data.error);
        } else {
            dispatch({ type: viewSellerConstants.GET_VIEWSELLER_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({type: viewSellerConstants.GET_VIEWSELLER_FAILURE,payload: err.message,});
        console.log(err);
      });
  };
};

// action for call the seller details update API
export const updateSeller = (formData) => {
  return (dispatch) => {
    axios.post("/seller/update", formData).then((response) => {
        if (response.data.hasOwnProperty("error")) {
          alert(response.data.error);
        } else {
            dispatch({ type: updateSellerConstants.GET_UPDATESELLER_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({type: updateSellerConstants.GET_UPDATESELLER_FAILURE,payload: err.message,});
        console.log(err);
      });
  };
};

// action for clear the state of set messages
export const clearSellerMsg = () => async (dispatch) => {
  try {
      dispatch({ type: clearSellerMessages.CLEAR_SELLER_MESSAGE, payload: [] })
  } catch (err) {
      console.log(`Error while calling login api ${err}`);
  }
}

// action for call the seller delete API
export const deleteSeller = (id) => {
  return (dispatch) => {
    axios.get("/seller/delete/"+id).then((response) => {
        if (response.data.hasOwnProperty("error")) {
          alert(response.data.error);
        } else {
            dispatch({ type: deleteSellerConstants.GET_DELETESELLER_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({type: deleteSellerConstants.GET_DELETESELLER_FAILURE,payload: err.message,});
        console.log(err);
      });
  };
};