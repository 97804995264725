import { countriesConstants } from "../constants/common.constant";

/************************** Reducer For Common API's *******************************/
export const countriesReducer = (state = { countries: [] }, action) => {
    switch (action.type) {
        case countriesConstants.GET_COUNTRIES_SUCCESS:
            return { countries: action.payload };
        case countriesConstants.GET_COUNTRIES_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
};