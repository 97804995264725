import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import {Link} from "react-router-dom";
import { toast } from 'react-toastify';
import { getItemProp, createItem, clearItemMsg } from "../../actions/itemAction";
import { getBuyers } from "../../actions/buyerAction";
import { getSellers } from "../../actions/sellerAction";


const ItemCreate = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const [buyersList, setBuyersList] = useState([]);
    const [sellersList, setSellersList] = useState([]);

    const [error, setError] = useState(false);
    const [assortmentsList, setAssortmentsList] = useState([]);
    const [brandsList, setBrandsList] = useState([]);
    const [categoriesList, setCategorieslist] = useState([]);
    const [forList, setForlist] = useState([]);
    const [fabricsList, setFabricslist] = useState([]);
    const [sizesList, setSizeslist] = useState([]);
    const [stylesList, setStyleslist] = useState([]);

    const [selectedBuyer, setSelectedBuyer] = useState("");
    const [selectedSeller, setSelectedSeller] = useState("");
    const [selectedFor, setSelectedFor] = useState("");
    const [selectedSize, setSelectedSize] = useState("");
    const [selectedAssortment, setSelectedAssortment] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedFabrics, setSelectedFabrics] = useState("");
    const [selectedBrand, setSelectedBrand] = useState("");
    const [selectedStyle, setSelectedStyle] = useState("");
    const [priceValue, setPriceValue] = useState("");
    const [selllerOverPrice, setSellerOverPrice] = useState("");

    //manage state for item
    const [itemDetails, setItemDetails] = useState({
        id:"", item_no:"", date:new Date().toISOString().substr(0, 10), wash:"", color:"", extra:"", barcode:"", basicPrice:"", overPrice:"", price:""
    });

    // useEffect for call Api's
    useEffect(() => {
        dispatch(getItemProp());
        dispatch(getBuyers());
        dispatch(getSellers());
    }, [dispatch]);

    //get all the buyers and sellers list from reducer and set here
    const getBuyerList = useSelector((state) => state.getBuyerList.getBuyerList)
    const getSellerList = useSelector((state) => state.getSellerList.getSellerList)
    useEffect(() => {
        if (getBuyerList && getBuyerList?.data && getBuyerList?.data?.status === true) {
            setBuyersList(getBuyerList?.data?.data);
        }
        if (getSellerList && getSellerList?.data && getSellerList?.data?.status === true) {
            setSellersList(getSellerList?.data?.data);
        }
    }, [getBuyerList,getSellerList]);


    // get the all item properties list from reducer and set in state
    const itemProperties = useSelector((state) => state.getItemProp.getItemProp)
    useEffect(() => {
        if (itemProperties && itemProperties?.data && itemProperties?.data?.status === true) {
            setAssortmentsList(itemProperties?.data?.data?.assortments);
            setBrandsList(itemProperties?.data?.data.brands);
            setCategorieslist(itemProperties?.data?.data.categories);
            setForlist(itemProperties?.data?.data.for);
            setFabricslist(itemProperties?.data?.data.fabrics);
            setSizeslist(itemProperties?.data?.data.sizes);
            setStyleslist(itemProperties?.data?.data.styles);
        }
    }, [itemProperties]);

    // Update the state when we change the value
    const handleItemFormDetails = (e) => {
        // Validate barcode input against regex pattern
        if (e.target.name === 'barcode' && !/^\d{0,13}$/.test(e.target.value)) {
            return;
        }
        // Check if the input value is a valid number with at most 9 digits
        if ((e.target.name === 'basicPrice' || e.target.name === 'overPrice') && !/^\d{0,7}(\.\d{0,2})?$/.test(e.target.value)) {
            return;
        }
        setItemDetails({
            ...itemDetails, 
            [e.target.name]: e.target.value
        })
    }

    //set Price value of perticular item
    useEffect(()=>{
        if(itemDetails.basicPrice && itemDetails.overPrice){
            setPriceValue(parseFloat(parseFloat(itemDetails.basicPrice) + parseFloat(itemDetails.overPrice)).toFixed(2));
        }else if(itemDetails.basicPrice === ''){
            itemDetails.basicPrice = 0;
            setPriceValue(parseFloat(parseFloat(itemDetails.basicPrice) + parseFloat(itemDetails.overPrice)).toFixed(2));
        }else if(itemDetails.overPrice === ''){
            itemDetails.overPrice = 0;
            setPriceValue(parseFloat(parseFloat(itemDetails.basicPrice) + parseFloat(itemDetails.overPrice)).toFixed(2));
        }else{
            setPriceValue(parseFloat(itemDetails.basicPrice));
        }
    },[itemDetails.basicPrice,itemDetails.overPrice])

    // set item properties in state
    const handleForDetails = (e, type) => {
        if(type === 'buyer'){
            setSelectedBuyer(e ? e.value : "");
        }else if(type === 'seller'){
            setSelectedSeller(e ? e.value : "");
            setItemDetails({...itemDetails, overPrice:""});
            setSellerOverPrice(e ? e.op : "");
        }else if(type === 'for'){
            setSelectedFor(e ? e.value : "");
        }else if(type === 'size'){
            setSelectedSize(e ? e.value : "");
        }else if(type === 'assortment'){
            setSelectedAssortment(e ? e.value : "");
        }else if(type === 'category'){
            setSelectedCategory(e ? e.value : "");
        }else if(type === 'fabrics'){
            setSelectedFabrics(e ? e.value : "");
        }else if(type === 'brand'){
            setSelectedBrand(e ? e.value : "");
        }else if(type === 'style'){
            setSelectedStyle(e ? e.value : "");
        }   
    }

    // function to create item
    const handleItemSubmit = async (e) => {
        e.preventDefault();
        if (!itemDetails.item_no || !itemDetails?.basicPrice) {
            setError(true);
            return false;
        } else {
            dispatch(
                createItem({
                    item_no     : itemDetails?.item_no,
                    date        : itemDetails?.date,
                    buyer_id    : selectedBuyer,
                    seller_id   : selectedSeller,
                    for         : selectedFor,
                    size        : selectedSize,
                    assortment  : selectedAssortment,
                    category    : selectedCategory,
                    fabrics     : selectedFabrics,
                    brand       : selectedBrand,
                    style       : selectedStyle,
                    color       : itemDetails?.color,
                    wash       : itemDetails?.wash,
                    extra       : itemDetails?.extra,
                    barcode     : itemDetails?.barcode,
                    basic_price : itemDetails?.basicPrice, 
                    over_price  : itemDetails?.overPrice, 
                    price       : priceValue
                })
            );
        }
    };

    //select data from reducer and set toaster here
    const createItemDtl = useSelector((state) => state.createItem.createItem)
    useEffect(() => {
        if (createItemDtl && createItemDtl?.data && createItemDtl?.data?.status === true) {
            navigate("/items")
            toast.success(createItemDtl?.data?.message,{theme: "dark",});
            dispatch(clearItemMsg());
        }
        if (createItemDtl && createItemDtl?.data && createItemDtl?.data?.status === false) {
            toast.error(createItemDtl?.data?.message,{theme: "dark",});
            dispatch(clearItemMsg());
        }
    }, [createItemDtl,navigate,dispatch]);

    return (
        <>
        <div className="dash-body">
            <div className="head-row">
            <h3>Add Item</h3>
            </div>
            <form onSubmit={handleItemSubmit}>
                <div className="box-body box-shd">
                    <div className="row">
                        <div className="col-sm-6 col-lg-3 dash-form-col">
                            <label>Item Number<span className="text-danger">*</span></label>
                            <input className="form-control" type="text" id="item_no" name="item_no"
                                value={itemDetails.item_no || ''}
                                onChange={handleItemFormDetails}
                                placeholder="Enter Item No." 
                                maxLength="50"
                                />
                            {error && !itemDetails?.item_no && (
                                <span style={{ color: "red" }} className="invalidInput" >
                                    Required*
                                </span>
                            )}
                        </div>
                        <div className="col-sm-6 col-lg-3 dash-form-col">
                            <label>Date</label>
                            <input className="form-control" type="date"
                                value={itemDetails.date || ''}
                                onChange={handleItemFormDetails}
                                name="date"
                                placeholder="Enter Date"
                            />
                        </div>
                        <div className="col-sm-6 col-lg-3 dash-form-col">
                            <label>Buyer</label>
                            <Select
                                isClearable
                                options={buyersList.map((data, index) => {
                                    return {
                                        label: data.name,
                                        value: data.id,
                                    };
                                })}
                                onChange={(e) => handleForDetails(e, 'buyer')}
                                placeholder="Select Buyer"
                            />
                        </div>
                        <div className="col-sm-6 col-lg-3 dash-form-col">
                            <label>Seller</label>
                            <Select
                                isClearable
                                options={sellersList.map((data, index) => {
                                    return {
                                        label: data.name,
                                        value: data.id,
                                        op : data.over_price
                                    };
                                })}
                                placeholder="Select Seller"
                                onChange={(e) => handleForDetails(e, 'seller')}
                            />
                        </div>
                        <div className="col-sm-6 col-lg-3 dash-form-col">
                            <label>For</label>
                            <CreatableSelect
                                isClearable
                                options={forList.map((data, index) => {
                                    return {
                                        label: data.name,
                                        value: data.id,
                                    };
                                })}
                                onChange={(e) => handleForDetails(e, 'for')}
                                placeholder="Select Type"
                                onInputChange={(inputValue) => inputValue.length > 250 ? inputValue.substring(0, 250) : inputValue}
                            />
                        </div>
                        <div className="col-sm-6 col-lg-3 dash-form-col">
                            <label>Size</label>
                            <CreatableSelect
                                isClearable
                                options={sizesList.map((data, index) => {
                                    return {
                                        label: data.name,
                                        value: data.id,
                                    };
                                })}
                                placeholder="Select Size"
                                onChange={(e) => handleForDetails(e, 'size')}
                                onInputChange={(inputValue) => inputValue.length > 250 ? inputValue.substring(0, 250) : inputValue}
                            />
                        </div>
                        <div className="col-sm-6 col-lg-3 dash-form-col">
                            <label>Assortment</label>
                            <CreatableSelect
                                isClearable
                                options={assortmentsList.map((data, index) => {
                                    return {
                                        label: data.name,
                                        value: data.id,
                                    };
                                })}
                                placeholder="Select Assortment"
                                onChange={(e) => handleForDetails(e, 'assortment')}
                                onInputChange={(inputValue) => inputValue.length > 250 ? inputValue.substring(0, 250) : inputValue}
                            />
                        </div>
                        <div className="col-sm-6 col-lg-3 dash-form-col">
                            <label>Category</label>
                            <CreatableSelect
                                isClearable
                                options={categoriesList.map((data, index) => {
                                    return {
                                        label: data.name,
                                        value: data.id,
                                    };
                                })}
                                placeholder="Select Catgeory"
                                onChange={(e) => handleForDetails(e, 'category')}
                                onInputChange={(inputValue) => inputValue.length > 250 ? inputValue.substring(0, 250) : inputValue}
                            />
                        </div>
                        <div className="col-sm-6 col-lg-3 dash-form-col">
                            <label>Fabric</label>
                            <CreatableSelect
                                isClearable
                                options={fabricsList.map((data, index) => {
                                    return {
                                        label: data.name,
                                        value: data.id,
                                    };
                                })}
                                placeholder="Select Fabric"
                                onChange={(e) => handleForDetails(e, 'fabrics')}
                                onInputChange={(inputValue) => inputValue.length > 250 ? inputValue.substring(0, 250) : inputValue}
                            />
                        </div>
                        <div className="col-sm-6 col-lg-3 dash-form-col">
                            <label>Wash</label>
                            <input className="form-control" type="text" id="wash" 
                                name="wash"
                                value={itemDetails.wash || ''}
                                onChange={handleItemFormDetails}
                                placeholder="Enter Wash"
                                maxLength="255" 
                            />
                        </div>
                        <div className="col-sm-6 col-lg-3 dash-form-col">
                            <label>Color</label>
                            <input className="form-control" type="text"
                                value={itemDetails.color || ''}
                                onChange={handleItemFormDetails}
                                name="color"
                                placeholder="Enter Color"
                                maxLength="255"
                            />
                        </div>
                        <div className="col-sm-6 col-lg-3 dash-form-col">
                            <label>Barcode</label>
                            <input className="form-control" type="number" id="barcode" name="barcode"
                                value={itemDetails.barcode || ''}
                                onChange={handleItemFormDetails}
                                placeholder="Enter Bar Code" 
                            />
                        </div>
                        <div className="col-sm-6 col-lg-3 dash-form-col">
                            <label>Brand</label>
                            <CreatableSelect
                                isClearable
                                options={brandsList.map((data, index) => {
                                    return {
                                        label: data.name,
                                        value: data.id,
                                    };
                                })}
                                placeholder="Select Brand"
                                onChange={(e) => handleForDetails(e, 'brand')}
                                onInputChange={(inputValue) => inputValue.length > 250 ? inputValue.substring(0, 250) : inputValue}
                            />
                        </div>
                        <div className="col-sm-6 col-lg-3 dash-form-col">
                            <label>Style</label>
                            <CreatableSelect
                                isClearable
                                options={stylesList.map((data, index) => {
                                    return {
                                        label: data.name,
                                        value: data.id,
                                    };
                                })}
                                placeholder="Select Style"
                                onChange={(e) => handleForDetails(e, 'style')}
                                onInputChange={(inputValue) => inputValue.length > 250 ? inputValue.substring(0, 250) : inputValue}
                            />
                        </div>
                        <div className="col-sm-6 col-lg-6 dash-form-col">
                            <label>Extra</label>
                            <textarea className="form-control" type="text" id="extra" name="extra"
                                value={itemDetails.extra || ''}
                                onChange={handleItemFormDetails}
                                placeholder="Type Decription..."
                                rows={1}
                            />
                        </div>
                    </div>
                </div>

                <div className="box-body box-shd">
                    <div className="row">   
                        <div className="col-sm-6 col-lg-3 dash-form-col">
                            <label>Basic Price US($)<span className="text-danger">*</span></label>
                            <div className="dash-f">
                                <input className="form-control" type="integer" id="basicPrice" name="basicPrice"
                                    value={itemDetails.basicPrice || ''}
                                    onChange={handleItemFormDetails}
                                    placeholder="$0.00"
                                    min="0" />
                                <span className="form-control f-h"aria-readonly>H</span>
                            </div>
                            {error && !itemDetails?.basicPrice && (
                                <span style={{ color: "red" }} className="invalidInput" >
                                    Required*
                                </span>
                            )}
                        </div>
                        <div className="col-sm-6 col-lg-3 dash-form-col">
                            <label>Over Price US($)</label>
                            <input className="form-control" type="integer"
                                name="overPrice"
                                readOnly={selllerOverPrice ? false : true}
                                style={selllerOverPrice ? {} : {opacity: "0.5"}}
                                value={itemDetails.overPrice || ''}
                                onChange={handleItemFormDetails}
                                placeholder="$0.00"
                                min="0"
                            />
                        </div>
                        <div className="col-sm-6 col-lg-3 dash-form-col">
                            <label>Price US($)</label>
                            <input className="form-control" type="number" id="price" name="price" readOnly
                                value={(priceValue) ? priceValue : 0}
                                // onChange={handleItemFormDetails}
                                placeholder="$0.00" 
                            />
                        </div>
                    </div>
                    <div className="dash-btn-group mt-2">
                        <button type="submit" className="cus-btn" data-bs-toggle="modal" data-bs-target="#exampleModal">Save</button>
                        <Link to="/items"><button className="cus-btn">Cancel</button></Link>
                    </div>
                </div>
            </form>
        </div>
        </>
    );
};

export default ItemCreate;
