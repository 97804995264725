import { 
    viewOrderConstants,
    orderReportConstants,
    orderByIdsConstants, 
    createOrderConstants, 
    updateOrderConstants, 
    orderListConstants, 
    // orderByIdsConstants, 
    deleteOrderConstants, 
    clearOrderMessages 
} from "../constants/order.constant";

/************************** Reducer For Orders *******************************/

export const orderListReducer = (state = { getOrderList: [] }, action) => {
    switch (action.type) {
        case orderListConstants.GET_ORDERLIST_SUCCESS:
            return { getOrderList: action.payload };
        case orderListConstants.GET_ORDERLIST_FAILURE:
            return { error: action.payload };
        case clearOrderMessages.CLEAR_ORDER_MESSAGE:
            return { getOrderList: [], error: "" }
        default:
            return state;
    }
};

export const orderByIdsReducer = (state = { getOrderById: [] }, action) => {
    switch (action.type) {
        case orderByIdsConstants.GET_ORDERBYIDS_SUCCESS:
            return { getOrderById: action.payload };
        case orderByIdsConstants.GET_ORDERBYIDS_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
};

export const viewOrderReducer = (state = { viewOrder: [] }, action) => {
    switch (action.type) {
        case viewOrderConstants.GET_VIEWORDER_SUCCESS:
            return { viewOrder: action.payload };
        case viewOrderConstants.GET_VIEWORDER_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
};

export const orderReportReducer = (state = { orderReport: [] }, action) => {
    switch (action.type) {
        case orderReportConstants.GET_ORDERREPORT_SUCCESS:
            return { orderReport: action.payload };
        case orderReportConstants.GET_ORDERREPORT_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
};

export const createOrderReducer = (state = { createOrder: [] }, action) => {
    switch (action.type) {
        case createOrderConstants.GET_CREATEORDER_SUCCESS:
            return { createOrder: action.payload };
        case createOrderConstants.GET_CREATEORDER_FAILURE:
            return { error: action.payload };
        case clearOrderMessages.CLEAR_ORDER_MESSAGE:
            return { createOrder: [], error: "" }
        default:
            return state;
    }
};


export const updateOrderReducer = (state = { updateOrder: [] }, action) => {
    switch (action.type) {
        case updateOrderConstants.GET_UPDATEORDER_SUCCESS:
            return { updateOrder: action.payload };
        case updateOrderConstants.GET_UPDATEORDER_FAILURE:
            return { error: action.payload };
        case clearOrderMessages.CLEAR_ORDER_MESSAGE:
            return { updateOrder: [], error: "" }
        default:
            return state;
    }
};

export const deleteOrderReducer = (state = { deleteOrder: [] }, action) => {
    switch (action.type) {
        case deleteOrderConstants.GET_DELETEORDER_SUCCESS:
            return { deleteOrder: action.payload };
        case deleteOrderConstants.GET_DELETEORDER_FAILURE:
            return { error: action.payload };
        case clearOrderMessages.CLEAR_ORDER_MESSAGE:
            return { deleteOrder: [], error: "" }
        default:
            return state;
    }
};