import { itemPropConstants, viewItemConstants, createItemConstants, updateItemConstants, createUpdateItemConstants, itemListConstants,
     itemByIdsConstants, itemByOrderIdConstants, itemByOrderItemIdConstants, deleteItemConstants, clearItemMessages } from "../constants/item.constant";

/************************** Reducer For Items *******************************/
export const itemPropReducer = (state = { getItemProp: [] }, action) => {
    switch (action.type) {
        case itemPropConstants.GET_ITEMPROP_SUCCESS:
            return { getItemProp: action.payload };
        case itemPropConstants.GET_ITEMPROP_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
};

export const itemListReducer = (state = { getItemList: [] }, action) => {
    switch (action.type) {
        case itemListConstants.GET_ITEMLIST_SUCCESS:
            return { getItemList: action.payload };
        case itemListConstants.GET_ITEMLIST_FAILURE:
            return { error: action.payload };
        case clearItemMessages.CLEAR_ITEM_MESSAGE:
            return { getItemList: [], error: "" }
        default:
            return state;
    }
};

export const itemByIdsReducer = (state = { getItemById: [] }, action) => {
    switch (action.type) {
        case itemByIdsConstants.GET_ITEMBYIDS_SUCCESS:
            return { getItemById: action.payload };
        case itemByIdsConstants.GET_ITEMBYIDS_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
};

export const itemByOrderIdReducer = (state = { getItemByOrderId: [] }, action) => {
    switch (action.type) {
        case itemByOrderIdConstants.GET_ITEMBYORDERID_SUCCESS:
            return { getItemByOrderId: action.payload };
        case itemByOrderIdConstants.GET_ITEMBYORDERID_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
};

export const itemByOrderItemIdReducer = (state = { getItemByOrderItemId: [] }, action) => {
    switch (action.type) {
        case itemByOrderItemIdConstants.GET_ITEMBYORDERITEMID_SUCCESS:
            return { getItemByOrderItemId: action.payload };
        case itemByOrderItemIdConstants.GET_ITEMBYORDERITEMID_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
};

export const createItemReducer = (state = { createItem: [] }, action) => {
    switch (action.type) {
        case createItemConstants.GET_CREATEITEM_SUCCESS:
            return { createItem: action.payload };
        case createItemConstants.GET_CREATEITEM_FAILURE:
            return { error: action.payload };
        case clearItemMessages.CLEAR_ITEM_MESSAGE:
            return { createItem: [], error: "" }
        default:
            return state;
    }
};


export const viewItemReducer = (state = { viewItem: [] }, action) => {
    switch (action.type) {
        case viewItemConstants.GET_VIEWITEM_SUCCESS:
            return { viewItem: action.payload };
        case viewItemConstants.GET_VIEWITEM_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
};

export const updateItemReducer = (state = { updateItem: [] }, action) => {
    switch (action.type) {
        case updateItemConstants.GET_UPDATEITEM_SUCCESS:
            return { updateItem: action.payload };
        case updateItemConstants.GET_UPDATEITEM_FAILURE:
            return { error: action.payload };
        case clearItemMessages.CLEAR_ITEM_MESSAGE:
            return { updateItem: [], error: "" }
        default:
            return state;
    }
};

export const createUpdateItemReducer = (state = { createUpdateItem: [] }, action) => {
    switch (action.type) {
        case createUpdateItemConstants.GET_CREATEUPDATEITEM_SUCCESS:
            return { createUpdateItem: action.payload };
        case createUpdateItemConstants.GET_CREATEUPDATEITEM_FAILURE:
            return { error: action.payload };
        case clearItemMessages.CLEAR_ITEM_MESSAGE:
            return { createUpdateItem: [], error: "" }
        default:
            return state;
    }
};

export const deleteItemReducer = (state = { deleteItem: [] }, action) => {
    switch (action.type) {
        case deleteItemConstants.GET_DELETEITEM_SUCCESS:
            return { deleteItem: action.payload };
        case deleteItemConstants.GET_DELETEITEM_FAILURE:
            return { error: action.payload };
        case clearItemMessages.CLEAR_ITEM_MESSAGE:
            return { deleteItem: [], error: "" }
        default:
            return state;
    }
};