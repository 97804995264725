import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import { createUser, clearUserMsg } from "../../actions/userAction";


const UserCreate = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [error, setError] = useState(false);
    const [showPassword, setShowPassword] = useState("");

    const [userDetails, setUserDetails] = useState({
        id:"", name: "", role: "", email: "", password: ""
    });


    // Update the state when we change the value
    const handleUserFormDetails = (e) => {
        setUserDetails({
            ...userDetails, 
            [e.target.name]: e.target.value
        })
    }

    // hide or show password
    const togglePasswordVisibility = (type) => {
        setShowPassword(!showPassword);
    };
    
    // function to create user
    const userSubmit = async (e) => {
        e.preventDefault();
        if (!userDetails.name || !userDetails.role || !userDetails.email || !userDetails.password ) {
            setError(true);
            return false;
        } else {
            dispatch(
                createUser({
                    name        : userDetails?.name,
                    role        : userDetails?.role,
                    email       : userDetails?.email,
                    password    : userDetails?.password,
                })
            );
        }
    };

    //get the user list data from selector
    const createUserDtl = useSelector((state) => state.createUser.createUser)
    useEffect(() => {
        if (createUserDtl && createUserDtl?.data && createUserDtl?.data?.status === true) {
            navigate("/users")
            toast.success(createUserDtl?.data?.message,{theme: "dark",});
            dispatch(clearUserMsg());
        }
        if (createUserDtl && createUserDtl?.data && createUserDtl?.data?.status === false) {
            toast.error(createUserDtl?.data?.message,{theme: "dark",});
        }
    }, [createUserDtl,navigate,dispatch]);

    return (
        <>
            <div className="dash-body">
                <div className="head-row">
                <h3>Add User</h3>
                </div>
                <div className="box-body box-shd">
                    <form onSubmit={userSubmit}>
                        <div className="row">
                            <div className="col-sm-6 col-lg-3 dash-form-col">
                                <label>User</label>
                                <input className="form-control" type="text" id="name" name="name"
                                    value={userDetails.name}
                                    onChange={handleUserFormDetails}
                                    placeholder="Enter User Name" />
                                {error && !userDetails?.name && (
                                    <span style={{ color: "red" }} className="invalidInput" >
                                        Required*
                                    </span>
                                )}
                            </div>

                            <div className="col-sm-6 col-lg-3 dash-form-col">
                                <label>Role</label>
                                <select className="form-control" name="role" onChange={handleUserFormDetails} value={userDetails.role || ''}>
                                    <option value="">-- Select role --</option>
                                        <option value="1">Super Admin</option>
                                        <option value="2">Data Operator</option>
                                </select>
                                {error && !userDetails?.role && (
                                    <span style={{ color: "red" }} className="invalidInput" >
                                        Required*
                                    </span>
                                )}
                            </div>

                            <div className="col-sm-6 col-lg-3 dash-form-col">
                                <label>Email</label>
                                <input className="form-control" type="email" id="email" name="email"
                                    value={userDetails.email}
                                    onChange={handleUserFormDetails}
                                    placeholder="Enter Email" />
                                {error && !userDetails?.email && (
                                    <span style={{ color: "red" }} className="invalidInput" >
                                        Required*
                                    </span>
                                )}
                            </div>

                            <div className="col-sm-6 col-lg-3 dash-form-col">
                                <label>Password</label>
                                <div style={{ position: 'relative' }}>
                                    <input className="form-control" type={showPassword ? 'text' : 'password'} id="password" name="password"
                                        value={userDetails.password}
                                        onChange={handleUserFormDetails}
                                        placeholder="Enter Password" 
                                    />
                                    <button
                                        type="button"
                                        onClick={() => togglePasswordVisibility('new')}
                                        style={{
                                            position: 'absolute',
                                            right: '10px',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            cursor: 'pointer',
                                            border: 'none',
                                            background: 'none',
                                        }}
                                    >
                                        {showPassword ? (
                                            <i className="fa fa-eye-slash" style={{ fontSize: '18px' }}></i>
                                        ) : (
                                            <i className="fa fa-eye" style={{ fontSize: '18px' }}></i>
                                        )}
                                    </button>
                                </div>
                                {error && !userDetails?.password && (
                                    <span style={{ color: "red" }} className="invalidInput" >
                                        Required*
                                    </span>
                                )}
                            </div>

                            <div className="dash-btn-group mt-2">
                                <button type="submit" className="cus-btn">Add</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default UserCreate;
