import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import moment from "moment";
import $ from 'jquery';
// import Swal from 'sweetalert2'
import { getOrders, 
    // deleteOrder, 
    clearOrderMsg } from "../../actions/orderAction";

const OrderList = () =>{

    const dispatch = useDispatch();

    const [ordersList,setOrdersList] = useState([]);

    useEffect(()=>{
        dispatch(getOrders());
    },[dispatch])

    const orderDtl = useSelector((state) => state.getOrderList.getOrderList)
    useEffect(() => {
        if (orderDtl && orderDtl?.data && orderDtl?.data?.status === true) {
            setOrdersList(orderDtl?.data?.data);
        }
        if (orderDtl && orderDtl?.data && orderDtl?.data?.status === false) {
            toast.error(orderDtl?.data?.message,{theme: "dark",});
        }
    }, [orderDtl]);

    // Delete selected order and set updated list
    // const handleDeleteOrder = (id) => {
    //     Swal.fire({
    //         title: "Are you sure?",
    //         text: "You want to delete this Orderr?",
    //         icon: "warning",
    //         confirmButtonText: 'OK',
    //         showCancelButton: true,
    //     }).then(result => {
    //         if (result.isConfirmed) {
    //             dispatch(deleteOrder(id));
    //         }
    //     });
    // };

    //get response of deleted order and update the list
    const deletedOrderDtl = useSelector((state) => state.deleteOrder.deleteOrder)
    useEffect(() => {
        if(deletedOrderDtl && deletedOrderDtl?.data && deletedOrderDtl?.data?.status === true ){
            toast.success(deletedOrderDtl?.data?.message,{theme: "dark",});
            dispatch(getOrders());
            dispatch(clearOrderMsg());
        } 
        else if(deletedOrderDtl && deletedOrderDtl?.data && deletedOrderDtl?.data?.status === false ){
            toast.error(deletedOrderDtl?.data?.message,{theme: "dark",});
        }
    }, [deletedOrderDtl,dispatch]);


    // impelement datatable from jquery
    useEffect(() => {
        ordersList && ordersList.length > 0 && $(document).ready(function () {
            $('#myTable').DataTable({
                bDestroy: true, 
                pageLength: 10
            });
        });
    }, [ordersList])

    return(
        <>
            <div className="dash-body">
                <div className="head-row">
                    <h3>Sales Contract Form</h3>
                    <Link to={'/order/create'} className="cus-btn"><i className="fa-solid fa-circle-plus"></i> Add</Link>
                </div>
                <div className="table-responsive dash-table data_tableUs">
                    <table id="myTable" width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <thead>
                            <tr>
                                <th>Edit</th>
                                <th>Report</th>
                                {/* <th>Delete</th> */}
                                <th>Order Name</th>
                                <th>Date</th>
                                <th>Buyer</th>
                                <th>Seller</th>
                                <th>Total Quantity</th>
                                <th>Total Amount</th>
                                <th>Shipment Date</th>
                            </tr>
                        </thead>
                        <tbody>
                        {(ordersList.length > 0) ? (
                            <>
                                {ordersList.map((order, index) => (
                                    <tr key={index}>
                                        <td><Link to={`/order/update/${order.id}`}><i className="fa-solid fa-pencil"></i></Link></td>
                                        <td><Link to={`/order/order-report/${order.id}`}><i className="fa-solid fa-file-lines"></i></Link></td>
                                        {/* <td><Link onClick={() => {handleDeleteOrder(order.id)}}><i className="fa-solid fa-trash-can-xmark"></i></Link></td> */}
                                        <td>{(order?.order_no) ? order?.order_no : '-'}</td>
                                        <td>{(order?.order_date) ? moment(order?.order_date).format('DD-MMM-YYYY') : '-'}</td>
                                        <td>{(order?.buyers?.name) ? order?.buyers?.name : '-'}</td>
                                        <td>{(order?.sellers?.name) ? order?.sellers?.name : '-'}</td>
                                        <td>{(order?.total_quantity) ? order?.total_quantity : '-'}</td>
                                        <td>{(order?.total_cnf) ? '$'+order?.total_cnf : '-'}</td>
                                        <td>{(order?.shipment_date) ? moment(order?.shipment_date).format('DD-MMM-YYYY') : '-'}</td>
                                    </tr>
                                ))}
                                </>
                            ): (
                                <tr>
                                    <td colSpan={10} align={'center'}> No recent orders available </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default OrderList;