/******************* Constants For Items ******************/
export const itemPropConstants = {
    GET_ITEMPROP_SUCCESS: 'GET_ITEMPROP_SUCCESS',
    GET_ITEMPROP_FAILURE: 'GET_ITEMPROP_FAILURE'
}

export const itemListConstants = {
    GET_ITEMLIST_SUCCESS: 'GET_ITEMLIST_SUCCESS',
    GET_ITEMLIST_FAILURE: 'GET_ITEMLIST_FAILURE'
}

export const itemByIdsConstants = {
    GET_ITEMBYIDS_SUCCESS: 'GET_ITEMBYIDS_SUCCESS',
    GET_ITEMBYIDS_FAILURE: 'GET_ITEMBYIDS_FAILURE',
}

export const itemByOrderIdConstants = {
    GET_ITEMBYORDERID_SUCCESS: 'GET_ITEMBYORDERID_SUCCESS',
    GET_ITEMBYORDERID_FAILURE: 'GET_ITEMBYORDERID_FAILURE',
}

export const itemByOrderItemIdConstants = {
    GET_ITEMBYORDERITEMID_SUCCESS: 'GET_ITEMBYORDERITEMID_SUCCESS',
    GET_ITEMBYORDERITEMID_FAILURE: 'GET_ITEMBYORDERITEMID_FAILURE',
}

export const createItemConstants = {
    GET_CREATEITEM_SUCCESS: 'GET_CREATEITEM_SUCCESS',
    GET_CREATEITEM_FAILURE: 'GET_CREATEITEM_FAILURE'
}

export const viewItemConstants = {
    GET_VIEWITEM_SUCCESS: 'GET_VIEWITEM_SUCCESS',
    GET_VIEWITEM_FAILURE: 'GET_VIEWITEM_FAILURE'
}

export const updateItemConstants = {
    GET_UPDATEITEM_SUCCESS: 'GET_UPDATEITEM_SUCCESS',
    GET_UPDATEITEM_FAILURE: 'GET_UPDATEITEM_FAILURE',
}

export const createUpdateItemConstants = {
    GET_CREATEUPDATEITEM_SUCCESS: 'GET_CREATEUPDATEITEM_SUCCESS',
    GET_CREATEUPDATEITEM_FAILURE: 'GET_CREATEUPDATEITEM_FAILURE',
}

export const deleteItemConstants = {
    GET_DELETEITEM_SUCCESS: 'GET_DELETEITEM_SUCCESS',
    GET_DELETEITEM_FAILURE: 'GET_DELETEITEM_FAILURE'
}

export const clearItemMessages = {
    CLEAR_ITEM_MESSAGE: 'CLEAR_ITEM_MESSAGE'
}