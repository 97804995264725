import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import Swal from 'sweetalert2'
import { useDispatch, useSelector } from "react-redux";
import { getUsers, deleteUser, clearUserMsg } from "../../actions/userAction";

const UserList = (props) => {
    const dispatch = useDispatch();

    const[usersList,setUsersList] = useState([]);
    const[userData,setUserData] = useState("");

    // useEffect for call Api's
    useEffect(() => {
        dispatch(getUsers());
        setUserData(JSON.parse(localStorage.getItem("userData")));
    }, [dispatch]);

    //get all the users list from reducer and set here
    const getUserList = useSelector((state) => state.getUserList.getUserList)
    useEffect(() => {
        if (getUserList && getUserList?.data && getUserList?.data?.status === true) {
            setUsersList(getUserList?.data?.data);
        }
        if (getUserList && getUserList?.data && getUserList?.data?.status === false) {
            toast.error(getUserList?.data?.message,{theme: "dark",});
        }
    }, [getUserList]);

    // Delete selected user and set updated list
    const handleDeleteUser = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to delete this User?",
            icon: "warning",
            confirmButtonText: 'OK',
            showCancelButton: true,
        }).then(result => {
            if (result.isConfirmed) {
                dispatch(deleteUser(id));
            }
        });
    };

    //get response of deleted user and update the list
    const deletedUserDtl = useSelector((state) => state.deleteUser.deleteUser)
    useEffect(() => {
        if(deletedUserDtl && deletedUserDtl?.data && deletedUserDtl?.data?.status === true ){
            toast.success(deletedUserDtl?.data?.message,{theme: "dark",});
            dispatch(getUsers());
            dispatch(clearUserMsg());
        } 
        else if(deletedUserDtl && deletedUserDtl?.data && deletedUserDtl?.data?.status === false ){
            toast.error(deletedUserDtl?.data?.message,{theme: "dark",});
        }
    }, [deletedUserDtl,dispatch]);

    return (
        <>
            <div className="dash-body">
                <div className="head-row">
                    <h3>Users</h3>
                    <Link to={'/user/create'} className="cus-btn"><i className="fa-solid fa-circle-plus"></i> Add New User</Link>
                </div>
                <div className="table-responsive dash-table">
                    <table width="100%" border={0} cellSpacing={0} cellPadding={0}>
                        <thead>
                            <tr>
                                <th>Delete</th>
                                <th>User</th>
                                <th>Role</th>
                                <th>Email</th>
                                <th>Reset Password</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(usersList.length > 0) ? (
                                <>
                                    {usersList.map((user, index) => (
                                        <tr key={user.id}>
                                            {(userData.id !== user.id ) ? 
                                                <td><Link className="listing-btn" onClick={() => {handleDeleteUser(user.id)}}><i className="fa-solid fa-trash-can-xmark"></i></Link></td>
                                            :   <td><i className="fa fa fa-circle text-success" ></i></td> }
                                            <td>{user.name}</td>
                                            <td>{(user.role === 1) ? 'Super Admin' : 'Data Oprateor'}</td>
                                            <td>{(user.email) ? user.email : '-'}</td>
                                            <td>
                                                <Link className="listing-btn" to={`/user/change-password/${user.id}`}>Change Password</Link>&nbsp;
                                            </td>
                                        </tr>
                                    ))}
                                </>
                            ): (
                                <tr>
                                    <td colSpan={7} align={'center'}> No recent users available </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default UserList;
