import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {useParams} from "react-router-dom";
import {Link} from "react-router-dom";
import { toast } from 'react-toastify';
import { clearItemMsg } from "../../actions/itemAction";
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import Modal from "react-modal";
import moment from "moment";
import { viewItem, updateItem, getItemProp } from "../../actions/itemAction";
import { getBuyers } from "../../actions/buyerAction";
import { getSellers } from "../../actions/sellerAction";

const ItemUpdate = (props) => {

    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState(false);

    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            width : "30%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
        },
    };
    const [modalIsOpen, setIsOpen] = useState(false);
    const [buyersList, setBuyersList] = useState([]);
    const [sellersList, setSellersList] = useState([]);
    
    const [assortmentsList, setAssortmentsList] = useState([]);
    const [brandsList, setBrandsList] = useState([]);
    const [categoriesList, setCategorieslist] = useState([]);
    const [forList, setForlist] = useState([]);
    const [fabricsList, setFabricslist] = useState([]);
    const [sizesList, setSizeslist] = useState([]);
    const [stylesList, setStyleslist] = useState([]);

    const [selectedBuyer, setSelectedBuyer] = useState("");
    const [selectedSeller, setSelectedSeller] = useState("");
    const [selectedFor, setSelectedFor] = useState("");
    const [selectedSize, setSelectedSize] = useState("");
    const [selectedAssortment, setSelectedAssortment] = useState("");
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedFabrics, setSelectedFabrics] = useState("");
    const [selectedBrand, setSelectedBrand] = useState("");
    const [selectedStyle, setSelectedStyle] = useState("");
    const [priceValue, setPriceValue] = useState({});
    const [selllerOverPrice, setSellerOverPrice] = useState("");

    const [itemDetails, setItemDetails] = useState({
        id:"", item_no:"", date:new Date().toISOString().substr(0, 10), wash:"", color:"", extra:"", barcode:"", basicPrice:"", overPrice:"", price:"", itemPrice: ""
    });

    // useEffect for call Api's
    useEffect(() => {
        dispatch(viewItem(id));
        dispatch(getItemProp());
        dispatch(getBuyers());
        dispatch(getSellers());
        Modal.setAppElement('#root');
    }, [dispatch,id]);


    //get all the buyers and sellers list from reducer and set here
    const getBuyerList = useSelector((state) => state.getBuyerList.getBuyerList)
    const getSellerList = useSelector((state) => state.getSellerList.getSellerList)
    useEffect(() => {
        if (getBuyerList && getBuyerList?.data && getBuyerList?.data?.status === true) {
            setBuyersList(getBuyerList?.data?.data);
        }
        if (getSellerList && getSellerList?.data && getSellerList?.data?.status === true) {
            setSellersList(getSellerList?.data?.data);
        }
    }, [getBuyerList,getSellerList]);

    // get the countries list from reducer and set here
    const itemProperties = useSelector((state) => state.getItemProp.getItemProp)
    useEffect(() => {
        if (itemProperties && itemProperties?.data && itemProperties?.data?.status === true) {
            setAssortmentsList(itemProperties?.data?.data?.assortments);
            setBrandsList(itemProperties?.data?.data.brands);
            setCategorieslist(itemProperties?.data?.data.categories);
            setForlist(itemProperties?.data?.data.for);
            setFabricslist(itemProperties?.data?.data.fabrics);
            setSizeslist(itemProperties?.data?.data.sizes);
            setStyleslist(itemProperties?.data?.data.styles);
        }
    }, [itemProperties]);

    // get item data via id and set it in state
    const itemDtl = useSelector((state) => state.viewItem.viewItem);
    useEffect(() => {
        if(itemDtl && itemDtl.data && itemDtl.data.status === true ){
            setItemDetails({
                id          : itemDtl?.data?.data?.id, 
                item_no     : itemDtl?.data?.data?.item_no, 
                date        : itemDtl?.data?.data?.date,
                wash        : itemDtl?.data?.data?.wash,
                color       : itemDtl?.data?.data?.color,
                extra       : itemDtl?.data?.data?.extra,
                barcode     : itemDtl?.data?.data?.barcode,
                basicPrice  : itemDtl?.data?.data?.basic_price, 
                overPrice   : itemDtl?.data?.data?.over_price, 
                price       : itemDtl?.data?.data?.price,
                itemPrice  : itemDtl?.data?.data?.item_price
            });
            setSelectedBuyer(itemDtl?.data?.data?.buyer_id);
            setSelectedSeller(itemDtl?.data?.data?.seller_id);
            setSellerOverPrice(itemDtl?.data?.data?.seller_over_price);
            setSelectedFor(itemDtl?.data?.data?.for_id);
            setSelectedSize(itemDtl?.data?.data?.size_id);
            setSelectedAssortment(itemDtl?.data?.data?.assortment_id);
            setSelectedCategory(itemDtl?.data?.data?.category_id);
            setSelectedFabrics(itemDtl?.data?.data?.fabric_id);
            setSelectedBrand(itemDtl?.data?.data?.brand_id);
            setSelectedStyle(itemDtl?.data?.data?.style_id);
        }
    }, [itemDtl]);

    // updating stored value in state
    const handleItemFormDetails = (e) => {
        // Validate barcode input against regex pattern
        if (e.target.name === 'barcode' && !/^\d{0,13}$/.test(e.target.value)) {
            return;
        }
        // Check if the input value is a valid number with at most 7 digits
        if ((e.target.name === 'basicPrice' || e.target.name === 'overPrice') && !/^\d{0,7}(\.\d{0,2})?$/.test(e.target.value)) {
            return;
        }
        setItemDetails({
            ...itemDetails, 
            [e.target.name]: e.target.value
        })
    }

    //set Price value of perticular item
    useEffect(()=>{
        if(itemDetails.basicPrice && itemDetails.overPrice){
            setPriceValue(parseFloat(parseFloat(itemDetails.basicPrice) + parseFloat(itemDetails.overPrice)).toFixed(2));
        }else if(itemDetails.basicPrice === ''){
            itemDetails.basicPrice = 0;
            setPriceValue(parseFloat(parseFloat(itemDetails.basicPrice) + parseFloat(itemDetails.overPrice)).toFixed(2));
        }else if(itemDetails.overPrice === ''){
            itemDetails.overPrice = 0;
            setPriceValue(parseFloat(parseFloat(itemDetails.basicPrice) + parseFloat(itemDetails.overPrice)).toFixed(2));
        }else{
            setPriceValue(parseFloat(itemDetails.basicPrice));
        }
    },[itemDetails.basicPrice,itemDetails.overPrice])

    // when creatable is selected and if the value is not empty and not present in setForlist then push new vlaue and remove all existing created values
    const filterCreatable = (value, list, listState) =>{
        if(value && list.findIndex(option => option.id === value) === -1){
            listState(prevList => prevList.filter(option => typeof option.id !== 'string'));
            listState(prevList => [...prevList, {'id':value,'name':value}]);
        }
    }
    // set item properties in state
    const handleForDetails = (e, type) => {
        let value = e ? e.value : "";
        if(type === 'buyer'){
            setSelectedBuyer(value);
        }else if(type === 'seller'){
            console.log('e.op',e.op);
            setSelectedSeller(value);
            setItemDetails({...itemDetails, overPrice:""});
            setSellerOverPrice(e ? e.op : "");
        }else if(type === 'for'){
            filterCreatable(value, forList, setForlist);
            setSelectedFor(value);
        }else if(type === 'size'){
            filterCreatable(value, sizesList, setSizeslist);
            setSelectedSize(value);
        }else if(type === 'assortment'){
            filterCreatable(value, assortmentsList, setAssortmentsList);
            setSelectedAssortment(value);
        }else if(type === 'category'){
            filterCreatable(value, categoriesList, setCategorieslist);
            setSelectedCategory(value);
        }else if(type === 'fabrics'){
            filterCreatable(value, fabricsList, setFabricslist);
            setSelectedFabrics(value);
        }else if(type === 'brand'){
            filterCreatable(value, brandsList, setBrandsList);
            setSelectedBrand(value);
        }else if(type === 'style'){
            filterCreatable(value, stylesList, setStyleslist);
            setSelectedStyle(value);
        }   
    }

    // function to create item
    const handleUpdateItem = async (e) => {
        e.preventDefault();
        if (!itemDetails.item_no || !itemDetails?.basicPrice) {
            setError(true);
            return false;
        } else {
            dispatch(
                updateItem({       
                    item_id     : itemDetails?.id,
                    item_no     : itemDetails?.item_no,
                    date        : itemDetails?.date,
                    buyer_id    : selectedBuyer,
                    seller_id   : selectedSeller,
                    for         : selectedFor,
                    size        : selectedSize,
                    assortment  : selectedAssortment,
                    category    : selectedCategory,
                    fabrics     : selectedFabrics,
                    brand       : selectedBrand,
                    style       : selectedStyle,
                    wash        : itemDetails?.wash,
                    color       : itemDetails?.color,
                    extra       : itemDetails?.extra,
                    barcode     : itemDetails?.barcode,
                    basic_price : itemDetails?.basicPrice, 
                    over_price  : itemDetails?.overPrice, 
                    price       : priceValue
                })
            );
        }
    };
     
    // return array in lable value pair for each select option 
    const createOptionsArray = (dataArray, labelColumnName, valueColumnName, overPriceColumnName) => {
        return dataArray.map((data, index) => {
            return {
                label: data[labelColumnName],
                value: data[valueColumnName],
                op   : data[overPriceColumnName],
            };
        });
    };
    
    // return lable value index object with selected value in DB
    const findById = (optionsArray, targetId) => {
        return optionsArray.find(option => option.value === targetId);
    };

    //select data from reducer and set toaster here
    const updateItemDtl = useSelector((state) => state.updateItem.updateItem)
    useEffect(() => {
        if (updateItemDtl && updateItemDtl?.data && updateItemDtl?.data?.status === true) {
            navigate("/items")
            toast.success(updateItemDtl?.data?.message,{theme: "dark",});
            dispatch(clearItemMsg());
        }
        if (updateItemDtl && updateItemDtl?.data && updateItemDtl?.data?.status === false) {
            toast.error(updateItemDtl?.data?.message,{theme: "dark",});
        }
    }, [updateItemDtl,navigate,dispatch]);


    // function for open or close modal
    const modalFunnction =(type)=>{
        if(type === 'open') setIsOpen(true); else setIsOpen(false);
    }
    
    return (
        <>
            <div className="dash-body">
                <div className="head-row">
                    <h3>Edit Item Details</h3>
                </div>
                <form onSubmit={handleUpdateItem}>
                    <div className="box-body box-shd">
                        <div className="row">
                            <div className="col-sm-6 col-lg-3 dash-form-col">
                                <label>Item Number<span className="text-danger">*</span></label>
                                <input className="form-control" type="text" id="item_no" name="item_no"
                                    value={itemDetails.item_no || ''}
                                    onChange={handleItemFormDetails}
                                    placeholder="Enter Item Name" 
                                    maxLength="50"
                                />
                                {error && !itemDetails?.item_no && (
                                    <span style={{ color: "red" }} className="invalidInput" >
                                        Required*
                                    </span>
                                )}
                            </div>
                            <div className="col-sm-6 col-lg-3 dash-form-col">
                                <label>Date</label>
                                <input className="form-control" type="date"
                                    value={itemDetails.date || ''}
                                    onChange={handleItemFormDetails}
                                    name="date"
                                    placeholder="Enter Date"
                                />
                            </div>
                            
                            <div className="col-sm-6 col-lg-3 dash-form-col">
                                <label>Buyer</label>
                                <Select
                                    isClearable
                                    value={[findById(createOptionsArray(buyersList, 'name', 'id'), selectedBuyer)]}
                                    options={createOptionsArray(buyersList, 'name', 'id')}
                                    onChange={(e) => handleForDetails(e, 'buyer')}
                                    placeholder="Select Buyer"
                                />
                            </div>
                            <div className="col-sm-6 col-lg-3 dash-form-col">
                                <label>Seller</label>
                                <Select
                                    isClearable
                                    value={[findById(createOptionsArray(sellersList, 'name', 'id', 'over_price'), selectedSeller)]}
                                    options={createOptionsArray(sellersList, 'name', 'id', 'over_price')}
                                    placeholder="Select Seller"
                                    onChange={(e) => handleForDetails(e, 'seller')}
                                />
                            </div> 

                            <div className="col-sm-6 col-lg-3 dash-form-col">
                                <label>For</label>
                                <CreatableSelect
                                    isClearable
                                    value={[findById(createOptionsArray(forList, 'name', 'id'), selectedFor)]}
                                    options={createOptionsArray(forList, 'name', 'id')}
                                    onChange={(e) => handleForDetails(e, 'for')}
                                    placeholder="Select Type"
                                    onInputChange={(inputValue) => inputValue.length > 250 ? inputValue.substring(0, 250) : inputValue}
                                />
                            </div>
                            <div className="col-sm-6 col-lg-3 dash-form-col">
                                <label>Size</label>
                                <CreatableSelect
                                    isClearable
                                    value={[findById(createOptionsArray(sizesList, 'name', 'id'), selectedSize)]}
                                    options={createOptionsArray(sizesList, 'name', 'id')}
                                    placeholder="Select Size"
                                    onChange={(e) => handleForDetails(e, 'size')}
                                    onInputChange={(inputValue) => inputValue.length > 250 ? inputValue.substring(0, 250) : inputValue}
                                />
                            </div>

                            <div className="col-sm-6 col-lg-3 dash-form-col">
                                <label>Assortment</label>
                                <CreatableSelect
                                    isClearable
                                    value={[findById(createOptionsArray(assortmentsList, 'name', 'id'), selectedAssortment)]}
                                    options={createOptionsArray(assortmentsList, 'name', 'id')}
                                    placeholder="Select Assortment"
                                    onChange={(e) => handleForDetails(e, 'assortment')}
                                    onInputChange={(inputValue) => inputValue.length > 250 ? inputValue.substring(0, 250) : inputValue}
                                />
                            </div>
                            <div className="col-sm-6 col-lg-3 dash-form-col">
                                <label>Category</label>
                                <CreatableSelect
                                    isClearable
                                    value={[findById(createOptionsArray(categoriesList, 'name', 'id'), selectedCategory)]}
                                    options={createOptionsArray(categoriesList, 'name', 'id')}
                                    placeholder="Select Catgeory"
                                    onChange={(e) => handleForDetails(e, 'category')}
                                    onInputChange={(inputValue) => inputValue.length > 250 ? inputValue.substring(0, 250) : inputValue}
                                />
                            </div>

                            <div className="col-sm-6 col-lg-3 dash-form-col">
                                <label>Fabric</label>
                                <CreatableSelect
                                    isClearable
                                    value={[findById(createOptionsArray(fabricsList, 'name', 'id'), selectedFabrics)]}
                                    options={createOptionsArray(fabricsList, 'name', 'id')}
                                    placeholder="Select Fabric"
                                    onChange={(e) => handleForDetails(e, 'fabrics')}
                                    onInputChange={(inputValue) => inputValue.length > 250 ? inputValue.substring(0, 250) : inputValue}
                                />
                            </div>
                            <div className="col-sm-6 col-lg-3 dash-form-col">
                                <label>Wash</label>
                                <input className="form-control" type="text" id="wash" 
                                    name="wash"
                                    value={itemDetails.wash || ''}
                                    onChange={handleItemFormDetails}
                                    placeholder="Enter Wash"
                                    maxLength="255"
                                />
                            </div>

                            <div className="col-sm-6 col-lg-3 dash-form-col">
                                <label>Color</label>
                                <input className="form-control" type="text"
                                    value={itemDetails.color || ''}
                                    onChange={handleItemFormDetails}
                                    name="color"
                                    placeholder="Enter Color"
                                    maxLength="255"
                                />
                            </div>
                            <div className="col-sm-6 col-lg-3 dash-form-col">
                                <label>Barcode</label>
                                <input className="form-control" type="number" id="barcode" name="barcode"
                                    value={itemDetails.barcode || ''}
                                    onChange={handleItemFormDetails}
                                    placeholder="Enter Bar Code" 
                                />
                            </div>
                            
                            <div className="col-sm-6 col-lg-3 dash-form-col">
                                <label>Brand</label>
                                <CreatableSelect
                                    isClearable
                                    value={[findById(createOptionsArray(brandsList, 'name', 'id'), selectedBrand)]}
                                    options={createOptionsArray(brandsList, 'name', 'id')}
                                    placeholder="Select Brand"
                                    onChange={(e) => handleForDetails(e, 'brand')}
                                    onInputChange={(inputValue) => inputValue.length > 250 ? inputValue.substring(0, 250) : inputValue}
                                />
                            </div>
                            <div className="col-sm-6 col-lg-3 dash-form-col">
                                <label>Style</label>
                                <CreatableSelect
                                    isClearable
                                    value={[findById(createOptionsArray(stylesList, 'name', 'id'), selectedStyle)]}
                                    options={createOptionsArray(stylesList, 'name', 'id')}
                                    placeholder="Select Style"
                                    onChange={(e) => handleForDetails(e, 'style')}
                                    onInputChange={(inputValue) => inputValue.length > 250 ? inputValue.substring(0, 250) : inputValue}
                                />
                            </div>

                            <div className="col-sm-6 col-lg-6 dash-form-col">
                                <label>Extra</label>
                                <textarea className="form-control" type="text" id="extra" name="extra"
                                    value={itemDetails.extra || ''}
                                    onChange={handleItemFormDetails}
                                    placeholder="Type Decription..."
                                    rows={1}
                                />
                            </div>
                        </div>
                    </div>
                    
                    <div className="box-body box-shd">
                        <div className="row">
                            <div className="col-sm-6 col-lg-3 dash-form-col">
                                <label>Basic Price US($)<span className="text-danger">*</span></label>
                                <div className="dash-f">
                                    <input className="form-control" type="integer" id="basicPrice" name="basicPrice"
                                        value={itemDetails.basicPrice || ''}
                                        onChange={handleItemFormDetails}
                                        placeholder="$0.00" 
                                        min="0" />
                                    <span style={{cursor:'pointer'}} onClick={() => modalFunnction('open')} className="form-control f-h">H</span>
                                </div>
                                {error && !itemDetails?.basicPrice && (
                                    <span style={{ color: "red" }} className="invalidInput" >
                                        Required*
                                    </span>
                                )}
                            </div>
                            <div className="col-sm-6 col-lg-3 dash-form-col">
                                <label>Over Price US($)</label>
                                <input className="form-control" type="integer"
                                    name="overPrice"
                                    readOnly={selllerOverPrice ? false : true}
                                    style={selllerOverPrice ? {} : {opacity: "0.5"}}
                                    value={itemDetails.overPrice || ''}
                                    onChange={handleItemFormDetails}
                                    placeholder="$0.00"
                                    min="0"
                                />
                            </div>
                            <div className="col-sm-6 col-lg-3 dash-form-col">
                                <label>Price US($)</label>
                                <input className="form-control" type="number" id="price" name="price" readOnly
                                    value={priceValue || ''}
                                    // onChange={handleItemFormDetails}
                                    placeholder="$0.00" 
                                />
                            </div>
                            <div className="dash-btn-group mt-2">
                                <button type="submit" className="cus-btn">Update</button>
                                <Link to="/items"><button className="cus-btn">Cancel</button></Link>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            {/* Modal to show Item umpdated Price  */}
            <Modal isOpen={modalIsOpen} onRequestClose={modalFunnction} style={customStyles} contentLabel="Price History">
                <div className="pr-hs">
                    <div style={{ borderBottom: '1px solid #ccc', paddingBottom: '10px', marginBottom: '10px' }} className="d-flex justify-content-between">
                        <h5 className="modal-title" id="exampleModalLabel">Price History</h5>
                        <span className="text-danger close-modal" style={{cursor:'pointer'}} onClick={modalFunnction}>X</span>
                    </div>
                    <table className="table" width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <thead>
                        <tr>
                            <th>Sr No</th>
                            <th>Unit Price</th>
                            <th>Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        {(itemDetails.itemPrice.length > 0) ? 
                            <>
                                {itemDetails.itemPrice.map((data,index)=>(
                                    <tr key={index}>
                                        <td>{index+1}</td>
                                        <td>${data.unit_price}</td>
                                        <td>{moment(data.date).format('DD-MM-YYYY')}</td>
                                    </tr>
                                ))}
                            </>
                        :(
                            <tr>
                                <td colSpan={4} align={'center'}> No recent items available </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </Modal>
        </>
    );
};

export default ItemUpdate;
