/******************* Constants For Reports ******************/
export const orderStatusReportConstants = {
    GET_ORDERSTATUSREPORT_SUCCESS: 'GET_ORDERSTATUSREPORT_SUCCESS',
    GET_ORDERSTATUSREPORT_FAILURE: 'GET_ORDERSTATUSREPORT_FAILURE'
}

export const orderStatusViewConstants = {
    GET_ORDERSTATUSVIEW_SUCCESS: 'GET_ORDERSTATUSVIEW_SUCCESS',
    GET_ORDERSTATUSVIEW_FAILURE: 'GET_ORDERSTATUSVIEW_FAILURE'
}

export const totalPurchaseReportConstants = {
    GET_TOTALPURCHASEREPORT_SUCCESS: 'GET_TOTALPURCHASEREPORT_SUCCESS',
    GET_TOTALPURCHASEREPORT_FAILURE: 'GET_TOTALPURCHASEREPORT_FAILURE'
}

export const commissionReportConstants = {
    GET_COMMISSIONREPORT_SUCCESS: 'GET_COMMISSIONREPORT_SUCCESS',
    GET_COMMISSIONREPORT_FAILURE: 'GET_COMMISSIONREPORT_FAILURE'
}

export const totalSalesReportConstants = {
    GET_TOTALSALESREPORT_SUCCESS: 'GET_TOTALSALESREPORT_SUCCESS',
    GET_TOTALSALESREPORT_FAILURE: 'GET_TOTALSALESREPORT_FAILURE'
}

export const downloadReportXLSConstants = {
    GET_DOWNLOADREPORTXLS_SUCCESS: 'GET_DOWNLOADREPORTXLS_SUCCESS',
    GET_DOWNLOADREPORTXLS_FAILURE: 'GET_DOWNLOADREPORTXLS_FAILURE'
}

export const clearReportDataConstants = {
    CLEAR_REPORT_DATA: 'CLEAR_REPORT_DATA'
}