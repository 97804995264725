import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import { userLogout } from "../../actions/authAction";
import { startGetUser } from "../../actions/userAction";
import Sidebar from "./sidebar";


const Header = () => {
    const dispatch = useDispatch();
    const [userDetails, setUserDetails] = useState("");

    useEffect(() => {
        dispatch(startGetUser());
    },[dispatch]);

    const handleLogout = () => {
        dispatch(userLogout());
    };

    //get all the data from logged in user and set it here
    const userData = useSelector((state) => state.user);
    useEffect(() => {
        const registerDeviceToken = async () => {
            if (userData && userData?.userData && userData?.userData?.data && userData?.userData?.data?.status === true) {
                setUserDetails(userData?.userData?.data?.data);
            }
        };
        registerDeviceToken();
    }, [userData]);

    return (
        <>
            <div className="dashboard">
                <div className="dash-header">
                    <div className="d-header-row">
                        <div className="dropdown">
                            <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <img style={{ height: "40px", width: "40px" }} src="/img/admin.png" alt=""/> {userDetails.name}
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            {(userDetails?.role === 1) ?<li><Link className="dropdown-item" to="/users">Users</Link></li>:''}
                            <li><Link className="dropdown-item"  onClick={handleLogout}>Logout</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <Sidebar/>
                <Outlet />
            </div>
        </>
    );
};

export default Header;
