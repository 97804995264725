import axios from "../services/axios";
import {  invoiceListConstants, 
    createInvoiceConstants, 
    viewInvoiceConstants, 
    // invoiceByIdsConstants, 
    updateInvoiceConstants, 
    deleteInvoiceConstants, 
    clearInvoiceMessages 
} from "../constants/invoice.constant";

export const getInvoices = () => {
  return (dispatch) => {
    axios.get("/invoice/list").then((response) => {
        if (response.data.hasOwnProperty("error")) {
          alert(response.data.error);
        } else {
            dispatch({ type: invoiceListConstants.GET_INVOICELIST_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({type: invoiceListConstants.GET_INVOICELIST_FAILURE,payload: err.message,});
        console.log(err);
      });
  };
};

export const viewInvoice = (id) => {
  return (dispatch) => {
    axios.get(`/invoice/view/${id}`).then((response) => {
        if (response.data.hasOwnProperty("error")) {
          alert(response.data.error);
        } else {
            dispatch({ type: viewInvoiceConstants.GET_VIEWINVOICE_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({type: viewInvoiceConstants.GET_VIEWINVOICE_FAILURE,payload: err.message,});
        console.log(err);
      });
  };
};

export const createInvoice = (formData) => {
  return (dispatch) => {
    axios.post("/invoice/create", formData).then((response) => {
        if (response.data.hasOwnProperty("error")) {
          alert(response.data.error);
        } else {
            dispatch({ type: createInvoiceConstants.GET_CREATEINVOICE_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({type: createInvoiceConstants.GET_CREATEINVOICE_FAILURE,payload: err.message,});
      });
  };
};

export const updateInvoice = (formData) => {
  return (dispatch) => {
    axios.post("/invoice/update", formData).then((response) => {
      if (response.data.hasOwnProperty("error")) {
        alert(response.data.error);
      } else {
          dispatch({ type: updateInvoiceConstants.GET_UPDATEINVOICE_SUCCESS, payload: { data: response.data },});
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch({type: updateInvoiceConstants.GET_UPDATEINVOICE_FAILURE,payload: err.message,});
    });
  };
}

// action for clear the state of set messages
export const clearInvoiceMsg = () => async (dispatch) => {
  try {
      dispatch({ type: clearInvoiceMessages.CLEAR_INVOICE_MESSAGE, payload: [] })
  } catch (err) {
      console.log(`Error while calling login api ${err}`);
  }
}

// action for call the invoice delete API
export const deleteInvoice = (id) => {
  return (dispatch) => {
    axios.post("/invoice/delete/"+id).then((response) => {
      console.log('response.data',response.data);
        if (response.data.hasOwnProperty("error")) {
          alert(response.data.error);
        } else {
            dispatch({ type: deleteInvoiceConstants.GET_DELETEINVOICE_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({type: deleteInvoiceConstants.GET_DELETEINVOICE_FAILURE,payload: err.message,});
        console.log(err);
      });
  };
};
