import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import Swal from 'sweetalert2'
import moment from "moment";
import $ from 'jquery';
import { useDispatch, useSelector } from "react-redux";
import { getItems, clearItemMsg, deleteItem} from "../../actions/itemAction";

const ItemList = (props) => {
    const dispatch = useDispatch();

    const[itemsList,setItemsList] = useState([]);

    // useEffect for call Api's
    useEffect(() => {
        dispatch(getItems());
    }, [dispatch]);

    //get all the items list from reducer and set here
    const getItemList = useSelector((state) => state.getItemList.getItemList)
    useEffect(() => {
        if (getItemList && getItemList?.data && getItemList?.data?.status === true) {
            setItemsList(getItemList?.data?.data);
        }
        if (getItemList && getItemList?.data && getItemList?.data?.status === false) {
            toast.error(getItemList?.data?.message,{theme: "dark",});
        }
    }, [getItemList]);

    // Delete selected item and set updated list
    const handleDeleteItem = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to delete this Item?",
            icon: "warning",
            confirmButtonText: 'OK',
            showCancelButton: true,
        }).then(result => {
            if (result.isConfirmed) {
                dispatch(deleteItem(id));
            }
        });
    };

    //get response of deleted item and update the list
    const deletedItemDtl = useSelector((state) => state.deleteItem.deleteItem)
    useEffect(() => {
        if(deletedItemDtl && deletedItemDtl?.data && deletedItemDtl?.data?.status === true ){
            toast.success(deletedItemDtl?.data?.message,{theme: "dark",});
            if ($.fn.DataTable.isDataTable('#myTable')) {
                $('#myTable').DataTable().destroy(); // Destroy the existing DataTable instance
            }
            dispatch(getItems());
            dispatch(clearItemMsg());
        } 
        else if(deletedItemDtl && deletedItemDtl?.data && deletedItemDtl?.data?.status === false ){
            toast.error(deletedItemDtl?.data?.message,{theme: "dark",});
        }
    }, [deletedItemDtl,dispatch]);


    // impelement datatable from jquery
    useEffect(() => {
        itemsList && itemsList.length > 0 && $(document).ready(function () {
            $('#myTable').DataTable({
                bDestroy: true, 
                pageLength: 10
            });
        });
    }, [itemsList])

    return (
        <>
            <div className="dash-body">
                <div className="head-row">
                    <h3>Items</h3>
                    <Link to={'/item/create'} className="cus-btn"><i className="fa-solid fa-circle-plus"></i>Create New Item </Link>
                </div>
                <div className="table-responsive dash-table data_tableUs">
                    <table id="myTable" width="100%" border={0} cellSpacing={0} cellPadding={0}>
                    <thead>
                        <tr>
                            <th>EDIT</th>
                            <th>DELETE</th>
                            <th>ITEM NO.</th>
                            <th>DATE</th>
                            <th>PRICE</th>
                            <th>FOR</th>
                            <th>CATEGORY</th>
                            <th>BUYER</th>
                            <th>SELLER</th>
                            <th>SIZE</th>
                            <th>BARCODE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(itemsList.length > 0) ? (
                            <>
                                {itemsList.map((item, index) => (
                                    <tr key={item.id}>
                                        <td>
                                            <Link to={`/item/update/${item.id}`}><i className="fa-solid fa-pencil"></i></Link>
                                        </td>
                                        <td>
                                            <Link onClick={() => {handleDeleteItem(item.id)}}><i className="fa-solid fa-trash-can-xmark"></i></Link>
                                        </td>
                                        <td>{item?.item_no}</td>
                                        <td>{item?.date ? moment(item?.date).format('DD-MMM-YYYY') : '-'}</td>
                                        <td>{item?.price ?? '-'}</td>
                                        <td>{item?.item_for?.name ?? '-'}</td>
                                        <td>{item?.item_category?.name ?? '-'}</td>
                                        <td>{item?.buyers?.name ?? '-'}</td>
                                        <td>{item?.sellers?.name ?? '-'}</td>
                                        <td>{item?.item_size?.name ?? '-'}</td>
                                        <td>{item?.barcode ?? '-'}</td>
                                    </tr>
                                ))}
                            </>
                        ): (
                            <tr>
                                <td colSpan={10} align={'center'}> No recent items available </td>
                            </tr>
                        )}
                    </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default ItemList;
