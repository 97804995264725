import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { financialYear, setFinancialYear, clearUserMsg } from "../../actions/userAction";

const FinancialYear = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(financialYear());
    },[dispatch])
    
    const [error, setError] = useState(false);

    const [curFrom_date, setCurFromDate] = useState("");
    const [curTo_date, setCurToDate] = useState("");
    const [newFrom_date, setNewFromDate] = useState("");
    const [newTo_date, setNewToDate] = useState("");

    //get the financial year data from reducer and set in state
    const financialYearDtl = useSelector((state) => state.financialYear.financialYear)
    useEffect(() => {
        if (financialYearDtl && financialYearDtl?.data && financialYearDtl?.data?.status === true) {
            setCurFromDate(financialYearDtl?.data?.data?.from_date)
            setCurToDate(financialYearDtl?.data?.data?.to_date)
            dispatch(clearUserMsg());
            setError(false);
        }
    }, [financialYearDtl,dispatch]);


    // function to create seller
    const yearSubmit = async (e) => {
        e.preventDefault();
        if (!newFrom_date || !newTo_date) {
            setError(true);
            return false;
        } else {
            dispatch(
                setFinancialYear({
                    from_date    : newFrom_date.getFullYear(),
                    to_date      : newTo_date.getFullYear(),
                })
            );
        }
    };
        
    //select data from reducer and set toaster here
    const setFinancialYearDtl = useSelector((state) => state.setFinancialYear.setFinancialYear)
    useEffect(() => {
        if (setFinancialYearDtl && setFinancialYearDtl?.data && setFinancialYearDtl?.data?.status === true) {
            toast.success(setFinancialYearDtl?.data?.message,{theme: "dark",});
            setNewFromDate("");
            setNewToDate("");
            dispatch(financialYear());
            dispatch(clearUserMsg());
        }
        if (setFinancialYearDtl && setFinancialYearDtl?.data && setFinancialYearDtl?.data?.status === false) {
            toast.error(setFinancialYearDtl?.data?.message,{theme: "dark",});
        }
    }, [setFinancialYearDtl,navigate,dispatch]);

    return (
        <>
            <div className="dash-body">
                <div className="head-row">
                    <h3>Set Financial Year</h3>
                </div>
                <div className="box-body box-shd">
                    <h4>Year Information</h4>
                    <div className="row">
                        <form onSubmit={yearSubmit}>
                            <div className="col-sm-6 col-lg-6 dash-form-col">
                                <div className="set-year-bar">
                                    <label>Current Year</label>
                                    <input type="text" className="form-control" placeholder="20.." value={curFrom_date || ''} readOnly/>
                                    <span>/</span>
                                    <input type="text" className="form-control" placeholder="20.." value={curTo_date || ''} readOnly/>
                                </div>
                                <div className="set-year-bar">
                                    <label>Set Year</label>
                                    <DatePicker type="date" className="form-control" 
                                        selected={newFrom_date || null}
                                        onChange={(e) => setNewFromDate(e)} 
                                        showYearPicker
                                        dateFormat="yyyy"
                                        placeholderText="From Year"
                                    />
                                    {error && !newFrom_date && (
                                        <span style={{ color: "red" }} className="invalidInput" >
                                            Required*
                                        </span>
                                    )}
                                    <span>/</span>
                                    
                                    <DatePicker type="date" className="form-control" 
                                        selected={newTo_date || null} 
                                        // value={newFrom_date}
                                        onChange={(e) => setNewToDate(e)} 
                                        showYearPicker
                                        dateFormat="yyyy"
                                        placeholderText="To Year"
                                    />
                                    {error && !newTo_date && (
                                        <span style={{ color: "red" }} className="invalidInput" >
                                            Required*
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div className="dash-btn-group">
                                <button className="cus-btn">Set</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FinancialYear;
