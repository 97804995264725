import React, {useEffect, useState, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
// import {toast} from 'react-toastify';
import moment from "moment";
import Select from 'react-select';
import { getBuyers } from "../../../actions/buyerAction";
import { getSellers } from "../../../actions/sellerAction";
import { commissionReport,clearReportData } from "../../../actions/reportAction";
import CommissionReportPDF from "./commissionReportPDF";
import { PDFDownloadLink, BlobProvider } from '@react-pdf/renderer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

const CommissionReport = () => {

    const dispatch = useDispatch();
	const reportTemplateRef = useRef(null);

    const [error, setError] = useState(false);
    const [flag, setFlag] = useState(false);
    const [buyersList, setBuyersList] = useState([]);
    const [sellersList, setSellersList] = useState([]);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [selectedBuyer, setSelectedBuyer] = useState("All");
    const [selectedSeller, setSelectedSeller] = useState("All");
    
    const [ordersList, setOrdersList] = useState([]);
    
    const [grossAmountTotal,setGrossAmountTotal] = useState(0);
    const [differenceTotal,setDifferenceTotal] = useState(0);
    const [creditNoteTotal,setCreditNoteTotal] = useState(0);
    const [netAmountTotal,setNetAmountTotal] = useState(0);
    // const [commissionTotal,setCommissionTotal] = useState(0);
    const [grossCommissionAmountTotal,setGrossCommissionAmountTotal] = useState(0);
    const [netCommissionAmountTotal,setNetCommissionAmountTotal] = useState(0);

    useEffect(() => {
        // dispatch(
        //     commissionReport({
        //         from_date   : "2024-02-10",
        //         to_date     : "2024-04-20",
        //         buyer       : "All",
        //         seller      : "All",
        //     })
        // );
        dispatch(getBuyers());
        dispatch(getSellers());
        dispatch(clearReportData());
    }, [dispatch])

    // This function adds commas to a number for every thousand separator.
    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    //get all the buyers and sellers list from reducer and set here
    const getBuyerList = useSelector((state) => state.getBuyerList.getBuyerList)
    const getSellerList = useSelector((state) => state.getSellerList.getSellerList)
    useEffect(() => {
        if (getBuyerList && getBuyerList?.data && getBuyerList?.data?.status === true) {
            setBuyersList(getBuyerList?.data?.data);
        }
        if (getSellerList && getSellerList?.data && getSellerList?.data?.status === true) {
            setSellersList(getSellerList?.data?.data);
        }
    }, [getBuyerList,getSellerList]);

    // set item properties in state
    const handleForDetails = (e, type) => {
        if(type === 'buyer'){
            setSelectedBuyer(e ? e.value : "");
        }else if(type === 'seller'){
            setSelectedSeller(e ? e.value : "");
        } 
    }

    // function for submit the props for getting purchase report accrodingly
    const handleReportFunction = async (e) => {
        e.preventDefault();
        if (!fromDate || !toDate || !selectedBuyer || !selectedSeller) {
            setError(true);
            return false;
        } else {
            dispatch(
                commissionReport({
                    from_date   : fromDate,
                    to_date     : toDate,
                    buyer       : selectedBuyer,
                    seller      : selectedSeller,
                })
            );
        }
    }

    const commissionReportDtl = useSelector((state) => state.commissionReport.commissionReport)
    useEffect(() => {
        if (commissionReportDtl && commissionReportDtl?.data && commissionReportDtl?.data?.status === true) {
            setOrdersList(commissionReportDtl?.data?.data);
            setFlag(true);
        }else{
            setOrdersList([]);
            setFlag(false);
        }
    }, [commissionReportDtl]);

    useEffect(() => {
        const totalGrossAmount = ordersList?.reduce((acc, order) => { return acc + (order?.invoice_total ? parseFloat(order.invoice_total) : 0);}, 0);
        setGrossAmountTotal(totalGrossAmount);
        const totalDifference = ordersList?.reduce((acc, order) => { return acc + (parseFloat((order?.invoice_total) - (order?.net_amount)));}, 0);
        setDifferenceTotal(totalDifference);
        const totalCreditNote = ordersList?.reduce((acc, order) => { return acc + (order?.credit_note ? parseFloat(order.credit_note) : 0);}, 0);
        setCreditNoteTotal(totalCreditNote);
        const totalNetAmount = ordersList?.reduce((acc, order) => { return acc + (order?.net_amount ? parseFloat(order.net_amount) : 0);}, 0);
        setNetAmountTotal(totalNetAmount);
        // const totalCommission = ordersList?.reduce((acc, invoice) => { return acc + (invoice.invoice_order?.reduce((acc, order) => {
        //     return acc + (parseFloat((invoice?.commission) ? (invoice?.commission) : ((invoice?.sellers?.commission)?(invoice?.sellers?.commission) : 0))); }, 0) || 0); // Default to 0 in case invoice.invoice_order is 
        // }, 0) || 0;
        // setCommissionTotal(totalCommission);
        const totalGrossCommissionAmount = ordersList?.reduce((acc, order) => { return acc + (parseFloat((order?.net_amount) * ((order?.commission) ? (order?.commission) : ((order?.sellers?.commission) ? (order?.sellers?.commission) : 0)) / 100))}, 0);
        setGrossCommissionAmountTotal(totalGrossCommissionAmount);
        const totalNetCommissionAmount = ordersList?.reduce((acc, order) => { return acc + (parseFloat((order?.net_amount) * ((order?.commission) ? (order?.commission) : ((order?.sellers?.commission) ? (order?.sellers?.commission) : 0)) / 100) + (order?.invoice_total - order?.net_amount))}, 0);
        setNetCommissionAmountTotal(totalNetCommissionAmount);
    }, [ordersList]);

    return (
        <>
            <div className="dash-body">
                <div className="head-row">
                    <h3>Commission Report</h3>
                </div>
                <div className="box-body box-shd">
                    <form onSubmit={handleReportFunction}>
                        <div className="row">
                            <div className="col-sm-6 col-lg-2 dash-form-col">
                                <label>From Date</label>
                                <input type="date" className="form-control" onChange={(e) => setFromDate(e.target.value)} placeholder="Select a date"/>
                                {error && !fromDate && (
                                    <span style={{ color: "red" }} className="invalidInput" >
                                        Required*
                                    </span>
                                )}
                            </div>
                            <div className="col-sm-6 col-lg-2 dash-form-col">
                                <label>To Date</label>
                                <input type="date" className="form-control" onChange={(e) => setToDate(e.target.value)} placeholder="Select a date"/>
                                {error && !toDate && (
                                    <span style={{ color: "red" }} className="invalidInput" >
                                        Required*
                                    </span>
                                )}
                            </div>
                            <div className="col-sm-6 col-lg-4 dash-form-col">
                                <label>Buyer</label>
                                <Select
                                    isClearable
                                    options={[
                                        { label: 'All', value: 'All' }, // Default option for ALL
                                        ...buyersList.map((data, index) => ({
                                            label: data.name,
                                            value: data.id,
                                        })),
                                    ]}
                                    onChange={(e) => handleForDetails(e, 'buyer')}
                                    defaultValue={{ label: 'All', value: 'All' }}
                                />
                            </div>
                            <div className="col-sm-6 col-lg-4 dash-form-col">
                                <label>Seller <span className="text-danger">*</span></label>
                                <Select
                                    isClearable
                                    options={[
                                        { label: 'All', value: 'All' }, // Default option for ALL
                                        ...sellersList.map((data, index) => ({
                                            label: data.name,
                                            value: data.id,
                                        })),
                                    ]}
                                    onChange={(e) => handleForDetails(e, 'seller')}
                                    defaultValue={{ label: 'All', value: 'All' }}
                                />
                                {error && !selectedSeller && (
                                    <span style={{ color: "red" }} className="invalidInput" >
                                        Required*
                                    </span>
                                )}
                            </div>

                            <div className="dash-btn-group mt-2">
                                <button className="cus-btn" type="submit">View</button>
                            </div>
                        </div>
                    </form>
                </div>
            
            {(flag) ?
                <div style={{backgroundColor:"#fff"}} >
                    {/* <PDFViewer style={{width: '100%', height: '100vh' }}>
                        <CommissionReportPDF ordersList={ordersList} grossAmountTotal={grossAmountTotal} creditNoteTotal={creditNoteTotal}
                            netAmountTotal={netAmountTotal} differenceTotal={differenceTotal} grossCommissionAmountTotal={grossCommissionAmountTotal} 
                            netCommissionAmountTotal={netCommissionAmountTotal}
                            buyersList={buyersList} sellersList={sellersList} selectedBuyer={selectedBuyer}
                            selectedSeller={selectedSeller} fromDate={fromDate} toDate={toDate}/>
                    </PDFViewer> */}
                     {(ordersList?.length > 0) &&
                    <div className="align-items-md-center d-flex gap-1 justify-content-end px-2">
                        <BlobProvider document={
                            <CommissionReportPDF ordersList={ordersList} grossAmountTotal={grossAmountTotal} creditNoteTotal={creditNoteTotal}
                            netAmountTotal={netAmountTotal} differenceTotal={differenceTotal} grossCommissionAmountTotal={grossCommissionAmountTotal} 
                            netCommissionAmountTotal={netCommissionAmountTotal}
                            buyersList={buyersList} sellersList={sellersList} selectedBuyer={selectedBuyer}
                            selectedSeller={selectedSeller} fromDate={fromDate} toDate={toDate}/>
                        }>
                            {({ url }) => (
                                <a href={url} target="_blank" rel="noopener noreferrer" title="Preview PDF"> <FontAwesomeIcon icon={faFilePdf} className="icon" /></a>
                            )}
                        </BlobProvider>
                        <PDFDownloadLink
                            document={
                                <CommissionReportPDF ordersList={ordersList} grossAmountTotal={grossAmountTotal} creditNoteTotal={creditNoteTotal}
                                netAmountTotal={netAmountTotal} differenceTotal={differenceTotal} grossCommissionAmountTotal={grossCommissionAmountTotal} 
                                netCommissionAmountTotal={netCommissionAmountTotal}
                                buyersList={buyersList} sellersList={sellersList} selectedBuyer={selectedBuyer}
                                selectedSeller={selectedSeller} fromDate={fromDate} toDate={toDate}/>
                            }
                            style={{padding: '5px 5px 5px 5px', float: 'right' }}
                            fileName="commission-report.pdf"
                            className="MuiButtonBase-root MuiButton-root MuiButton-contained btn-download MuiButton-containedPrimary MuiButton-containedSizeLarge MuiButton-sizeLarge"
                        >
                            {({ blob, url, loading, error }) =>
                                loading ? "Loading document..." : <i className="fa-solid fa-download" title="Download PDF"></i>
                            }
                        </PDFDownloadLink>
                    </div>
                    }
                    <div ref={reportTemplateRef} style={{padding:'4em 4em'}}>
                        <table border="0" cellSpacing="0" cellPadding="0" style={{ fontFamily: 'sans-serif', color: '#000', fontSize: '16px', margin: 'auto', maxWidth: '1650px', width: '100%' }}>
                            <tbody>
                                <tr>
                                    <td>
                                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <table style={{border: "3px solid #000",width: "100%",borderWidth: "2px 5px 5px 2px",}}>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td valign="center">
                                                                                        <img src="../../img/m-logo.png" alt="" style={{ maxWidth: 120, width: '100%'}} />
                                                                                    </td>
                                                                                    <td valign="top" style={{ textAlign: "center" }}>
                                                                                        <table width="100%" border={0} cellSpacing={0} cellPadding={0}>
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <td colSpan={4} style={{ fontSize: 45,fontWeight: 700,textTransform: "uppercase", fontFamily: "ui-rounded" }}>
                                                                                                        MARUTI OVERSEAS CORP.
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td colSpan={4} style={{ fontSize: 20, fontWeight: 700, textTransform: "uppercase", fontFamily: "ui-rounded" }}>
                                                                                                        IMPORTERS &amp; INDENTORS
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td colSpan={4} height="15px" />
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td height="30px" />
                                                                </tr>
                                                                <tr>
                                                                    <td style={{ textAlign: 'center', fontSize: '18px' }}>
                                                                        <div style={{display:'flex', justifyContent:'space-between', marginTop:'10px'}}>
                                                                            <span style={{ fontWeight: 600 }}>
                                                                                Buyer: <span style={{fontWeight:'normal', marginRight:'0.5em'}}>{!(selectedBuyer) ? '-' : (buyersList.find(buyer => buyer.id === selectedBuyer)?.name || selectedBuyer)}</span>  
                                                                                Seller: <span style={{fontWeight:'normal'}}>{!(selectedSeller) ? '-' : (sellersList.find(seller => seller.id === selectedSeller)?.name || selectedSeller)}</span>
                                                                            </span>
                                                                            <span style={{ fontWeight: 600 }}>Account Statment</span>

                                                                            <span style={{ fontWeight: 600 }}>
                                                                                From Date: <span style={{fontWeight:'normal', marginRight:'0.5em'}}>{(fromDate) ? moment(fromDate).format('DD-MMM-YYYY') : '-'}</span>  
                                                                                To Date: <span style={{fontWeight:'normal'}}>{(toDate) ? moment(toDate).format('DD-MMM-YYYY') : '-'}</span>
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td height="50px"></td>
                                </tr>
                                <tr>
                                    <td>
                                        <table width="100%" border="0" cellSpacing="0" cellPadding="0" style={{ borderRadius: '6px', border: '1px solid #bbb' }}>
                                            <thead>
                                                <tr style={{ backgroundColor: '#F8F7FD' }}>
                                                    <th style={{ textAlign: 'center', backgroundColor: '#e6e2e2', padding: '20px' }}>Invoice Date</th>
                                                    <th style={{ textAlign: 'center', backgroundColor: '#e6e2e2', padding: '20px' }}>Invoice Number</th>
                                                    <th style={{ textAlign: 'center', backgroundColor: '#e6e2e2', padding: '20px' }}>Buyer</th>
                                                    <th style={{ textAlign: 'center', backgroundColor: '#e6e2e2', padding: '20px' }}>Gross Amount</th>
                                                    <th style={{ textAlign: 'center', backgroundColor: '#e6e2e2', padding: '20px' }}>Difference US($)</th>
                                                    <th style={{ textAlign: 'center', backgroundColor: '#e6e2e2', padding: '20px' }}>Credit Note</th>
                                                    <th style={{ textAlign: 'center', backgroundColor: '#e6e2e2', padding: '20px' }}>Net Amount US($)</th>
                                                    <th style={{ textAlign: 'center', backgroundColor: '#e6e2e2', padding: '20px' }}>Commission (%)</th>
                                                    <th style={{ textAlign: 'center', backgroundColor: '#e6e2e2', padding: '20px' }}>Gross Commission Amount US($)</th>
                                                    <th style={{ textAlign: 'center', backgroundColor: '#e6e2e2', padding: '20px' }}>Net Commission Amount US($)</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{whiteSpace:"nowrap"}}>
                                                {(ordersList?.length > 0) ? (
                                                    <>
                                                        {ordersList?.map((invoice, index) => {
                                                            // calculate commission ammount behalf of commission or seller 
                                                            const commissionCalc = ((invoice?.net_amount) *
                                                                ((invoice?.commission) ? (invoice?.commission) : ((invoice?.sellers?.commission) ? (invoice?.sellers?.commission) : 0)) / 100)
                                                            // calculate difference ammount 
                                                            const differenceCalc = ((invoice?.invoice_total) - (invoice?.net_amount));
                                                            const netCommAmount = commissionCalc + differenceCalc;
                                                            return (
                                                                <>
                                                                    <tr key={invoice.id}>
                                                                        <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderBottom:'0.5px solid #ccc',borderRight:'0.5px solid #ccc'}}>
                                                                            {(invoice?.invoice_date) ? moment(invoice?.invoice_date).format('DD-MMM-YYYY') : '-'}</td>
                                                                        <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderBottom:'0.5px solid #ccc',borderRight:'0.5px solid #ccc'}}>
                                                                            {(invoice?.invoice_no) ? (invoice?.invoice_no) : '-'}</td>
                                                                        <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderBottom:'0.5px solid #ccc',borderRight:'0.5px solid #ccc'}}>
                                                                            {(invoice?.buyers?.name) ? (invoice?.buyers?.name) : '-'}</td>
                                                                        <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderBottom:'0.5px solid #ccc',borderRight:'0.5px solid #ccc',textAlign:"right"}}>
                                                                            {numberWithCommas((invoice?.invoice_total) ? parseFloat(invoice?.invoice_total).toFixed(2) : '-')}</td>
                                                                        <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderBottom:'0.5px solid #ccc',borderRight:'0.5px solid #ccc',textAlign:"right"}}>
                                                                            {numberWithCommas(parseFloat(differenceCalc).toFixed(2))}</td>
                                                                        <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderBottom:'0.5px solid #ccc',borderRight:'0.5px solid #ccc',textAlign:"right"}}>
                                                                            {numberWithCommas((invoice?.credit_note) ? parseFloat(invoice?.credit_note).toFixed(2) : '-')}</td>
                                                                        <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderBottom:'0.5px solid #ccc',borderRight:'0.5px solid #ccc',textAlign:"right"}}>
                                                                            {numberWithCommas((invoice?.net_amount) ? parseFloat(invoice?.net_amount).toFixed(2) : '-')}</td>
                                                                        <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderBottom:'0.5px solid #ccc',borderRight:'0.5px solid #ccc',textAlign:"right"}}>
                                                                            {numberWithCommas((invoice?.commission) ? (invoice?.commission) : ((invoice?.sellers?.commission)?(invoice?.sellers?.commission) : 0))}</td>
                                                                        <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderBottom:'0.5px solid #ccc',borderRight:'0.5px solid #ccc',textAlign:"right"}}>
                                                                            {numberWithCommas(parseFloat(commissionCalc).toFixed(2))}
                                                                        </td>
                                                                        <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderBottom:'0.5px solid #ccc',borderRight:'0.5px solid #ccc',textAlign:"right"}}>
                                                                            {numberWithCommas(parseFloat(netCommAmount).toFixed(2))}
                                                                        </td>
                                                                    </tr>
                                                                </>
                                                            );
                                                        })}
                                                    </>
                                                ): (
                                                    <tr>
                                                        <td colSpan={12} align={'center'} style={{fontWeight:'700',padding:'1em'}}> No Record Found. </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                            {(ordersList?.length > 0) ? (
                                                <>
                                                    <tfoot>
                                                        <tr>
                                                            <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderTop:'2px solid #000',borderRight:'0.5px solid #ccc',fontWeight: 'bold'}}>Total</td>
                                                            <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderTop:'2px solid #000',borderRight:'0.5px solid #ccc',fontWeight: 'bold'}}>&nbsp;</td>
                                                            <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderTop:'2px solid #000',borderRight:'0.5px solid #ccc',fontWeight: 'bold'}}>&nbsp;</td>
                                                            <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderTop:'2px solid #000',borderRight:'0.5px solid #ccc',fontWeight: 'bold',textAlign:"right"}}>{(grossAmountTotal) ? '$'+numberWithCommas(parseFloat(grossAmountTotal).toFixed(2)) : 0}</td>
                                                            <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderTop:'2px solid #000',borderRight:'0.5px solid #ccc',fontWeight: 'bold',textAlign:"right"}}>{(differenceTotal) ? parseFloat(differenceTotal).toFixed(2) : 0}</td>
                                                            <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderTop:'2px solid #000',borderRight:'0.5px solid #ccc',fontWeight: 'bold',textAlign:"right"}}>{(creditNoteTotal) ? parseFloat(creditNoteTotal).toFixed(2) : 0}</td>
                                                            <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderTop:'2px solid #000',borderRight:'0.5px solid #ccc',fontWeight: 'bold',textAlign:"right"}}>{(netAmountTotal) ? '$'+numberWithCommas(parseFloat(netAmountTotal).toFixed(2)) : 0}</td>
                                                            <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderTop:'2px solid #000',borderRight:'0.5px solid #ccc',fontWeight: 'bold'}}>&nbsp;</td>
                                                            {/* <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderTop:'2px solid #000',borderRight:'0.5px solid #ccc',fontWeight: 'bold'}}>{(commissionTotal) ? '$'+numberWithCommas(commissionTotal) : 0}</td> */}
                                                            <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderTop:'2px solid #000',borderRight:'0.5px solid #ccc',fontWeight: 'bold',textAlign:"right"}}>{(grossCommissionAmountTotal) ? '$'+numberWithCommas(parseFloat(grossCommissionAmountTotal).toFixed(2)) : '0'}</td>
                                                            <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderTop:'2px solid #000',borderRight:'0.5px solid #ccc',fontWeight: 'bold',textAlign:"right"}}>{(netCommissionAmountTotal) ? '$'+numberWithCommas(parseFloat(netCommissionAmountTotal).toFixed(2)) : '0'}</td>
                                                        </tr>
                                                    </tfoot>
                                                </>
                                                ):""}
                                        </table>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            : ""}
            </div>
        </>
    );

}

export default CommissionReport;
