import axios from "../services/axios";
import { countriesConstants } from "../constants/common.constant";

export const countriesAction = (formData) => {
  return (dispatch) => {
    axios.get("/countries-list", formData).then((response) => {
        if (response.data.hasOwnProperty("error")) {
          alert(response.data.error);
        } else {
          if(response?.data?.status === true){
            dispatch({ type: countriesConstants.GET_COUNTRIES_SUCCESS, payload: { data: response.data },});
          }else{
            dispatch({ type: countriesConstants.GET_COUNTRIES_SUCCESS, payload: { data: response.data },});
          }
        }
      })
      .catch((err) => {
        dispatch({type: countriesConstants.GET_COUNTRIES_FAILURE,payload: err.message,});
        console.log(err);
      });
  };
};
