import { 
    viewInvoiceConstants, 
    createInvoiceConstants, 
    updateInvoiceConstants, 
    invoiceListConstants, 
    // invoiceByIdsConstants, 
    deleteInvoiceConstants, 
    clearInvoiceMessages 
} from "../constants/invoice.constant";

/************************** Reducer For Invoices *******************************/

export const invoiceListReducer = (state = { getInvoiceList: [] }, action) => {
    switch (action.type) {
        case invoiceListConstants.GET_INVOICELIST_SUCCESS:
            return { getInvoiceList: action.payload };
        case invoiceListConstants.GET_INVOICELIST_FAILURE:
            return { error: action.payload };
        case clearInvoiceMessages.CLEAR_INVOICE_MESSAGE:
            return { getInvoiceList: [], error: "" }
        default:
            return state;
    }
};

export const createInvoiceReducer = (state = { createInvoice: [] }, action) => {
    switch (action.type) {
        case createInvoiceConstants.GET_CREATEINVOICE_SUCCESS:
            return { createInvoice: action.payload };
        case createInvoiceConstants.GET_CREATEINVOICE_FAILURE:
            return { error: action.payload };
        case clearInvoiceMessages.CLEAR_INVOICE_MESSAGE:
            return { createInvoice: [], error: "" }
        default:
            return state;
    }
};


export const viewInvoiceReducer = (state = { viewInvoice: [] }, action) => {
    switch (action.type) {
        case viewInvoiceConstants.GET_VIEWINVOICE_SUCCESS:
            return { viewInvoice: action.payload };
        case viewInvoiceConstants.GET_VIEWINVOICE_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
};

export const updateInvoiceReducer = (state = { updateInvoice: [] }, action) => {
    switch (action.type) {
        case updateInvoiceConstants.GET_UPDATEINVOICE_SUCCESS:
            return { updateInvoice: action.payload };
        case updateInvoiceConstants.GET_UPDATEINVOICE_FAILURE:
            return { error: action.payload };
        case clearInvoiceMessages.CLEAR_INVOICE_MESSAGE:
            return { updateInvoice: [], error: "" }
        default:
            return state;
    }
};

export const deleteInvoiceReducer = (state = { deleteInvoice: [] }, action) => {
    switch (action.type) {
        case deleteInvoiceConstants.GET_DELETEINVOICE_SUCCESS:
            return { deleteInvoice: action.payload };
        case deleteInvoiceConstants.GET_DELETEINVOICE_FAILURE:
            return { error: action.payload };
        case clearInvoiceMessages.CLEAR_INVOICE_MESSAGE:
            return { deleteInvoice: [], error: "" }
        default:
            return state;
    }
};