import { createStore, combineReducers, applyMiddleware } from "redux";
import {thunk} from "redux-thunk";
import { loginReducer } from "../reducers/authReducer";
import { countriesReducer } from "../reducers/commonReducer"; 
import { dashboardReducer, userReducer, userListReducer, createUserReducer, updateUserReducer, deleteUserReducer, changePasswordReducer, 
        financialYearReducer, setFinancialYearReducer, salesOrderPrefixReducer, setSalesOrderPrefixReducer, recycleBinRecordsReducer, 
        restoreRecordsReducer, deleteRecordPermantReducer } from "../reducers/userReducer";
import { buyerListReducer, createBuyerReducer, viewBuyerReducer, updateBuyerReducer, deleteBuyerReducer } from "../reducers/buyerReducer";
import { sellerListReducer, createSellerReducer, viewSellerReducer, updateSellerReducer, deleteSellerReducer} from "../reducers/sellerReducer";
import { itemPropReducer, itemListReducer, itemByIdsReducer, itemByOrderIdReducer, itemByOrderItemIdReducer,
        viewItemReducer, createItemReducer, updateItemReducer, createUpdateItemReducer, deleteItemReducer } from "../reducers/itemReducer";
import { orderListReducer, orderByIdsReducer, viewOrderReducer, orderReportReducer, createOrderReducer, updateOrderReducer, deleteOrderReducer} from "../reducers/orderReducer";
import { viewInvoiceReducer, invoiceListReducer, createInvoiceReducer, updateInvoiceReducer, deleteInvoiceReducer} from "../reducers/invoiceReducer";
import { orderStatusReportReducer, orderStatusViewReducer, totalPurchaseReportReducer, commissionReportReducer, totalSalesReportReducer, downloadReportXLSReducer } from "../reducers/reportReducer";

const configureStore = () => {
  const store = createStore(
    combineReducers({
      //Auth Reducer
      userLogin     : loginReducer,
      dashboardData : dashboardReducer,
      recycleBinRecords : recycleBinRecordsReducer,
      restoreRecord     : restoreRecordsReducer,
      deleteRecord      : deleteRecordPermantReducer,
      
      //User Reducer
      user                : userReducer,
      getUserList         : userListReducer,
      createUser          : createUserReducer,
      updateUser          : updateUserReducer,
      deleteUser          : deleteUserReducer,
      changePassword      : changePasswordReducer,
      financialYear       : financialYearReducer,
      setFinancialYear    : setFinancialYearReducer,
      salesOrderPrefix    : salesOrderPrefixReducer,
      setSalesOrderPrefix : setSalesOrderPrefixReducer,

      //Common Reducer
      countries: countriesReducer,
      
      //Buyer Reducres
      getBuyerList    : buyerListReducer,
      createBuyer     : createBuyerReducer,
      viewBuyer       : viewBuyerReducer,
      updateBuyer     : updateBuyerReducer,
      deleteBuyer     : deleteBuyerReducer,

      //Seller Reducres
      getSellerList   : sellerListReducer,
      createSeller    : createSellerReducer,
      viewSeller      : viewSellerReducer,
      updateSeller    : updateSellerReducer,
      deleteSeller    : deleteSellerReducer,

      //Item Reducres
      getItemProp     : itemPropReducer,
      getItemList     : itemListReducer,
      getItemById     : itemByIdsReducer,
      getItemByOrderId: itemByOrderIdReducer,
      getItemByOrderItemId: itemByOrderItemIdReducer,
      viewItem        : viewItemReducer,
      updateItem      : updateItemReducer,
      createUpdateItem: createUpdateItemReducer,
      createItem      : createItemReducer,
      deleteItem      : deleteItemReducer,

      //Order Reducres
      getOrderList     : orderListReducer,
      getOrderById     : orderByIdsReducer,
      viewOrder        : viewOrderReducer,
      orderReport      : orderReportReducer,
      updateOrder      : updateOrderReducer,
      createOrder      : createOrderReducer,
      deleteOrder      : deleteOrderReducer,

      //Invoice Reducres
      getInvoiceList     : invoiceListReducer,
      viewInvoice        : viewInvoiceReducer,
      updateInvoice      : updateInvoiceReducer,
      createInvoice      : createInvoiceReducer,
      deleteInvoice      : deleteInvoiceReducer,

      //Report Reducers
      orderStatusReport   : orderStatusReportReducer,
      orderStatusView     : orderStatusViewReducer,
      downloadReportXLS   : downloadReportXLSReducer,

      totalPurchaseReport : totalPurchaseReportReducer,
      commissionReport    : commissionReportReducer,
      totalSalesReport    : totalSalesReportReducer,
      
    }),
    applyMiddleware(thunk)
  );
  return store;
};

export default configureStore;
