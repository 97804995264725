import axios from "../services/axios";
import {  orderListConstants,
    orderByIdsConstants,
    createOrderConstants, 
    viewOrderConstants,
    orderReportConstants, 
    // orderByIdsConstants, 
    updateOrderConstants, 
    deleteOrderConstants, 
    clearOrderMessages 
} from "../constants/order.constant";

export const getOrders = () => {
  return (dispatch) => {
    axios.get("/order/list").then((response) => {
        if (response.data.hasOwnProperty("error")) {
          alert(response.data.error);
        } else {
            dispatch({ type: orderListConstants.GET_ORDERLIST_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({type: orderListConstants.GET_ORDERLIST_FAILURE,payload: err.message,});
        console.log(err);
      });
  };
};

export const orderReport = (id) => {
  return (dispatch) => {
    axios.get(`/order/order-report/${id}`).then((response) => {
        if (response.data.hasOwnProperty("error")) {
          alert(response.data.error);
        } else {
            dispatch({ type: orderReportConstants.GET_ORDERREPORT_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({type: orderReportConstants.GET_ORDERREPORT_FAILURE,payload: err.message,});
        console.log(err);
      });
  };
};

export const getOrderByIds = (formData) => {
  return (dispatch) => {
    axios.post("/order/order-by-ids", formData).then((response) => {
        if (response.data.hasOwnProperty("error")) {
          alert(response.data.error);
        } else {
            dispatch({ type: orderByIdsConstants.GET_ORDERBYIDS_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({type: orderByIdsConstants.GET_ORDERBYIDS_FAILURE,payload: err.message,});
        console.log(err);
      });
  };
};

export const viewOrder = (id) => {
  return (dispatch) => {
    axios.get(`/order/view/${id}`).then((response) => {
        if (response.data.hasOwnProperty("error")) {
          alert(response.data.error);
        } else {
            dispatch({ type: viewOrderConstants.GET_VIEWORDER_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({type: viewOrderConstants.GET_VIEWORDER_FAILURE,payload: err.message,});
        console.log(err);
      });
  };
};

export const createOrder = (formData) => {
  return (dispatch) => {
    axios.post("/order/create", formData).then((response) => {
        if (response.data.hasOwnProperty("error")) {
          alert(response.data.error);
        } else {
            dispatch({ type: createOrderConstants.GET_CREATEORDER_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({type: createOrderConstants.GET_CREATEORDER_FAILURE,payload: err.message,});
      });
  };
};

export const updateOrder = (formData) => {
  return (dispatch) => {
    axios.post("/order/update", formData).then((response) => {
      if (response.data.hasOwnProperty("error")) {
        alert(response.data.error);
      } else {
          dispatch({ type: updateOrderConstants.GET_UPDATEORDER_SUCCESS, payload: { data: response.data },});
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch({type: updateOrderConstants.GET_UPDATEORDER_FAILURE,payload: err.message,});
    });
  };
}

// action for clear the state of set messages
export const clearOrderMsg = () => async (dispatch) => {
  try {
      dispatch({ type: clearOrderMessages.CLEAR_ORDER_MESSAGE, payload: [] })
  } catch (err) {
      console.log(`Error while calling login api ${err}`);
  }
}

// action for call the order delete API
export const deleteOrder = (id) => {
  return (dispatch) => {
    axios.post("/order/delete/"+id).then((response) => {
      console.log('response.data',response.data);
        if (response.data.hasOwnProperty("error")) {
          alert(response.data.error);
        } else {
            dispatch({ type: deleteOrderConstants.GET_DELETEORDER_SUCCESS, payload: { data: response.data },});
        }
      })
      .catch((err) => {
        dispatch({type: deleteOrderConstants.GET_DELETEORDER_FAILURE,payload: err.message,});
        console.log(err);
      });
  };
};
