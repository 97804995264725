import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import {Link, useParams} from "react-router-dom";
import { viewSeller, updateSeller, clearSellerMsg } from "../../actions/sellerAction";
import { countriesAction } from "../../actions/commonActions";


const SellerUpdate = (props) => {

    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState(false);

    const [countryList, setCountryList] = useState([]);
    const [isOverPriceChecked, setIsOverPriceChecked] = useState(false);

    const [sellerDetails, setSellerDetails] = useState({
        id:"", seller_name: "", email: "", address: "", commission: "", city: "", country: "", phone_no: ""
    });

    // useEffect for call Api's
    useEffect(() => {
        dispatch(countriesAction());
        dispatch(viewSeller(id));
    }, [dispatch,id]);

    // get the countries list from reducer and set here
    const countriesList = useSelector((state) => state.countries.countries)
    useEffect(() => {
        if (countriesList && countriesList?.data && countriesList?.data?.status === true) {
            setCountryList(countriesList?.data?.data);
        }
    }, [countriesList]);

    // get seller data via id and set it in state
    const sellerDtl = useSelector((state) => state.viewSeller.viewSeller);
    useEffect(() => {
        if(sellerDtl && sellerDtl.data && sellerDtl.data.status === true ){
            setSellerDetails({
                id: sellerDtl.data.data.id, 
                seller_name: sellerDtl.data.data.name, 
                email: sellerDtl.data.data.email, 
                address: sellerDtl.data.data.address, 
                commission: sellerDtl.data.data.commission, 
                city: sellerDtl.data.data.city, 
                country: sellerDtl.data.data.country, 
                phone_no: sellerDtl.data.data.phone_no
            });
            setIsOverPriceChecked(sellerDtl.data.data.over_price);
        }
    }, [sellerDtl]);

    // updating stored value in state
    const handleSellerFormDetails = (e) => {
        // Check if the input value is a valid number with at most 5 digits
        if (e.target.name === 'commission' && !/^\d{0,5}(\.\d{0,2})?$/.test(e.target.value)) {
            return;
        }
        // Validate phone_no input against regex pattern
        if (e.target.name === 'phone_no' && !/^[0-9\-()+ ]{0,15}$/.test(e.target.value)) {
            return;
        }
        setSellerDetails({
            ...sellerDetails, 
            [e.target.name]: e.target.value
        })
    }

    const handleOverPrice = (e) => {
        setIsOverPriceChecked(!isOverPriceChecked);
    }

    // function to create seller
    const handleUpdateSeller = async (e) => {
        e.preventDefault();
        if (!sellerDetails.seller_name) {
            setError(true);
            return false;
        } else {
            dispatch(
                updateSeller({
                    id          : sellerDetails?.id,
                    name        : sellerDetails?.seller_name,
                    email       : sellerDetails?.email,
                    address     : sellerDetails?.address,
                    commission  : sellerDetails?.commission,
                    over_price  : isOverPriceChecked,
                    city        : sellerDetails?.city,
                    country     : sellerDetails?.country,
                    phone_no    : sellerDetails?.phone_no,
                })
            );
        }
    };

    //select data from reducer and set toaster here
    const updateSellerDtl = useSelector((state) => state.updateSeller.updateSeller)
    useEffect(() => {
        if (updateSellerDtl && updateSellerDtl?.data && updateSellerDtl?.data?.status === true) {
            navigate("/sellers")
            toast.success(updateSellerDtl?.data?.message,{theme: "dark",});
            dispatch(clearSellerMsg());
        }
        if (updateSellerDtl && updateSellerDtl?.data && updateSellerDtl?.data?.status === false) {
            toast.error(updateSellerDtl?.data?.message,{theme: "dark",});
        }
    }, [updateSellerDtl,navigate,dispatch]);

    return (
        <>
            <div className="dash-body">
                <div className="head-row">
                    <h3>Edit Seller Details</h3>
                </div>
                <form onSubmit={handleUpdateSeller}>
                    <div className="box-body box-shd">
                        <div className="row">
                            <div className="col-sm-6 col-lg-4 dash-form-col">
                                <label>Seller Name<span className="text-danger">*</span></label>
                                <input className="form-control" type="text" id="seller_name" 
                                    name="seller_name"
                                    value={sellerDetails.seller_name}
                                    onChange={handleSellerFormDetails}
                                    placeholder="Enter Seller Name"
                                    maxLength="255"
                                     />
                                {error && !sellerDetails?.seller_name && (
                                    <span style={{ color: "red" }} className="invalidInput" >
                                        Required*
                                    </span>
                                )}
                            </div>
                            <div className="col-sm-6 col-lg-4 dash-form-col">
                                <label>Phone No.</label>
                                <input className="form-control" type="text"
                                    value={sellerDetails?.phone_no || ''}
                                    onChange={handleSellerFormDetails}
                                    name="phone_no"
                                    placeholder="Enter No."
                                />
                            </div>
                            <div className="col-sm-6 col-lg-4 dash-form-col">
                                <label>Address</label>
                                <input className="form-control" type="text" id="address" name="address"
                                    value={sellerDetails?.address || ''}
                                    onChange={handleSellerFormDetails}
                                    placeholder="Enter Address"
                                    maxLength="255"
                                />
                            </div>
                            <div className="col-sm-6 col-lg-4 dash-form-col">
                                <label>Email</label>
                                <input className="form-control" type="email"
                                    value={sellerDetails.email || ''}
                                    onChange={handleSellerFormDetails}
                                    name="email"
                                    placeholder="Enter Email"
                                    maxLength="255"
                                />
                            </div>
                            <div className="col-sm-6 col-lg-4 dash-form-col">
                                <label>Country</label>
                                <select className="form-control" onChange={handleSellerFormDetails} name="country" value={sellerDetails.country || ''} >
                                    <option value="">--Select country--</option>
                                    {countryList.map((data,i) => (
                                        <option key={i} value={data.name}>{data.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-sm-6 col-lg-4 dash-form-col">
                                <label>City</label>
                                <input className="form-control"
                                    placeholder="Enter City"
                                    name="city"
                                    value={sellerDetails?.city || ''}
                                    onChange={handleSellerFormDetails}
                                    maxLength="255"
                                />
                            </div>
                            <div className="col-sm-6 col-lg-4 dash-form-col">
                                <label>Commission %</label>
                                <input className="form-control" type="number" step="0.01" id="commission" name="commission"
                                    value={sellerDetails?.commission || ''}
                                    onChange={handleSellerFormDetails}
                                    placeholder="Enter Commission" 
                                    min="0"
                                />
                            </div>
                            <div className="col-sm-6 col-lg-4 dash-form-col">
                                <label>&nbsp;</label>
                                <div className="form-check">
                                    <input
                                        name="over_price"
                                        className="form-check-input"
                                        type="checkbox"
                                        id="inlineCheckbox1"
                                        value={1}  // Set the value to "1"
                                        checked={isOverPriceChecked}
                                        onChange={(e) => handleOverPrice(e)}
                                    />
                                    <label className="form-check-label" htmlFor="inlineCheckbox1">Over Price</label>
                                </div>
                            </div>

                            <div className="dash-btn-group mt-2">
                                <button type="submit" className="cus-btn">Update</button>
                                <Link to="/sellers"><button className="cus-btn">Cancel</button></Link>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default SellerUpdate;
