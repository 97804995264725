import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import {  changePassword, clearUserMsg } from "../../../actions/userAction";

const ChangePassword = () => {
    const id = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [error, setError] = useState(false);

    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showNewPassword, setShowNewPassword] = useState("");
    const [showCurrentPassword, setShowCurrentPassword] = useState("");

    // hide or show password
    const togglePasswordVisibility = (type) => {
        if(type === 'new')
        setShowNewPassword(!showNewPassword);
        if(type === 'current')
        setShowCurrentPassword(!showCurrentPassword);
    };

    // function to create user
    const passwordSubmit = async (e) => {
        e.preventDefault();
        if (!newPassword || !confirmPassword ) {
            setError(true);
            return false;
        } else {
            dispatch(
                changePassword({
                    user_id                : id,
                    newPassword       : newPassword,
                    confirmPassword   : confirmPassword,
                })
            );
        }
    };

    //get the user chnage password response from selector
    const passwordDtl = useSelector((state) => state.changePassword.changePassword);
    useEffect(() => {
        if (passwordDtl && passwordDtl?.data && passwordDtl?.data?.status === true) {
            navigate("/users")
            toast.success(passwordDtl?.data?.message,{theme: "dark",});
            dispatch(clearUserMsg());
        }
        if (passwordDtl && passwordDtl?.data && passwordDtl?.data?.status === false) {
            toast.error(passwordDtl?.data?.message,{theme: "dark",});
        }
    }, [passwordDtl,navigate,dispatch]);

    return (
        <>
            <div className="dash-body">
                <div className="head-row">
                <h3>Change Password</h3>
                </div>
                <div className="box-body box-shd">
                    <form onSubmit={passwordSubmit}>
                        <div className="row">
                            <div className="col-sm-6 col-lg-3 dash-form-col" style={{ position: 'relative' }}>
                                <label>New Password</label>
                                <div style={{ position: 'relative' }}>
                                    <input className="form-control" type={showNewPassword ? 'text' : 'password'} id="newPassword" name="newPassword"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)} 
                                        placeholder="Enter New Password" />
                                    <button
                                        type="button"
                                        onClick={() => {togglePasswordVisibility('new')}}
                                        style={{
                                            position: 'absolute',
                                            right: '10px',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            cursor: 'pointer',
                                            border: 'none',
                                            background: 'none',
                                        }}
                                        >
                                        {showNewPassword ? (
                                            <i className="fa fa-eye-slash" style={{ fontSize: '18px' }}></i>
                                        ) : (
                                            <i className="fa fa-eye" style={{ fontSize: '18px' }}></i>
                                        )}
                                    </button>
                                </div>
                                {error && !newPassword && (
                                    <span style={{ color: "red" }} className="invalidInput" >
                                        Required*
                                    </span>
                                )}
                            </div>


                            <div className="col-sm-6 col-lg-3 dash-form-col" style={{ position: 'relative' }}>
                                <label>Current Password</label>
                                <div style={{ position: 'relative' }}>
                                    <input className="form-control" type={showCurrentPassword ? 'text' : 'password'} id="confirmPassword" name="confirmPassword"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)} 
                                        placeholder="Enter Confirm Password" />
                                    <button
                                        type="button"
                                        onClick={() => {togglePasswordVisibility('current')}}
                                        style={{
                                            position: 'absolute',
                                            right: '10px',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            cursor: 'pointer',
                                            border: 'none',
                                            background: 'none',
                                        }}
                                        >
                                        {showCurrentPassword ? (
                                            <i className="fa fa-eye-slash" style={{ fontSize: '18px' }}></i>
                                        ) : (
                                            <i className="fa fa-eye" style={{ fontSize: '18px' }}></i>
                                        )}
                                    </button>
                                </div>
                                {error && !confirmPassword && (
                                    <span style={{ color: "red" }} className="invalidInput" >
                                        Required*
                                    </span>
                                )}
                            </div>

                            <div className="dash-btn-group mt-2">
                                <button type="submit" className="cus-btn">Change</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ChangePassword;
