import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import moment from "moment";
import Swal from 'sweetalert2'
import { deleteRecordPermanent, recycleBinRecords, restoreRecord, clearUserMsg } from "../../actions/userAction";

const RecycleBin = () => {

    const dispatch = useDispatch();

    const[records,setRecords] = useState("");
    const[selectedType,setSelectedType] = useState('buyer');

    // useEffect for call Api's
    useEffect(() => {
        dispatch(recycleBinRecords({ type : selectedType }));
    }, [dispatch,selectedType]);

    //get all the recycle bin records
    const recordList = useSelector((state) => state.recycleBinRecords.recycleBinRecords)
    useEffect(() => {
        if (recordList && recordList?.data && recordList?.data?.status === true) {
            setRecords(recordList?.data?.data);
        }
        if (recordList && recordList?.data && recordList?.data?.status === false) {
            toast.error(recordList?.data?.message,{theme: "dark",});
        }
    }, [recordList]);

    // check type for get perticular record
    const handleType = (e) =>{
        if(e.target.value){
            setSelectedType(e.target.value);
            dispatch(recycleBinRecords({ type : e.target.value }));
        }
    }

    // restore all the seleted records
    const handleRestoreValue = (action) => {
        Swal.fire({
            title: "Are you sure?",
            text: (action === 'all') ? "You want to restore all the records?" : "You want to restore that record",
            icon: "warning",
            confirmButtonText: 'OK',
            showCancelButton: true,
        }).then(result => {
            if (result.isConfirmed) {
                dispatch(restoreRecord({
                    type    : selectedType,
                    action  : action
                }));
            }
        });
    };
    
    // delete all the seleted records
    const handleDeleteValue = (action) => {
        Swal.fire({
            title: "Are you sure?",
            text: (action === 'all') ? "You want to permanent delete all the records?" : "You want to permanent delete that record",
            icon: "error",
            confirmButtonText: 'OK',
            showCancelButton: true,
        }).then(result => {
            if (result.isConfirmed) {
                dispatch(deleteRecordPermanent({
                    type    : selectedType,
                    action  : action
                }));
            }
        });
    };

    //get response of restore record data
    const restorRecordDtl = useSelector((state) => state.restoreRecord.restoreRecord)
    useEffect(() => {
        if(restorRecordDtl && restorRecordDtl?.data && restorRecordDtl?.data?.status === true ){
            toast.success(restorRecordDtl?.data?.message,{theme: "dark",});
            dispatch(recycleBinRecords({ type : selectedType }));
            dispatch(clearUserMsg());
        } 
        else if(restorRecordDtl && restorRecordDtl?.data && restorRecordDtl?.data?.status === false ){
            toast.error(restorRecordDtl?.data?.message,{theme: "dark",});
        }
    }, [restorRecordDtl,dispatch,selectedType]);

    //get response of deleted records
    const deletedRecordDtl = useSelector((state) => state.deleteRecord.deleteRecord)
    useEffect(() => {
        if(deletedRecordDtl && deletedRecordDtl?.data && deletedRecordDtl?.data?.status === true ){
            toast.success(deletedRecordDtl?.data?.message,{theme: "dark",});
            dispatch(recycleBinRecords({ type : selectedType }));
            dispatch(clearUserMsg());
        } 
        else if(deletedRecordDtl && deletedRecordDtl?.data && deletedRecordDtl?.data?.status === false ){
            toast.error(deletedRecordDtl?.data?.message,{theme: "dark",});
        }
    }, [deletedRecordDtl,dispatch,selectedType]);

    return (
        <>
            <div className="dash-body">
                <div className="head-row">
                    <h3 className="text-danger">Recycle Bin <i className="fa-solid fa-trash-can-xmark"></i></h3>
                    <div className="btn-groups">
                        <select value={selectedType} onChange={handleType} className="form-control form-select" aria-label="Default select example">
                            <option value="buyer">Buyer</option>
                            <option value="seller">Seller</option>
                            <option value="item">Item</option>
                        </select>
                        <Link onClick={() => {handleRestoreValue('all') }} className="cus-btn"><i className="fa-solid fa-rotate-right"></i>Restore All</Link>
                        <Link onClick={() => {handleDeleteValue('all') }}className="cus-btn border-btn"><i className="fa-solid fa-trash-can-xmark"></i>Delete All</Link>
                    </div>

                </div>
                <div className="table-responsive dash-table">
                    {(selectedType === 'buyer') ?
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <thead>
                                <tr>
                                    <th>Restore</th>
                                    <th>Delete</th>
                                    <th>Buyer Name</th>
                                    <th>Address</th>
                                    <th>City</th>
                                    <th>Phone No.</th>
                                    <th>Email</th>
                                    <th>Country</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(records.length > 0) ? (
                                    <>
                                        {records.map((buyer, index) => (
                                            <tr key={buyer.id}>
                                                <td><Link onClick={() => {handleRestoreValue(buyer.id) }}><i className="fa-solid fa-rotate-right"></i></Link></td>
                                                <td><Link onClick={() => {handleDeleteValue(buyer.id) }}> <i className="fa-solid fa-trash-can-xmark"></i></Link></td>
                                                <td>{buyer.name}</td>
                                                <td>{(buyer.address) ? buyer.address : '-'}</td>
                                                <td>{(buyer.city) ? buyer.city : '-'}</td>
                                                <td>{(buyer.phone_no) ? buyer.phone_no : '-'}</td>
                                                <td>{(buyer.email )? buyer.email  : '-'}</td>
                                                <td>{(buyer.country) ? buyer.country : '-'}</td>
                                            </tr>
                                        ))}
                                    </>
                                ): (
                                    <tr>
                                        <td colSpan={8} align={'center'}> No buyers found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    : ''}
                    {(selectedType === 'seller') ?
                        <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                            <thead>
                                <tr>
                                    <th>Restore</th>
                                    <th>Delete</th>
                                    <th>Seller Name</th>
                                    <th>Address</th>
                                    <th>City</th>
                                    <th>Phone No.</th>
                                    <th>Email</th>
                                    <th>Country</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(records.length > 0) ? (
                                    <>
                                        {records.map((seller, index) => (
                                            <tr key={seller.id}>
                                                <td><Link onClick={() => {handleRestoreValue(seller.id) }}><i className="fa-solid fa-rotate-right"></i></Link></td>
                                                <td><Link onClick={() => {handleDeleteValue(seller.id) }}> <i className="fa-solid fa-trash-can-xmark"></i></Link></td>
                                                <td>{seller.name}</td>
                                                <td>{(seller.address) ? seller.address : '-'}</td>
                                                <td>{(seller.city) ? seller.city : '-'}</td>
                                                <td>{(seller.phone_no) ? seller.phone_no : '-'}</td>
                                                <td>{(seller.email )? seller.email  : '-'}</td>
                                                <td>{(seller.country) ? seller.country : '-'}</td>
                                            </tr>
                                        ))}
                                    </>
                                ): (
                                    <tr>
                                        <td colSpan={8} align={'center'}> No sellers found </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    : ''}
                    {(selectedType === 'item') ?
                        <table id="myTable" width="100%" border={0} cellSpacing={0} cellPadding={0}>
                            <thead>
                                <tr>
                                    <th>Restore</th>
                                    <th>Delete</th>
                                    <th>Item No.</th>
                                    <th>Date</th>
                                    <th>Price</th>
                                    <th>Buyer</th>
                                    <th>Seller</th>
                                    <th>Size</th>
                                    <th>Barcode</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(records.length > 0) ? (
                                    <>
                                        {records.map((item, index) => (
                                            <tr key={item.id}>
                                                {console.log('records',records)}            
                                                <td><Link onClick={() => {handleRestoreValue(item.id) }}><i className="fa-solid fa-rotate-right"></i></Link></td>
                                                <td><Link onClick={() => {handleDeleteValue(item.id) }}> <i className="fa-solid fa-trash-can-xmark"></i></Link></td>
                                                <td>{item?.item_no}</td>
                                                <td>{(item?.date) ? moment(item?.date).format('DD-MMM-YYYY') : '-'}</td>
                                                <td>{item?.basic_price ?? '-'}</td>
                                                <td>{item?.buyers?.name ?? '-'}</td>
                                                <td>{item?.sellers?.name ?? '-'}</td>
                                                <td>{item?.item_size?.name ?? '-'}</td>
                                                <td>{item?.barcode ?? '-'}</td>
                                            </tr>
                                        ))}
                                    </>
                                ): (
                                    <tr>
                                        <td colSpan={9} align={'center'}> No items found </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    : ''}
                </div>
            </div>
        </>
    );

}
export default RecycleBin;
