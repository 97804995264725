
/******************* Constants For Buyers ******************/
export const buyerListConstants = {
    GET_BUYERLIST_SUCCESS: 'GET_BUYERLIST_SUCCESS',
    GET_BUYERLIST_FAILURE: 'GET_BUYERLIST_FAILURE'
}

export const createBuyerConstants = {
    GET_CREATEBUYER_SUCCESS: 'GET_CREATEBUYER_SUCCESS',
    GET_CREATEBUYER_FAILURE: 'GET_CREATEBUYER_FAILURE'
}

export const viewBuyerConstants = {
    GET_VIEWBUYER_SUCCESS: 'GET_VIEWBUYER_SUCCESS',
    GET_VIEWBUYER_FAILURE: 'GET_VIEWBUYER_FAILURE'
}

export const updateBuyerConstants = {
    GET_UPDATEBUYER_SUCCESS: 'GET_UPDATEBUYER_SUCCESS',
    GET_UPDATEBUYER_FAILURE: 'GET_UPDATEBUYER_FAILURE',
}

export const deleteBuyerConstants = {
    GET_DELETEBUYER_SUCCESS: 'GET_DELETEBUYER_SUCCESS',
    GET_DELETEBUYER_FAILURE: 'GET_DELETEBUYER_FAILURE'
}

export const clearBuyerMessages = {
    CLEAR_BUYER_MESSAGE: 'CLEAR_BUYER_MESSAGE'
}