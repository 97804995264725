import React, {useEffect, useState, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
// import {toast} from 'react-toastify';
import moment from "moment";
import Select from 'react-select';
import { getBuyers } from "../../../actions/buyerAction";
import { getSellers } from "../../../actions/sellerAction";
import {totalPurchaseReport, clearReportData} from "../../../actions/reportAction";
import { PDFDownloadLink, BlobProvider } from '@react-pdf/renderer';
import TotalPurchasePDF from "./totalPurchasePDF";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

const TotalPurchaseReport = () => {

    const dispatch = useDispatch();
	const reportTemplateRef = useRef(null);
    const [error, setError] = useState(false);
    const [flag, setFlag] = useState(false);
    const [buyersList, setBuyersList] = useState([]);
    const [sellersList, setSellersList] = useState([]);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [selectedBuyer, setSelectedBuyer] = useState("All");
    const [selectedSeller, setSelectedSeller] = useState("All");
    
    const [ordersList, setOrdersList] = useState([]);
    
    const [orderQtyTotal,setOrderQtyTotal] = useState(0);
    const [shippedQtyTotal,setShippedQtyTotal] = useState(0);
    const [pendingQtyTotal,setPendingQtyTotal] = useState(0);
    const [orderAmountTotal,setOrderAmountTotal] = useState(0);
    const [shippedAmountTotal,setShippedAmountTotal] = useState(0);
    const [pendingAmountTotal,setPendingAmountTotal] = useState(0);

    useEffect(() => {
        // dispatch(
        //     totalPurchaseReport({
        //         from_date   : "2024-01-01",
        //         to_date     : "2024-06-25",
        //         buyer       : "All",
        //         seller      : "All",
        //     })
        // );
        dispatch(getBuyers());
        dispatch(getSellers());
        dispatch(clearReportData());
    }, [dispatch])

    // This function adds commas to a number for every thousand separator.
    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    //get all the buyers and sellers list from reducer and set here
    const getBuyerList = useSelector((state) => state.getBuyerList.getBuyerList)
    const getSellerList = useSelector((state) => state.getSellerList.getSellerList)
    useEffect(() => {
        if (getBuyerList && getBuyerList?.data && getBuyerList?.data?.status === true) {
            setBuyersList(getBuyerList?.data?.data);
        }
        if (getSellerList && getSellerList?.data && getSellerList?.data?.status === true) {
            setSellersList(getSellerList?.data?.data);
        }
    }, [getBuyerList,getSellerList]);

    // set item properties in state
    const handleForDetails = (e, type) => {
        if(type === 'buyer'){
            setSelectedBuyer(e ? e.value : "");
        }else if(type === 'seller'){
            setSelectedSeller(e ? e.value : "");
        } 
    }

    // function for submit the props for getting purchase report accrodingly
    const handleReportFunction = async (e) => {
        e.preventDefault();
        if (!fromDate || !toDate || !selectedBuyer || !selectedSeller) {
            setError(true);
            return false;
        } else {
            dispatch(
                totalPurchaseReport({
                    from_date   : fromDate,
                    to_date     : toDate,
                    buyer       : selectedBuyer,
                    seller      : selectedSeller,
                })
            );
        }
    }

    const purchaseReportDtl = useSelector((state) => state.totalPurchaseReport.totalPurchaseReport)
    useEffect(() => {
        if (purchaseReportDtl && purchaseReportDtl?.data && purchaseReportDtl?.data?.status === true) {
            setOrdersList(purchaseReportDtl?.data?.data);
            setFlag(true);
        }else{
            setOrdersList([]);
            setFlag(false);
        }
    }, [purchaseReportDtl]);

    useEffect(() => {
        // Initialize a variable to store the sum of order values
        const totalQuantity = ordersList?.reduce((acc, order) => {
                return acc + ((order?.total_quantity)?parseInt(order?.total_quantity) : 0);  // Default to 0 in case invoice.invoice_order is null or undefined
        }, 0) || 0;
        setOrderQtyTotal(totalQuantity);
        const shippedQuantity = ordersList?.reduce((acc, order) => {
                return acc + ((order?.total_shipped_quantity) ? parseInt(order?.total_shipped_quantity) : 0); // Default to 0 in case invoice.invoice_order is 
        }, 0) || 0;
        setShippedQtyTotal(shippedQuantity);
        const pendingQuantity = ordersList?.reduce((acc, order) => {
                return acc + (parseInt(order?.total_quantity) - ((order?.total_shipped_quantity) ? (order?.total_shipped_quantity) : 0)); // Default to 0 in case invoice.invoice_order is 
        }, 0) || 0;
        setPendingQtyTotal(pendingQuantity);
        const orderAmount = ordersList?.reduce((acc, order) => {
                return acc + (parseInt((order?.total_cnf) ? parseFloat(order?.total_cnf) : 0))
        }, 0) || 0;
        setOrderAmountTotal(orderAmount);
        const shippedAmount = ordersList?.reduce((acc, order) => {
                return acc + (parseInt((order?.total_shipped_amount) ? parseFloat(order?.total_shipped_amount) : 0))
        }, 0) || 0;
        setShippedAmountTotal(shippedAmount);
        const pendingAmount = ordersList?.reduce((acc, order) => {
            return acc + ((order?.total_cnf) - (order?.total_shipped_amount))
        }, 0) || 0;
        setPendingAmountTotal(pendingAmount);
    }, [ordersList]);
    return (
        <>
            <div className="dash-body">
                <div className="head-row">
                    <h3>Total Purchase Report</h3>
                </div>
                <div className="box-body box-shd">
                    <form onSubmit={handleReportFunction}>
                        <div className="row">
                            <div className="col-sm-6 col-lg-2 dash-form-col">
                                <label>From Date</label>
                                <input type="date" className="form-control" onChange={(e) => setFromDate(e.target.value)} placeholder="Select a date"/>
                                {error && !fromDate && (
                                    <span style={{ color: "red" }} className="invalidInput" >
                                        Required*
                                    </span>
                                )}
                            </div>
                            <div className="col-sm-6 col-lg-2 dash-form-col">
                                <label>To Date</label>
                                <input type="date" className="form-control" onChange={(e) => setToDate(e.target.value)} placeholder="Select a date"/>
                                {error && !toDate && (
                                    <span style={{ color: "red" }} className="invalidInput" >
                                        Required*
                                    </span>
                                )}
                            </div>
                            <div className="col-sm-6 col-lg-4 dash-form-col">
                                <label>Buyer</label>
                                <Select
                                    isClearable
                                    options={[
                                        { label: 'All', value: 'All' }, // Default option for ALL
                                        ...buyersList.map((data, index) => ({
                                            label: data.name,
                                            value: data.id,
                                        })),
                                    ]}
                                    onChange={(e) => handleForDetails(e, 'buyer')}
                                    defaultValue={{ label: 'All', value: 'All' }}
                                />
                            </div>
                            <div className="col-sm-6 col-lg-4 dash-form-col">
                                <label>Seller <span className="text-danger">*</span></label>
                                <Select
                                    isClearable
                                    options={[
                                        { label: 'All', value: 'All' }, // Default option for ALL
                                        ...sellersList.map((data, index) => ({
                                            label: data.name,
                                            value: data.id,
                                        })),
                                    ]}
                                    onChange={(e) => handleForDetails(e, 'seller')}
                                    defaultValue={{ label: 'All', value: 'All' }}
                                />
                                {error && !selectedSeller && (
                                    <span style={{ color: "red" }} className="invalidInput" >
                                        Required*
                                    </span>
                                )}
                            </div>

                            <div className="dash-btn-group mt-2">
                                <button className="cus-btn" type="submit">View</button>
                            </div>
                        </div>
                    </form>
                </div>
            
                {(flag) ?
                    <div style={{backgroundColor:"#fff"}} >
                         {(ordersList?.length > 0) &&
                        <div className="align-items-md-center d-flex gap-1 justify-content-end px-2">
                            <BlobProvider document={
                                <TotalPurchasePDF ordersList={ordersList} orderQtyTotal={orderQtyTotal} shippedQtyTotal={shippedQtyTotal}
                                    pendingQtyTotal={pendingQtyTotal} orderAmountTotal={orderAmountTotal} shippedAmountTotal={shippedAmountTotal}
                                    pendingAmountTotal={pendingAmountTotal} buyersList={buyersList} sellersList={sellersList} selectedBuyer={selectedBuyer}
                                    selectedSeller={selectedSeller} fromDate={fromDate} toDate={toDate}/>
                            }>
                                {({ url }) => (
                                    <a href={url} target="_blank" rel="noopener noreferrer" title="Preview PDF"> <FontAwesomeIcon icon={faFilePdf} className="icon" /></a>
                                )}
                            </BlobProvider>
                            <PDFDownloadLink
                                document={
                                    <TotalPurchasePDF ordersList={ordersList} orderQtyTotal={orderQtyTotal} shippedQtyTotal={shippedQtyTotal}
                                        pendingQtyTotal={pendingQtyTotal} orderAmountTotal={orderAmountTotal} shippedAmountTotal={shippedAmountTotal}
                                        pendingAmountTotal={pendingAmountTotal} buyersList={buyersList} sellersList={sellersList} selectedBuyer={selectedBuyer}
                                        selectedSeller={selectedSeller} fromDate={fromDate} toDate={toDate}/>
                                }
                                style={{padding: '5px 5px 5px 5px', float: 'right' }}
                                fileName="total-purchase-report.pdf"
                                className="MuiButtonBase-root MuiButton-root MuiButton-contained btn-download MuiButton-containedPrimary MuiButton-containedSizeLarge MuiButton-sizeLarge"
                            >
                                {({ blob, url, loading, error }) =>
                                    loading ? "Loading document..." : <i className="fa-solid fa-download" title="Download PDF"></i>
                                }
                            </PDFDownloadLink>
                        </div>
                        }
                        <div ref={reportTemplateRef} style={{padding:'4em 4em'}}>
                            <table border="0" cellSpacing="0" cellPadding="0" style={{ fontFamily: 'sans-serif', color: '#000', fontSize: '16px', margin: 'auto', maxWidth: '1650px', width: '100%' }}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <table style={{border: "3px solid #000",width: "100%",borderWidth: "2px 5px 5px 2px",}}>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td valign="center">
                                                                                            <img src="../../img/m-logo.png" alt="" style={{ maxWidth: 120, width: '100%'}} />
                                                                                        </td>
                                                                                        <td valign="top" style={{ textAlign: "center" }}>
                                                                                            <table width="100%" border={0} cellSpacing={0} cellPadding={0}>
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td colSpan={4} style={{ fontSize: 45,fontWeight: 700,textTransform: "uppercase", fontFamily: "ui-rounded" }}>
                                                                                                            MARUTI OVERSEAS CORP.
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td colSpan={4} style={{ fontSize: 20, fontWeight: 700, textTransform: "uppercase", fontFamily: "ui-rounded" }}>
                                                                                                            IMPORTERS &amp; INDENTORS
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td colSpan={4} height="15px" />
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td height="30px" />
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ textAlign: 'center', fontSize: '18px' }}>
                                                                            <div style={{display:'flex', justifyContent:'space-between', marginTop:'10px'}}>
                                                                                <span style={{ fontWeight: 600 }}>
                                                                                    Buyer: <span style={{fontWeight:'normal', marginRight:'0.5em'}}>{!(selectedBuyer) ? '-' : (buyersList.find(buyer => buyer.id === selectedBuyer)?.name || selectedBuyer)}</span>  
                                                                                    Seller: <span style={{fontWeight:'normal'}}>{!(selectedSeller) ? '-' : (sellersList.find(seller => seller.id === selectedSeller)?.name || selectedSeller)}</span>
                                                                                </span>
                                                                                <span style={{ fontWeight: 600 }}>Total Purchase Report</span>

                                                                                <span style={{ fontWeight: 600 }}>
                                                                                    From Date: <span style={{fontWeight:'normal', marginRight:'0.5em'}}>{(fromDate) ? moment(fromDate).format('DD-MMM-YYYY') : '-'}</span>  
                                                                                    To Date: <span style={{fontWeight:'normal'}}>{(toDate) ? moment(toDate).format('DD-MMM-YYYY') : '-'}</span>
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td height="50px"></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table width="100%" border="0" cellSpacing="0" cellPadding="0" style={{ borderRadius: '6px', border: '1px solid #bbb' }}>
                                                <thead>
                                                    <tr style={{ backgroundColor: '#F8F7FD' }}>
                                                        <th style={{ textAlign: 'center', backgroundColor: '#e6e2e2', padding: '20px' }}>Order Number</th>
                                                        <th style={{ textAlign: 'center', backgroundColor: '#e6e2e2', padding: '20px' }}>Seller</th>
                                                        <th style={{ textAlign: 'center', backgroundColor: '#e6e2e2', padding: '20px' }}>Ship Date</th>
                                                        <th style={{ textAlign: 'center', backgroundColor: '#e6e2e2', padding: '20px' }}>Order Qty (Dz)</th>
                                                        <th style={{ textAlign: 'center', backgroundColor: '#e6e2e2', padding: '20px' }}>Shipped Qty (Dz)</th>
                                                        <th style={{ textAlign: 'center', backgroundColor: '#e6e2e2', padding: '20px' }}>Pending Qty (Dz)</th>
                                                        <th style={{ textAlign: 'center', backgroundColor: '#e6e2e2', padding: '20px' }}>Order Amount US($)</th>
                                                        <th style={{ textAlign: 'center', backgroundColor: '#e6e2e2', padding: '20px' }}>Shipped Amount US($)</th>
                                                        <th style={{ textAlign: 'center', backgroundColor: '#e6e2e2', padding: '20px' }}>Pending Amount US($)</th>
                                                    </tr>
                                                </thead>
                                                <tbody style={{whiteSpace:"nowrap"}}>
                                                    {(ordersList?.length > 0) ? (
                                                        <>
                                                            {ordersList?.map((order) => (
                                                                // invoice.invoice_order?.map((order) => (
                                                                    <tr key={order.id}>
                                                                        <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderBottom:'0.5px solid #ccc',borderRight:'0.5px solid #ccc'}}>
                                                                            {(order?.order_no)?(order?.order_no) : '-'}</td>
                                                                        <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderBottom:'0.5px solid #ccc',borderRight:'0.5px solid #ccc'}}>
                                                                            {(order?.sellers?.name)?(order?.sellers?.name) : '-'}</td>
                                                                        <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderBottom:'0.5px solid #ccc',borderRight:'0.5px solid #ccc'}}>
                                                                            {(order?.shipment_date)? moment(order?.shipment_date).format('DD-MMM-YYYY') : '-'}</td>
                                                                        <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderBottom:'0.5px solid #ccc',borderRight:'0.5px solid #ccc'}}>
                                                                            {(order?.total_quantity)?(order?.total_quantity) : '0'}</td>
                                                                        <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderBottom:'0.5px solid #ccc',borderRight:'0.5px solid #ccc'}}>
                                                                            {(order?.total_shipped_quantity)?(order?.total_shipped_quantity) : '0'}</td>
                                                                        <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderBottom:'0.5px solid #ccc',borderRight:'0.5px solid #ccc'}}>
                                                                            {numberWithCommas((order?.total_quantity) - ((order?.total_shipped_quantity) ? (order?.total_shipped_quantity) : 0))}</td>
                                                                        <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderBottom:'0.5px solid #ccc',borderRight:'0.5px solid #ccc'}}>
                                                                            {numberWithCommas((order?.total_cnf) ? (order?.total_cnf) : 0)}</td>
                                                                        <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderBottom:'0.5px solid #ccc',borderRight:'0.5px solid #ccc'}}>
                                                                            {numberWithCommas((order?.total_shipped_amount) ? order?.total_shipped_amount : 0)}</td>
                                                                        <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderBottom:'0.5px solid #ccc',borderRight:'0.5px solid #ccc'}}>
                                                                            {numberWithCommas(parseFloat((order?.total_cnf) - (order?.total_shipped_amount)).toFixed(2))}</td>
                                                                    </tr>
                                                                // ))
                                                            ))}
                                                        </>
                                                    ): (
                                                        <tr>
                                                            <td colSpan={12} align={'center'} style={{fontWeight:'700',padding:'1em'}}> No Record Found. </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                                {(ordersList?.length > 0) ? (
                                                    <>
                                                        <tfoot>
                                                            <tr>
                                                                <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderTop:'2px solid #000',borderRight:'0.5px solid #ccc',fontWeight: 'bold'}}>Total</td>
                                                                <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderTop:'2px solid #000',borderRight:'0.5px solid #ccc',fontWeight: 'bold'}}>&nbsp;</td>
                                                                <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderTop:'2px solid #000',borderRight:'0.5px solid #ccc',fontWeight: 'bold'}}>&nbsp;</td>
                                                                <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderTop:'2px solid #000',borderRight:'0.5px solid #ccc',fontWeight: 'bold'}}>{(orderQtyTotal) ? orderQtyTotal : 0}</td>
                                                                <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderTop:'2px solid #000',borderRight:'0.5px solid #ccc',fontWeight: 'bold'}}>{(shippedQtyTotal) ? shippedQtyTotal : 0}</td>
                                                                <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderTop:'2px solid #000',borderRight:'0.5px solid #ccc',fontWeight: 'bold'}}>{(pendingQtyTotal) ? pendingQtyTotal : 0}</td>
                                                                <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderTop:'2px solid #000',borderRight:'0.5px solid #ccc',fontWeight: 'bold'}}>{(orderAmountTotal) ? '$'+numberWithCommas(parseFloat(orderAmountTotal).toFixed(2)) : '0'}</td>
                                                                <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderTop:'2px solid #000',borderRight:'0.5px solid #ccc',fontWeight: 'bold'}}>{(shippedAmountTotal) ? '$'+numberWithCommas(parseFloat(shippedAmountTotal).toFixed(2)) : '0'}</td>
                                                                <td style={{padding:'20px',color:'#333333',backgroundColor:'#fff',borderTop:'2px solid #000',borderRight:'0.5px solid #ccc',fontWeight: 'bold'}}>{(pendingAmountTotal) ? '$'+numberWithCommas(parseFloat(pendingAmountTotal).toFixed(2)) : '0'}</td>
                                                            </tr>
                                                        </tfoot>
                                                    </>
                                                    ):""}
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                : ""}
            </div>
        </>
    );

}

export default TotalPurchaseReport;
