import { sellerListConstants, createSellerConstants, viewSellerConstants, updateSellerConstants, clearSellerMessages, deleteSellerConstants } from "../constants/seller.constant";

/************************** Reducer For Sellers *******************************/
export const sellerListReducer = (state = { getSellerList: [] }, action) => {
    switch (action.type) {
        case sellerListConstants.GET_SELLERLIST_SUCCESS:
            return { getSellerList: action.payload };
        case sellerListConstants.GET_SELLERLIST_FAILURE:
            return { error: action.payload };
        case clearSellerMessages.CLEAR_SELLER_MESSAGE:
            return { getSellerList: [], error: "" }
        default:
            return state;
    }
};

export const createSellerReducer = (state = { createSeller: [] }, action) => {
    switch (action.type) {
        case createSellerConstants.GET_CREATESELLER_SUCCESS:
            return { createSeller: action.payload };
        case createSellerConstants.GET_CREATESELLER_FAILURE:
            return { error: action.payload };
        case clearSellerMessages.CLEAR_SELLER_MESSAGE:
            return { createSeller: [], error: "" }
        default:
            return state;
    }
};

export const viewSellerReducer = (state = { viewSeller: [] }, action) => {
    switch (action.type) {
        case viewSellerConstants.GET_VIEWSELLER_SUCCESS:
            return { viewSeller: action.payload };
        case viewSellerConstants.GET_VIEWSELLER_FAILURE:
            return { error: action.payload };
        default:
            return state;
    }
};

export const updateSellerReducer = (state = { updateSeller: [] }, action) => {
    switch (action.type) {
        case updateSellerConstants.GET_UPDATESELLER_SUCCESS:
            return { updateSeller: action.payload };
        case updateSellerConstants.GET_UPDATESELLER_FAILURE:
            return { error: action.payload };
        case clearSellerMessages.CLEAR_SELLER_MESSAGE:
            return { updateSeller: [], error: "" }
        default:
            return state;
    }
};

export const deleteSellerReducer = (state = { deleteSeller: [] }, action) => {
    switch (action.type) {
        case deleteSellerConstants.GET_DELETESELLER_SUCCESS:
            return { deleteSeller: action.payload };
        case deleteSellerConstants.GET_DELETESELLER_FAILURE:
            return { error: action.payload };
        case clearSellerMessages.CLEAR_SELLER_MESSAGE:
            return { deleteSeller: [], error: "" }
        default:
            return state;
    }
};